import styled from "styled-components";
import ValidationSection from "../../components/Software/ValidationSection/ValidationSection";
import TextInput from "../../components/Software/Inputs/TextInput";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fieldValidator from "../../utils/validators/field";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import Popup from "../../components/Software/Popup/Popup";
import TitleWrapper from "../../components/Software/TitleWrapper/TitleWrapper";
import Section from "../../components/Software/Section/Section";
import FormBlock from "../../components/Software/FormBlock/FormBlock";
import fetchAPI from "../../utils/fetch";
import { useCookies } from "react-cookie";
import NumberInput from "../../components/Software/Inputs/NumberInput";

/**
 * Styles
 */

const FieldNameBlock = styled.div `
    width:300px;
    height:32px;
`

const FieldCoefficientBlock = styled.div `
    width:82px;
    height:32px;
`

/**
 * Component
 * @param {*} param0 
 * @returns 
 */
const Field = ({ match }) => {

    // Get id provided in URL
    const { id } = useParams();

    // Get navigation
    const navigate = useNavigate();

    // Get cookies
    const [cookies] = useCookies(['competition']);

    // Init error
    const [error, setError] = useState(null);
    
    // Field
    const [field, setfield] = useState(null);
    const [fieldName, setfieldName] = useState("");
    const [fieldCoeff, setfieldCoeff] = useState(0);

    // Competition object
    const [competition, setCompetition] = useState(null);

    /**
     * Test if token exists
     */
    useEffect(() => {
        if(!cookies.proAuthorization) navigate('/login');
    }, [cookies.proAuthorization, navigate]);

    /**
     * Load competition
     */
    useEffect(() => {

        (async () => {

            if(!cookies.competition) {
                navigate('/');
                return;
            }

            try {

                // Try to get the competition
                const request = await fetchAPI('GET', '/competition', {
                    competitionId: parseInt(cookies.competition)
                });

                if(request.status !== 200) {
                    if(request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/logout');
                        return;
                    }
                    throw new Error();
                } else {
                    setCompetition(request.json.data.competition);
                }

            }
            catch(e) {
                setError("Une erreur est survenue");
            }
            
        })();

    }, [id, cookies.competition, navigate])

    /**
     * Load fields from database
     */
    useEffect(() => {

        (async () => {

            if(!cookies.competition) {
                navigate('/');
                return;
            }

            if(id && competition) {

                try {

                    const request = await fetchAPI('GET', '/field', {
                        fieldId: id
                    });
        
                    if(request.status !== 200) {
                        if(request.json.message === "ERR_PLEASE_RECONNECT") {
                            navigate('/logout');
                            return;
                        }
                        throw new Error();
                    } else {
                        setfield(request.json.data.field);
                    }

                }
                catch(e) {
                    setError("Une erreur est survenue.");
                }

            }

        })();

        return () => {};

    }, [id, competition, cookies.competition, navigate]);

    /**
     * When data is loaded from database, fill form
     */
    useEffect(() => {

        setfieldName(field ? field.name : "");
        setfieldCoeff(field ? field.coeff : 1);

        return () => {};

    }, [field]);

    // Handlers

    const handleChangefieldName = (e) => {setfieldName(e.target.value); setError(null); };
    const handleChangefieldCoeff = (e) => {setfieldCoeff(e.target.value); setError(null); };

    /**
     * Submit form handler
     */
    const handleSubmitForm = async () => {

        try {

            fieldValidator(fieldName, parseInt(fieldCoeff));

            let method = 'POST';

            let field = {
                name: fieldName,
                coeff: parseInt(fieldCoeff),
                competitionId: competition.competitionId
            }

            if(id) {
                method = "PUT";
                field = {
                    ...field,
                    fieldId: id
                }
            }

            try {

                const request = await fetchAPI(method, '/field', {
                    field: field
                });
    
                if(request.status !== 200) {
                    if(request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/logout');
                        return;
                    }
                    else throw new Error("Une erreur est survenue.");
                } else navigate('/fields');

            }
            catch(e) {
                throw new Error("Une erreur est survenue.");
            }

        }
        catch(e) {
            setError(e.message);
        }

    }

    return (

        <Wrapper>

            <Popup content={[error]} />

            <TitleWrapper title={field ? "Modifier un terrain" : "Créer un terrain"} />

            <Section align="start">

                <FormBlock>
                    <label>Nom</label>
                    <FieldNameBlock>
                        <TextInput placeholder="Nom du terrain" value={fieldName} onChange={handleChangefieldName} />
                    </FieldNameBlock>
                </FormBlock>

                <FormBlock>
                    <label>Coefficient</label>
                    <FieldCoefficientBlock>
                        <NumberInput placeholder="Coeff" value={fieldCoeff} onChange={handleChangefieldCoeff} />
                    </FieldCoefficientBlock>
                </FormBlock>

            </Section>

            <ValidationSection icon="save" message1="Enregistrer" message2="le terrain" callback={handleSubmitForm} />

        </Wrapper>
        
    );

}

export default Field;