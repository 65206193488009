import styled from "styled-components";
import Indicators from "../../components/Software/Indicators/Indicators";
import TitlePage from "../../components/Software/TitlePage/TitlePage"
import Emmet from "../../components/Software/Emmet/Emmet";
import Logs from "../../components/Software/Logs/Logs";
import TourState from "../../components/Software/TourState/TourState";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import fetchAPI from "../../utils/fetch";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/Software/Popup/Popup";
import Loader from "../../components/Loader/Loader";

/**
 * Styles
 */

const DashboardWrapper = styled.div `

    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width:100%;
    height:100%;
    position:relative;

`

const FlexDashboardContainer = styled.div `

    position:relative;
    display:flex;
    top:0;
    left:0;
    width:100%;
    justify-content:space-between;
    margin:30px 0 4px 0;
    height:100%;
    overflow:hidden;

    & > div:first-child {
        width:30%;
    }

    & > div:nth-child(2) {
        width:66%;
    }

`

/**
 * Component
 * @returns 
 */
const Dashboard = () => {

    // Get navigation
    const navigate = useNavigate();

    // Get cookies
    const [cookies] = useCookies(['proAuthorization', 'competition']);

    // Competition
    const [competition, setCompetition] = useState(null);
    const [tours, setTours] = useState([]);

    // Errors
    const [error, setError] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    /**
     * Test if token exists
     */
    useEffect(() => {
        if(!cookies.proAuthorization) navigate('/login');
    }, [cookies.proAuthorization, navigate]);

    /**
     * Fetch competition
     */
    useEffect(() => {

        if(!cookies.competition) {
            navigate('/');
            return;
        }

        (async () => {

            setIsLoading(true);

            try {
                const request = await fetchAPI('GET', '/competition', {
                    competitionId: parseInt(cookies.competition)
                });

                if(request.status !== 200) {
                    if(request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/logout');
                        return;
                    }
                    setError("Une erreur est survenue.");
                    setIsLoading(false);
                }
    
                
                setCompetition(request.json.data.competition);
                setIsLoading(false);

            }
            catch(e) {
                console.log(e);
                setIsLoading(false);
            }
            
        })();

    }, [cookies.competition, navigate]);

    /**
     * Load tours
     */
    useEffect(() => {

        setIsLoading(true);

        if(!cookies.competition) {
            navigate('/');
            return;
        }

        (async () => {

            try {
                const request = await fetchAPI('GET', '/tour/allofcompetition', {
                    competitionId: parseInt(cookies.competition)
                });

                if(request.status !== 200) {
                    if(request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/logout');
                        return;
                    }
                    setError("Une erreur est survenue.");
                    setIsLoading(false);
                }
    
                
                setTours(request.json.data.tours);
                setIsLoading(false);

            }
            catch(e) {
                console.log(e);
            }
            
        })();

    }, [cookies.competition, navigate]);

    return (

        <>

            <Loader isLoading={isLoading} />

            {

                competition && tours &&

                <DashboardWrapper>

                    <Popup content={[error]} />

                <TitlePage title={"Tableau de bord - " + competition.name} />
                <Indicators competition={competition} />

                    <FlexDashboardContainer>

                        <Emmet title="Journaux">
                            <Logs logs={competition.logs} />
                        </Emmet>

                        <Emmet title="Etat des tours">
                            <TourState tours={tours} />
                        </Emmet>

                    </FlexDashboardContainer>

                </DashboardWrapper>

            }

        </>

    );
    
}

export default Dashboard;