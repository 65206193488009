import styled from "styled-components";
import ValidationSection from "../../components/Software/ValidationSection/ValidationSection";
import TextInput from "../../components/Software/Inputs/TextInput";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import levelValidator from "../../utils/validators/level";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import Popup from "../../components/Software/Popup/Popup";
import TitleWrapper from "../../components/Software/TitleWrapper/TitleWrapper";
import Section from "../../components/Software/Section/Section";
import FormBlock from "../../components/Software/FormBlock/FormBlock";
import fetchAPI from "../../utils/fetch";
import { useCookies } from "react-cookie";
import NumberInput from "../../components/Software/Inputs/NumberInput";

/**
 * Styles
 */

const LevelNameBlock = styled.div `
    width:300px;
    height:32px;
`

const LevelCoefficientBlock = styled.div `
    width:82px;
    height:32px;
`

/**
 * Component
 * @param {*} param0 
 * @returns 
 */
const Level = ({ match }) => {

    // Get id provided in URL
    const { id } = useParams();

    // Get navigation
    const navigate = useNavigate();

    // Get cookies
    const [cookies] = useCookies(['competition', 'proAuthorization']);

    // Init error
    const [error, setError] = useState(null);
    
    // Level
    const [level, setLevel] = useState(null);
    const [levelName, setLevelName] = useState("");
    const [levelCoeff, setLevelCoeff] = useState(0);

    // Competition object
    const [competition, setCompetition] = useState(null);

    /**
     * Test if token exists
     */
    useEffect(() => {
        if(!cookies.proAuthorization) navigate('/login');
    }, [cookies.proAuthorization, navigate]);

    /**
     * Load competition
     */
    useEffect(() => {

        (async () => {

            if(!cookies.competition) {
                navigate('/');
                return;
            }

            try {

                // Try to get the competition
                const request = await fetchAPI('GET', '/competition', {
                    competitionId: parseInt(cookies.competition)
                });

                if(request.status !== 200) {
                    if(request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/logout');
                        return;
                    }
                    throw new Error();
                } else {
                    setCompetition(request.json.data.competition);
                }

            }
            catch(e) {
                setError("Une erreur est survenue");
            }
            
        })();

    }, [cookies.competition, navigate])

    /**
     * Load levels from database
     */
    useEffect(() => {

        (async () => {

            if(!cookies.competition) {
                navigate('/');
                return;
            }

            if(id && competition) {

                try {

                    const request = await fetchAPI('GET', '/level', {
                        levelId: id
                    });
        
                    if(request.status !== 200) {
                        if(request.json.message === "ERR_PLEASE_RECONNECT") {
                            navigate('/logout');
                            return;
                        }
                        throw new Error();
                    } else {
                        setLevel(request.json.data.level);
                    }

                }
                catch(e) {
                    setError("Une erreur est survenue.");
                }

            }

        })();

        return () => {};

    }, [id, competition, cookies.competition, navigate]);

    /**
     * When data is loaded from database, fill form
     */
    useEffect(() => {

        setLevelName(level ? level.name : "");
        setLevelCoeff(level ? level.coeff : 1);

        return () => {};

    }, [level]);

    // Handlers

    const handleChangeLevelName = (e) => {setLevelName(e.target.value); setError(null); };
    const handleChangeLevelCoeff = (e) => {setLevelCoeff(e.target.value); setError(null); };

    /**
     * Submit form handler
     */
    const handleSubmitForm = async () => {

        try {

            levelValidator(levelName, parseInt(levelCoeff));

            let method = 'POST';

            let level = {
                name: levelName,
                coeff: parseInt(levelCoeff),
                competitionId: competition.competitionId
            }

            if(id) {
                method = "PUT";
                level = {
                    ...level,
                    levelId: id
                }
            }

            try {

                const request = await fetchAPI(method, '/level', {
                    level: level
                });
    
                if(request.status !== 200) {
                    if(request.json.message === "ERR_PLEASE_RECONNECT") {navigate('/logout'); return; }
                    else throw new Error("Une erreur est survenue.");
                } else navigate('/levels');

            }
            catch(e) {
                throw new Error("Une erreur est survenue.");
            }

        }
        catch(e) {
            setError(e.message);
        }

    }

    return (

        <Wrapper>

            <Popup content={[error]} />

            <TitleWrapper title={level ? "Modifier un niveau de jeu" : "Créer un niveau de jeu"} />

            <Section align="start">

                <FormBlock>
                    <label>Nom</label>
                    <LevelNameBlock>
                        <TextInput placeholder="Nom du niveau" value={levelName} onChange={handleChangeLevelName} />
                    </LevelNameBlock>
                </FormBlock>

                <FormBlock>
                    <label>Coefficient</label>
                    <LevelCoefficientBlock>
                        <NumberInput placeholder="Coeff" value={levelCoeff} onChange={handleChangeLevelCoeff} />
                    </LevelCoefficientBlock>
                </FormBlock>

            </Section>

            <ValidationSection icon="save" message1="Enregistrer" message2="le niveau" callback={handleSubmitForm} />

        </Wrapper>
        
    );

}

export default Level;