const fieldValidator = (name, coeff) => {
    if(typeof name !== "string") throw new Error("Le nom est invalide.");
    if(name.length === 0) throw new Error("Le nom ne peut pas être vide.");
    if(name.length > 50) throw new Error("Le nom est trop long.");
    if(typeof coeff !== "number") throw new Error("La valeur du coefficient est invalide");
    if(coeff < -500 || coeff > 500) throw new Error("La valeur du coefficient est invalide");

    return;

}

export default fieldValidator;