import styled from "styled-components";

/**
 * Styles
 */

const EmmetWrapper = styled.div `

    border-radius:8px;
    -webkit-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.23); 
    box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.23);
    margin:0 4px;
    border:1px solid #34495e;
    overflow:hidden;
    display:flex;
    flex-direction:column;

    & > h3 {
        position:relative;
        margin:0;
        padding:25px 0 20px 25px;
        font-size:16px;
        margin:0;
        font-weight:600;
        font-size:16px;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const Emmet = (props) => {

    return (
        <EmmetWrapper>
            <h3>{props.title}</h3>
            {props.children}
        </EmmetWrapper>
    );
    
}

export default Emmet;