import styled from "styled-components"
import Indicator from "./Indicator/Indicator";
import competitionModelConverter from "../../../utils/modelConverters/competition";
import { useEffect, useState } from "react";
import fetchAPI from "../../../utils/fetch";
import { useNavigate } from "react-router-dom";

/**
 * Styles
 */

const IndicatorsWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    justify-content:space-between;
    flex-direction:row;
    display:flex;

`

/**
 * Components
 * @returns 
 */
const Indicators = (props) => {

    const [nbCurrentGames, setNbCurrentGames] = useState(0);
    const [nbTeams, setNbTeams] = useState(0);
    const [nbAvailableFields, setNbAvailableFields] = useState(0);
    const [nbTotalFields, setNbTotalFields] = useState(0);

    useEffect(() => {

        (async () => {

            if(props.competition) {

                try {

                    const request = await fetchAPI('GET', '/team/allofcompetition', {
                        competitionId: props.competition.competitionId
                    });
    
                    if(request.status !== 200) throw new Error(request.json.message);

                    setNbTeams(request.json.data.teams.length);

                }
                catch(e) {
                    console.log(e);
                }

            }

        })();

    });

    useEffect(() => {

        (async () => {

            if(props.competition) {

                try {

                    const request = await fetchAPI('GET', '/field/allofcompetition', {
                        competitionId: props.competition.competitionId
                    });
    
                    if(request.status !== 200) throw new Error(request.json.message);

                    setNbTotalFields(request.json.data.fields.length);
                    setNbAvailableFields(request.json.data.fields.filter((f) => f.available).length);

                }
                catch(e) {
                    console.log(e);
                }

            }

        })();

    });

    useEffect(() => {

        (async () => {

            if(props.competition) {

                try {

                    const request = await fetchAPI('GET', '/game/allofcompetition', {
                        competitionId: props.competition.competitionId
                    });
    
                    if(request.status !== 200) throw new Error(request.json.message);

                    setNbCurrentGames(request.json.data.games.filter((g) => !g.scored).length);

                }
                catch(e) {
                    console.log(e);
                }

            }

        })();

    });


    return (
        <IndicatorsWrapper>
            <Indicator main={ nbCurrentGames } desc="matchs en cours" />
            <Indicator main={ nbTeams } desc="équipes engagées" />
            <Indicator main={ nbAvailableFields } desc="terrains disponibles" />
            <Indicator main={ nbTotalFields } desc="terrains au total" />
        </IndicatorsWrapper>
    );

}

export default Indicators;