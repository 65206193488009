import styled from "styled-components"

/**
 * Styles
 */

const TitlePageWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    font-size:26px;

    & > h1 {
        margin:20px 0 30px 0;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TitlePage = (props) => {

    return (
        <TitlePageWrapper><h1>{props.title}</h1></TitlePageWrapper>
    );
    
}

export default TitlePage;