import styled from "styled-components";
import SidebarLink from "./SidebarLink/SidebarLink";
import { useLocation } from 'react-router-dom';
import { useCookies } from "react-cookie";

/**
 * Styles
 */

const SidebarWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:120px;
    height:100%;
    background:#34495e;
    display:flex;
    flex-direction:column;
    justify-content:start;
    align-items:center;
    overflow-y:auto;
    overflow-x:hidden;

    & > div:first-child {
        display:flex;
        flex-direction:column;
    }

    & > img {
        width:56px;
        margin:24px 0;
    }

`

/**
 * Component
 * @returns 
 */
const Sidebar = () => {

    // Get location
    const location = useLocation().pathname;

    // Get cookies
    const [cookies] = useCookies(["competition"]);

    return (
        <SidebarWrapper>

            <img src="/logo.png" alt="Logo Neoscore" />

            {
            
            cookies.competition && 
             
            <div>
                <SidebarLink title="Tableau de bord" icon="home" to="/dashboard" className={location === "/dashboard" ? "active" : ""} />
                <SidebarLink title="Equipes engagées" icon="people" to="/teams" className={location === "/teams" || location === "/add-team" || location.includes("/team/") ? "active" : ""} />
                <SidebarLink title="Déroulement compétition" icon="git-merge" to="/manager" className={location === "/manager" || location.includes("/manager/") ? "active" : ""} />
                <SidebarLink title="Gestion terrains" icon="tablet-landscape" to="/fields" className={location === "/fields" || location === "/add-field" || location.includes("/field/") ? "active" : ""} />
                <SidebarLink title="Gestion niveaux" icon="analytics" to="/levels" className={location === "/levels" || location === "/add-level" || location.includes("/level/") ? "active" : ""} />
                <SidebarLink title="Affichage en direct" icon="easel" to="/" className="red" />
            </div> 
            
            }

        </SidebarWrapper>
    );
    
}

export default Sidebar;