import styled from "styled-components";
import { Draggable } from 'react-beautiful-dnd';
import SelectInput from "../../../Inputs/SelectInput";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:150px;
    min-height:150px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    border-radius:10px;
    flex-shrink:0;
    margin:0 25px 25px 0;

    & > div:first-child {
        font-weight:600;
        text-align:center;
        padding:10px 15px;
        display:flex;
        justify-content:space-between;
        align-items:center;
    }

    & > div:first-child > div:nth-child(2) {
        color:#e74c3c;
        font-size:20px;
        cursor:pointer;
    }

    & > div:nth-child(3) {
        padding:5px;
    }

    & > div:nth-child(3) > div {
        border: 3px dashed #ecf0f1;
        margin:3px;
        text-align:center;
        color:grey;
        font-weight:600;
    }

`

const FieldSelectDiv = styled.div `
    width:90%;
    margin:0 5%;
    height:32px;
`

const PouleDragNDropBlock = (props) => {

   return (

    props.teams && props.pouleId && props.handleDeletePouleToCreate && props.fields && props.handleChangeFieldId &&

    <StyledDiv>
        <div>
            <div>Poule { props.pouleId }</div>
            <div><ion-icon name="trash-outline" onClick={() => props.handleDeletePouleToCreate(props.pouleId) }></ion-icon></div>
        </div>
        <div>
            <FieldSelectDiv>
                <SelectInput options={props.fields} value={props.fieldId} onChange={props.handleChangeFieldId} />
            </FieldSelectDiv>
        </div>
        <div>
            {props.teams.map((t, i) => (
                <Draggable key={t.teamId} draggableId={ t.teamId.toString() } index={i}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                userSelect: 'none',
                                padding:'5px',
                                fontSize:'13px',
                                color: 'grey',
                                ...provided.draggableProps.style,
                            }}
                        >
                            {t.name}
                        </div>
                    )}
                </Draggable>
            ))}
        </div>
    </StyledDiv>

   )

}

export default PouleDragNDropBlock;