import API_HOST_URL from "./apiHost";

const getCookieValue = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

const fetchAPI = async (method, url, payload) => {

    const token = getCookieValue("proAuthorization") ? getCookieValue("proAuthorization") : "none"

    try {
        const API_HOST = API_HOST_URL;
        let options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        };

        if (method === "GET" && payload) {
            const params = new URLSearchParams();
            Object.entries(payload).forEach(([key, value]) => {
                params.append(key, value);
            });
            url += `?${params.toString()}`;
        } else if (payload) {
            options.body = JSON.stringify(payload);
        }

        const response = await fetch(`${API_HOST}${url}`, options);
        const jsondata = await response.json();
        return {
            json: jsondata,
            status: response.status,
            message: jsondata.message ? jsondata.message : ""
        };
    } catch (e) {
        console.error("Error in fetch - ROUTE:", url, "Details:", e);
        throw e;
    }
};

export default fetchAPI;