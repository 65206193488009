import { useState } from "react"
import styled from "styled-components"
import ProfileLinks from "./ProfileLinks/ProfileLinks";

/**
 * Styles
 */
const PP = styled.div `

    width:60px;
    height:60px;
    background:url("/logo-tdp.jpg");
    background-size:cover;
    border-radius:100px;
    margin-right:30px;
    cursor:pointer;

`



/**
 * Component
 * @returns 
 */
const Profile = (props) => {

    const [showProfilePanel, setShowProfilePanel] = useState(false);

    const togglePanel = () => {
        setShowProfilePanel(showProfilePanel === true ? false : true);
    }

    return  (

        props.pro &&

        <>
            <PP onClick={togglePanel} pro={props.pro} />
            { showProfilePanel ? <ProfileLinks pro={props.pro} /> : null }
        </>

    )
}

export default Profile;