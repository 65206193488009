const getFormattedNbTeams = (tour) => {

    let cpt = 0;

    for(const p of tour.poules) {
        for(let t = 0; t < p.teams.length; t++) {
            cpt++;
        }
    }

    return cpt;

}

const getFormattedNbGames = (tour) => {

    let cpt = 0;

    for(const p of tour.poules) {
        for(let g = 0; g < p.games.length; g++) {
            cpt++;
        }
    }

    return cpt;

}

const tourModelConverter = {
    getFormattedNbTeams,
    getFormattedNbGames
}

export default tourModelConverter;