import styled from "styled-components";
import ValidationSection from "../../components/Software/ValidationSection/ValidationSection";
import TextInput from "../../components/Software/Inputs/TextInput";
import { useEffect, useState } from "react";
import SelectInput from "../../components/Software/Inputs/SelectInput";
import CheckboxInput from "../../components/Software/Inputs/CheckboxInput";
import PlayerList from "../../components/Software/PlayerList/PlayerList";
import { useNavigate, useParams } from "react-router-dom";
import teamValidator from "../../utils/validators/team";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import Popup from "../../components/Software/Popup/Popup";
import TitleWrapper from "../../components/Software/TitleWrapper/TitleWrapper";
import Section from "../../components/Software/Section/Section";
import FormBlock from "../../components/Software/FormBlock/FormBlock";
import fetchAPI from "../../utils/fetch";
import { useCookies } from "react-cookie";
import { parseDate } from "../../utils/validators/player";
import Loader from "../../components/Loader/Loader";

/**
 * Styles
 */

const Cols = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:85%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;

`

const Col1 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;

`

const Col2 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:17%;
    display:flex;
    justify-content:start;
    align-items:start;
    flex-direction:column;
    overflow-y:auto;

`

const TeamNameBlock = styled.div `
    width:300px;
    height:32px;
`

const TeamLevelBlock = styled.div `
    width:auto;
    height:32px;
`

const TeamForfaitBlock = styled.div `
    width:34px;
    height:34px;
    margin:0 15px 0 0;
`

const TeamConfirmationBlock = styled.div `
    width:34px;
    height:34px;
    margin:0 15px 0 0;
`

/**
 * Component
 * @param {*} param0 
 * @returns 
 */
const Team = ({ match }) => {

    // Get id provided in URL
    const { id } = useParams();

    // Get navigation
    const navigate = useNavigate();

    // Get cookies
    const [cookies] = useCookies(['competition']);

    // Init error
    const [error, setError] = useState(null);

    // Competition object
    const [competition, setCompetition] = useState(null);

    // Team
    const [team, setTeam] = useState(null);
    const [teamName, setTeamName] = useState("");
    const [teamLevelId, setTeamLevelId] = useState(0);
    const [teamGiveup, setTeamGiveUp] = useState(false);
    const [teamConfirmed, setTeamConfirmed] = useState(false);
    const [players, setPlayers] = useState([]);

    // Levels
    const [levels, setLevels] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    /**
     * Test if token exists
     */
    useEffect(() => {
        if(!cookies.proAuthorization) navigate('/login');
    }, [cookies.proAuthorization, navigate]);

    /**
     * Load competition
     */
    useEffect(() => {

        (async () => {

            if(!cookies.competition) {
                navigate('/');
                return;
            }

            // Try to get the competition
            const request = await fetchAPI('GET', '/competition', {
                competitionId: parseInt(cookies.competition)
            });

            if(request.status !== 200) {
                if(request.json.message === "ERR_PLEASE_RECONNECT") {
                    navigate('/logout');
                    return;
                }
                throw new Error();
            } else {
                setCompetition(request.json.data.competition);
            }
            
        })();

    }, [id, cookies.competition, navigate])

    /**
     * Load team from database
     */
    useEffect(() => {

        (async () => {

            if(id && competition) {

                // Request api
                const request = await fetchAPI('GET', '/team', {
                    teamId: parseInt(id)
                });

                if(request.status !== 200) {
                    navigate('/');
                    return;
                }

                setTeam(request.json.data.team);
                setPlayers(request.json.data.team.players.map((p) => {
                    return {
                        firstname: p.firstname,
                        name: p.name,
                        email: p.email,
                        phone: p.phone,
                        playerId: p.playerId,
                        payed: p.PlayersTeams.payed,
                        birthDate: p.birthDate
                    }
                }));

            }

            

        })();

    }, [competition, id, navigate])

    /**
     * Load levels from database
     */
    useEffect(() => {

        (async () => {

            if(competition) {

                const request = await fetchAPI('GET', '/level/allofcompetition', {
                    competitionId: competition.competitionId
                });

                if(request.status !== 200) {
                    if(request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/logout');
                        return;
                    }
                    throw new Error();
                } else {
                    setLevels(request.json.data.levels.map((l) => {
                        return {
                            value: l.levelId,
                            text: l.name
                        }
                    }));
                }

            }

        })();

        return () => {};

    }, [competition, navigate]);

    /**
     * When data is loaded from database, fill form
     */
    useEffect(() => {

        setTeamName(team ? team.name : "");
        setTeamLevelId(team ? team.levelId : 0);
        setTeamConfirmed(team ? team.confirmed : false);
        setTeamGiveUp(team ? team.giveup : false);

        return () => {};

    }, [team]);

    // Handlers

    const handleChangeTeamName = (e) => {setTeamName(e.target.value); setError(null); };
    const handleChangeTeamLevelId = (e) => {setTeamLevelId(e.target.value); setError(null); console.log(e.target.value) };
    const handleChangeGiveUp = (g) => {setTeamGiveUp(g); setError(null); };
    const handleChangeConfirm = (c) => {setTeamConfirmed(c); setError(null); };

    /**
     * Delete player handler
     * @param {*} email 
     */
    const handleDeletePlayer = (email) => {
        const cf = window.confirm("Voulez-vous vraiment supprimer ce joueur ?");
        if(cf) setPlayers(players.filter((p) => p.email !== email));
    }

    /**
     * Post team handler
     */
    const handleSubmitForm = async () => {

        setIsLoading(true);
        
        try {

            teamValidator(teamName, teamLevelId, teamGiveup, teamConfirmed);

            let pl = [];
            let pltc = [];

            for(const p of players) {
                if(p.playerId) {
                    pl.push({
                        id: p.playerId,
                        payed: p.payed
                    });
                }
                else {
                    pltc.push({
                        firstname: p.firstname,
                        name: p.name,
                        email: p.email,
                        birthDate: p.birthDate,
                        phone: p.phone,
                        payed: p.payed
                    })
                }
            }

            let method = "POST";

            let team = {
                name: teamName,
                levelId: teamLevelId,
                players: pl,
                playersToCreate: pltc,
                giveup: teamGiveup,
                confirmed: teamConfirmed
            }

            if(id) {
                method = "PUT"
                team = {
                    ...team,
                    teamId: id
                }
            }

            const request = await fetchAPI(method, '/team', {
                team: team
            });

            if(request.status === 200) {
                navigate('/teams');
            } else {
                if(request.json.message === "ERR_PLEASE_RECONNECT") {
                    navigate('/');
                    return;
                }
                if(request.json.message === "ERR_ALREADY_IN_TEAM") setError("Un ou plusieurs joueurs sont déja engagés dans une équipe.")
            }

        }
        catch(e) {
            setIsLoading(false);
            setError(e.message);
        }

    }

    /**
     * Add player handler
     * @param {*} player
     * @returns 
     */
    const handleAddPlayer = async (player) => {

        const in_ = players.filter((p) => p.email === player.email);

        if(in_.length === 0) {

            let newPlayer = {
                firstname: player.firstname,
                name: player.name,
                email: player.email,
                birthDate: parseDate(player.birthDate),
                phone: player.phone,
                playerId: player.playerId ? player.playerId : 0,
                payed: player.payed
            }

            if(newPlayer.playerId === 0) {

                const playerExists = await fetchAPI('GET', '/player/criteria', {
                    criteria: player.email
                });

                if(playerExists.status !== 200) return;
                if(playerExists.json.data.player) newPlayer.playerId = playerExists.json.data.player.playerId;

            }

            setPlayers([...players, newPlayer]);

            return;

        }

        if(in_.length === 1 && !in_[0].playerId && !player.playerId) {
            setPlayers(players.map((p) => {
                if(p.email === player.email) {
                    return {
                        firstname: player.firstname,
                        name: player.name,
                        email: player.email,
                        phone: player.phone,
                        birthDate: parseDate(player.birthDate),
                        playerId: 0,
                        payed: player.payed
                    }
                }
                return p;
            }));
            return;
        }

        if(in_.length === 1 && in_[0].playerId && player.playerId) {
            setPlayers(players.map((p) => {
                if(p.playerId !== player.playerId) return p;
                return {
                    ...p,
                    payed: player.payed
                }
            }));
            return;
        }

    }

    return (

        <Wrapper>

            <Popup content={[error]} />
            <Loader isLoading={isLoading} />

            <TitleWrapper title={team ? "Modifier une équipe" : "Créer une équipe"} />

            <Cols>
            
                <Col1>

                    <Section align="start">

                        <FormBlock>
                            <label>Nom</label>
                            <TeamNameBlock>
                                <TextInput placeholder="Nom de l'équipe" value={teamName} onChange={handleChangeTeamName} />
                            </TeamNameBlock>
                        </FormBlock>

                        <FormBlock>
                            <label>Niveau</label>
                            <TeamLevelBlock>
                                <SelectInput options={levels} value={teamLevelId} onChange={handleChangeTeamLevelId} />
                            </TeamLevelBlock>
                        </FormBlock>

                        </Section>

                        <Section title="Status de l'équipe" align="start">

                        <FormBlock>
                            <TeamForfaitBlock>
                                <CheckboxInput value={teamGiveup} onChange={handleChangeGiveUp} />      
                            </TeamForfaitBlock>
                            <label>Equipe forfait</label>
                        </FormBlock>

                        <FormBlock>
                            <TeamConfirmationBlock>
                                <CheckboxInput value={teamConfirmed} onChange={handleChangeConfirm} />      
                            </TeamConfirmationBlock>
                            <label>Equipe confirmée</label>
                        </FormBlock>

                        </Section>

                        <Section title="Composition">
                        <PlayerList players={players} onAddPlayer={handleAddPlayer} onDeletePlayer={handleDeletePlayer} />
                        
                    </Section>

                    <ValidationSection icon="save" message1="Enregistrer" message2="l'équipe" callback={handleSubmitForm} />

                </Col1>

                <Col2>

                </Col2>
            
            </Cols>


        </Wrapper>
    );

}

export default Team;