import moment from "moment";

const getFormattedCanal = (team) => {
    if(!team) return "Non chargée";
    return team.onlineSub ? "Neoscore Hub" : "Sportive";
}

const getFormattedPaymentCanal = (team) => {

    if(!team) return "Non chargée";

    const PaymentCanal = new Set();

    for(const c of team.players) {
        if(c.PlayersTeams.payed && c.PlayersTeams.payedOnline) PaymentCanal.add('Application');
        if(c.PlayersTeams.payed && !c.PlayersTeams.payedOnline) PaymentCanal.add('Sportive');
    }

    return Array.from(PaymentCanal).join(', ');
    
}

const getFormattedLevel = (team) => {
    return team.level.name;
}

const getFormattedRegistrationDate = (team) => {
    if(!team) return "Non chargée";
    const date = moment(team.createdAt);
    return date.format('DD/MM/YYYY HH:MM');
}

const getFormattedConfirmation = (team) => {
    if(!team) return {
        className: 'error',
        text: 'Non chargée'
    }
    if(team.confirmed) return {
        className: 'success',
        text: 'Confirmée'
    }
    return {
        className: 'error',
        text: 'Non confirmée'
    }
}

const getFormattedPayment = (team) => {
    if(!team) return {
        className: 'error',
        text: 'Non chargée'
    }

    let payed = true;

    for(const p of team.players) {
        if(p.PlayersTeams.payed === false) payed = false;
    }

    if(payed) return {
        className: 'success',
        text: 'Règlement complet'
    }
    return {
        className: 'error',
        text: 'Attente de règlement'
    }
}

const getFormattedEngagement = (t) => {

    if(!t.deletable) return {
        className: "info",
        text: "Engagée"
    }

    return {
        className: "error",
        text: "A engager"
    }

}

const teamModelConverter = {
    getFormattedCanal,
    getFormattedPaymentCanal,
    getFormattedLevel,
    getFormattedRegistrationDate,
    getFormattedConfirmation,
    getFormattedPayment,
    getFormattedEngagement
}

export default teamModelConverter;