import { useEffect, useState } from "react";
import styled from "styled-components"

/**
 * Styles
 */

const StyledInput = styled.select `

    border-radius:8px;
    border:1px solid black;
    background:white;
    padding:0 5px 0 3px;
    font-weight:550;
    outline:0;
    width:100%;
    height:100%;
    transition:all .3s ease;
    cursor:pointer;

    &.disabled {
        background:grey;
        cursor:default;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const SelectInput = (props) => {

    const [val, setVal] = useState(props.value || 0);
    const [opts, setOpts] = useState([]);

    useEffect(() => {
        setVal(props.value);
    }, [props.value]);

    useEffect(() => {
        setOpts(props.options ? [{
            value: 0,
            text: ""
        }, ...props.options] : [{
            value: 0,
            text: ""
        }]);
    }, [props.options])

    return (
        <StyledInput onChange={props.onChange} value={val} disabled={props.isDisabled} className={props.isDisabled ? "disabled" : ""}>
            {opts.map((opt) => (
                <option key={opt.value} value={opt.value}>{opt.text}</option>
            ))}
        </StyledInput>
    );
};

export default SelectInput;