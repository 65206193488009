import styled from "styled-components"
import AddBtn from "../Btns/AddBtn"

/**
 * Styles
 */

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:row;
    margin:12px 0 10px 0;
    justify-content:end;

    & > div {
        width:240px;
        height:80px;
    }

    & > div.small {
        width:160px;
        height:50px;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const ValidationSection = (props) => {

    return (
        <StyledDiv className={props.className}>
            <div className={props.small && "small"}>
                <AddBtn icon={props.icon} message1={props.message1} message2={props.message2} callback={props.callback} small={props.small} />
            </div>
        </StyledDiv>
    );
    
} 

export default ValidationSection;