import { useNavigate } from "react-router-dom"
import styled from "styled-components"

/**
 * Styles
 */
const TourWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:140px;
    margin:0 12px 12px 0;
    height:60px;
    border-radius:8px;
    -webkit-box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.23); 
    box-shadow: 0px 0px 15px -4px rgba(0,0,0,0.23);
    cursor:pointer;
    transition:all .3s ease;

    & > h6 {
        position:absolute;
        top:10px;
        left:10px;
        color:grey;
        margin:0;
        font-size:12px;

        z-index:2;
    }


    & > p {
        position:absolute;
        bottom:10px;
        left:10px;
        color:#34495e;
        margin:0;
        font-size:10px;
        font-weight:600;

        z-index:2;
    }

    &:hover {
        transform:scale(1.05);
    }

`

const ProgressBg = styled.div `

    position:absolute;
    top:0;
    left:0;
    height:100%;
    width: ${props => props.width + '%' || '0%'};
    background:#2ecc71;
    border-radius:8px;
    z-index:1;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const Tour = (props) => {

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/manager/' + props.tourId)
    }

    return (
        <TourWrapper onClick={handleNavigate}>
            <h6>{ props.title }</h6>
            <p>Tour complété à { props.progression }%</p>
            <ProgressBg className="progression" width={props.progression}></ProgressBg>
        </TourWrapper>
    );
    
}

export default Tour;