import styled from "styled-components";

/**
 * Styles
 */

const StyledDiv = styled.div `

    margin:0 0 30px 0;
    font-size:14px;

    & > h4 {
        margin:10px 0;
        font-weight:600;
        font-size:16px;
    }

    & > p {
        margin:10px 0;
        line-height:20px;
        color:#7f8c8d;
    }

    & > p > span > span {
        color:#2c3e50;
        font-weight:600;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const Info = (props) => {
    
    return (
        <StyledDiv>
            <h4>{props.title}</h4>
            <p>
                { props.elements.map((el,i) => (
                    <span key={i}><span>{ el.title }: </span> { el.value }<br /></span>
                ))}
            </p>
        </StyledDiv>
    );
    
}

export default Info;