import styled, { keyframes } from "styled-components"

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
`;

const StyledDiv = styled.div `

    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.7);
    z-index:9999;
    display:flex;
    justify-content:center;
    border-radius:12px;
    align-items:center;

    & > span {
        color:white;
        font-size:18px;
        font-weight:bold;
        animation: ${blinkAnimation} 1s linear infinite;
    }

`

const Loader = (props) => {

    return (

        props.isLoading &&

        <StyledDiv>
            <span>En attente de connexion ...</span>
        </StyledDiv>

    )

}

export default Loader;