import styled from "styled-components";
import TextInput from "../../components/Software/Inputs/TextInput";
import { useEffect, useState } from "react";
import SelectInput from "../../components/Software/Inputs/SelectInput";
import { useNavigate, useParams } from "react-router-dom";
import Popup from "../../components/Software/Popup/Popup";
import Section from "../../components/Software/Section/Section";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import CheckboxInput from "../../components/Software/Inputs/CheckboxInput";
import FormBlock from "../../components/Software/FormBlock/FormBlock";
import ValidationSection from "../../components/Software/ValidationSection/ValidationSection";
import TitleWrapper from "../../components/Software/TitleWrapper/TitleWrapper";
import FormBlockCategory from "../../components/Software/FormBlockCategory/FormBlockCategory";
import TextareaInput from "../../components/Software/Inputs/TextareaInput";
import FileInput from "../../components/Software/Inputs/FileInput";
import competitionValidator from "../../utils/validators/competition";
import fetchAPI from "../../utils/fetch";
import moment from "moment";
import Loader from "../../components/Loader/Loader";

/**
 * Styles
 */

const CompetitionNameDiv = styled.div `
    width:300px;
    height:32px;
`

const CompetitionPlaceDiv = styled.div `
    width:200px;
    height:32px;
`

const CompetitionDateDiv = styled.div `
    width:100px;
    height:32px;
`

const CompetitionTimeDiv = styled.div `
    width:100px;
    height:32px;
`

const CompetitionSportDiv = styled.div `
    width:auto;
    height:32px;
`

const CompetitionPaliersDiv = styled.div `
    width:auto;
    height:32px;
`

const CompetitionAverageDiv = styled.div `
    width:auto;
    height:32px;
`

const CompetitionIndividualDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionNumberTeamDiv = styled.div `
    width:32px;
    height:32px;
    display:inline-block;
    margin:0 10px;
`

const CompetitionPublishedDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionPrivateDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionPrivateMessageDiv = styled.div `
    width:400px;
    height:32px;
`
const CompetitionPrivateCodeDiv = styled.div `
    width:200px;
    height:32px;
`

const CompetitionOnlineRegistrationDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionOnlineRegistrationClosingDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionOnlineRegistrationClosingDateDiv = styled.div `
    width:100px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionOnlinePaymentDiv = styled.div `
    width:32px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionOnlinePaymentPriceDiv = styled.div `
    width:50px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionMaxDiv = styled.div `
    width:50px;
    height:32px;
    margin:0 10px 0 0;
`

const CompetitionDescriptionDiv = styled.div `
    width:800px;
    height:100px;
    margin:0 10px 0 0;
`

const CompetitionIllustrationDiv = styled.div `
    width:auto;
    height:32px;
    margin:0 10px 0 0;
`

/**
 * Component
 * @param {*} param0 
 * @returns 
 */
const Competition = ({ match }) => {

    // Get id provided in URL
    const { id } = useParams();

    // Pro
    const [pro, setPro] = useState(null);

    // Get navigation
    const navigate = useNavigate();

    // Init error
    const [error, setError] = useState(null);
    
    // Competition
    const [competition, setCompetition] = useState(null);

    const [competitionName, setCompetitionName] = useState("");
    const [competitionSport, setCompetitionSport] = useState(0);
    const [competitionDate, setCompetitionDate] = useState("");
    const [competitionTime, setCompetitionTime] = useState("");
    const [competitionScoreMode, setCompetitionScoreMode] = useState(0);
    const [competitionStepConfiguration, setCompetitionStepConfiguration] = useState(0);
    const [competitionMinimalComposition, setCompetitionMinimalComposition] = useState("");
    const [competitionMaximalComposition, setCompetitionMaximalComposition] = useState("");
    const [competitionIndividualComposition, setCompetitionIndividualComposition] = useState(false);
    const [competitionPublishedCompetition, setCompetitionPublishedCompetition] = useState(false);
    const [competitionPrivateCompetition, setCompetitionPrivateCompetition] = useState(false);
    const [competitionMessagePrivateCompetition, setCompetitionMessagePrivateCompetition] = useState("");
    const [competitionCodePrivateCompetition, setCompetitionCodePrivateCompetition] = useState("");
    const [competitionOnlineRegistration, setCompetitionOnlineRegistration] = useState(false);
    const [competitionOnlineRegistrationClosing, setCompetitionOnlineRegistrationClosing] = useState(false);
    const [competitionOnlineRegistrationClosingDate, setCompetitionOnlineRegistrationClosingDate] = useState("");
    const [competitionOnlinePayment, setCompetitionOnlinePayment] = useState(false);
    const [competitionOnlinePaymentPrice, setCompetitionOnlinePaymentPrice] = useState("");
    const [competitionDescription, setCompetitionDescription] = useState("");
    const [competitionPlace, setCompetitionPlace] = useState("");
    const [competitionMax, setCompetitionMax] = useState(0);

    // Sports
    const [sports, setSports] = useState([]);

    // Averages
    const [scoreModes, setScoreModes] = useState([]);

    // Steps
    const [stepConfigurations, setStepConfigurations] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    /**
     * Load scoresmodes from database
     */
    useEffect(() => {

        setIsLoading(true);

        (async () => {

            try {

                const request = await fetchAPI('GET', '/competition/scoremodes', {});

                if(request.status !== 200) {
                    if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/');
                        return;
                    }
                } else {
                    setScoreModes(request.json.data.scoreModes.map((s) => {
                        return {
                            value: s.key,
                            text: s.text
                        }
                    }));
                    setIsLoading(false);
                }

            }
            catch(e) {
                setError("Une erreur est survenue.");
                setIsLoading(false);
            }


        })();

        return () => {};

    }, [navigate]);

    /**
     * Load step configurations from database
     */
    useEffect(() => {

        setIsLoading(true);

        (async () => {

            try {

                const request = await fetchAPI('GET', '/competition/stepconfigurations', {});

                if(request.status !== 200) {
                    if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/');
                        return;
                    }
                } else {
                    setStepConfigurations(request.json.data.stepConfigurations.map((s) => {
                        return {
                            value: s.key,
                            text: s.text
                        }
                    }));
                    setIsLoading(false);
                }

            }
            catch(e) {
                setIsLoading(false);
                setError("Une erreur est survenue.");
            }


        })();

        return () => {};

    }, [navigate]);

    /**
     * Load sports from database
     */
    useEffect(() => {

        setIsLoading(true);

        (async () => {

            try {

                const request = await fetchAPI('GET', '/sport/all', {});

                if(request.status !== 200) {
                    if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/');
                        return;
                    }
                } else {
                    setSports(request.json.data.sports.map((s) => {
                        return {
                            value: s.sportId,
                            text: s.name
                        }
                    }));
                    setIsLoading(false);
                }

            }
            catch(e) {
                setIsLoading(false);
                setError("Une erreur est survenue.");
            }


        })();

        return () => {};

    }, [navigate]);

    /**
     * Load pro from database
     */
    useEffect(() => {

        setIsLoading(true);

        (async () => {

            try {

                const request = await fetchAPI('GET', '/pro', {});

                if(request.status !== 200) {
                    if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/');
                        return;
                    }
                } else {
                    setPro(request.json.data.pro);
                    setIsLoading(false);
                }

            }
            catch(e) {
                setError("Une erreur est survenue.");
                setIsLoading(false);
            }


        })();

        return () => {};

    }, [navigate]);


    /**
     * When data is loaded from database, fill form
     */
    useEffect(() => {

        setIsLoading(true);

        setCompetitionName(competition ? competition.name : "");
        setCompetitionPlace(competition ? competition.place : "");
        setCompetitionDate(competition ? moment(new Date(competition.dte)).format('DD/MM/YYYY') : "");
        setCompetitionTime(competition ? moment(new Date(competition.dte)).format('HH:mm') : "");
        setCompetitionSport(competition ? competition.sportId : 0);
        setCompetitionMaximalComposition(competition ? "" + competition.maxPlayersByTeam + "" : null);
        setCompetitionMinimalComposition(competition ? "" + competition.minPlayersByTeam + "" : null);
        setCompetitionIndividualComposition(competition ? (competition.maxPlayersByTeam && competition.minPlayersByTeam && competition.maxPlayersByTeam === 1 && competition.minPlayersByTeam === 1 ? true : false) : false);
        setCompetitionPublishedCompetition(competition ? competition.published : false);
        setCompetitionPrivateCompetition(competition ? competition.private_ : false);
        setCompetitionMessagePrivateCompetition(competition ? competition.message : null);
        setCompetitionCodePrivateCompetition(competition ? competition.code : null);
        setCompetitionOnlineRegistration(competition ? competition.onlineSub : false);
        setCompetitionOnlinePayment(competition ? competition.onlinePay : false);
        setCompetitionOnlineRegistrationClosing(competition && competition.limitDteOnline ? true : false);
        setCompetitionOnlineRegistrationClosingDate(competition && competition.limitDteOnline ? moment(new Date(competition.limitDteOnline)).format('DD/MM/YYYY') : "");
        setCompetitionMax(competition && competition.max ? "" + competition.max + "" : "");
        setCompetitionOnlinePaymentPrice(competition ? "" + competition.price + "" : "");
        setCompetitionDescription(competition && competition.description ? competition.description : null);

        setIsLoading(false);

        return () => {};

    }, [competition]);

    useEffect(() => {

        setIsLoading(true);

        (async () => {

            if(id) {

                try {

                    const request = await fetchAPI("GET", '/competition', {
                        competitionId: parseInt(id)
                    });

                    if(request.status !== 200) {
                        
                        if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                            navigate('/');
                            return;
                        }

                    } else {
                        setCompetition(request.json.data.competition);
                        setIsLoading(false);
                    }

                }
                catch(e) {
                    setError("Une erreur est survenue.");
                    setIsLoading(false);
                }

            }

        })();

    }, [id, navigate])

    // Handlers
    const handleChangeCompetitionName = (e) => {setCompetitionName(e.target.value); setError(null); };
    const handleChangeCompetitionPlace = (e) => {setCompetitionPlace(e.target.value); setError(null); };
    
    const handleChangeCompetitionDate = (e) => {
        if(e.target.value.length === 2 || e.target.value.length === 5) setCompetitionDate(e.target.value + "/");
        else setCompetitionDate(e.target.value);
        setError(null);
    };
    
    const handleChangeCompetitionTime = (e) => {
        if(e.target.value.length === 2) setCompetitionTime(e.target.value + ":");
        else setCompetitionTime(e.target.value);
        setError(null);
    }
    
    const handleChangeCompetitionScoreMode = (e) => {setCompetitionScoreMode(e.target.value); setError(null); }
    const handleChangeCompetitionMinimalComposition = (e) => {setCompetitionMinimalComposition(e.target.value); setError(null); }
    const handleChangeCompetitionMaximalComposition = (e) => {setCompetitionMaximalComposition(e.target.value); setError(null); }
    const handleChangeCompetitionMax = (e) => {setCompetitionMax(e.target.value); setError(null); }
    const handleChangeCompetitionIndividualComposition = (e) => {setCompetitionIndividualComposition(e);setError(null);}
    
    const handleChangeCompetitionPublishedCompetition = (e) => {
        setCompetitionPublishedCompetition(e);
        if(e === false) {
            handleChangeCompetitionOnlineRegistration(false);
            handleChangeCompetitionPrivateCompetition(false);
        }
        setError(null);
    }
    
    const handleChangeCompetitionPrivateCompetition = (e) => {setCompetitionPrivateCompetition(e); setError(null); }
    const handleChangeCompetitionMessagePrivateCompetition = (e) => {setCompetitionMessagePrivateCompetition(e.target.value); setError(null); }
    
    const handleChangeCompetitionCodePrivateCompetition = (e) => {setCompetitionCodePrivateCompetition(e.target.value); setError(null); }
    
    const handleChangeCompetitionOnlineRegistration = (e) => {
        setCompetitionOnlineRegistration(e);
        if(e === false) {
            setCompetitionOnlineRegistrationClosing(false);
            setCompetitionOnlinePayment(false);
        }
        setError(null);
    }
    
    const handleChangeCompetitionOnlineRegistrationClosing = (e) => {
        setCompetitionOnlineRegistrationClosing(e);
        if(!e) setCompetitionOnlineRegistrationClosingDate("");
        setError(null);
    }

    const handleChangeCompetitionOnlineRegistrationClosingDate = (e) => {setCompetitionOnlineRegistrationClosingDate(e.target.value); setError(null); }
    const handleChangeCompetitionOnlinePayment = (e) => {setCompetitionOnlinePayment(e); setError(null); }
    const handleChangeCompetitionOnlinePaymentPrice = (e) => {setCompetitionOnlinePaymentPrice(e.target.value); setError(null); }
    const handleChangeCompetitionDescription = (e) => {setCompetitionDescription(e.target.value); setError(null); }
    const handleChangeCompetitionSport = (e) => {setCompetitionSport(e.target.value); setError(null); }
    const handleChangeCompetitionStepConfiguration = (e) => {setCompetitionStepConfiguration(e.target.value); setError(null); }

    const handleSubmitForm = async () => {

        setIsLoading(true);
        
        try {

            if(competition) {

                competitionValidator(
                    competitionName,
                    competitionPlace,
                    competitionDate,
                    competitionTime,
                    competitionMinimalComposition,
                    competitionMaximalComposition,
                    competitionIndividualComposition,
                    competitionPublishedCompetition,
                    competitionPrivateCompetition,
                    competitionMessagePrivateCompetition,
                    competitionCodePrivateCompetition,
                    competitionOnlineRegistration,
                    competitionOnlineRegistrationClosing,
                    competitionOnlineRegistrationClosingDate,
                    competitionOnlinePayment,
                    competitionOnlinePaymentPrice,
                    competitionDescription,
                    1,
                    competitionSport,
                    1,
                    competitionMax
                );

            }
            else {

                competitionValidator(
                    competitionName,
                    competitionPlace,
                    competitionDate,
                    competitionTime,
                    competitionMinimalComposition,
                    competitionMaximalComposition,
                    competitionIndividualComposition,
                    competitionPublishedCompetition,
                    competitionPrivateCompetition,
                    competitionMessagePrivateCompetition,
                    competitionCodePrivateCompetition,
                    competitionOnlineRegistration,
                    competitionOnlineRegistrationClosing,
                    competitionOnlineRegistrationClosingDate,
                    competitionOnlinePayment,
                    competitionOnlinePaymentPrice,
                    competitionDescription,
                    parseInt(competitionScoreMode),
                    competitionSport,
                    parseInt(competitionStepConfiguration),
                    parseInt(competitionMax)
                );

            }

            const parseDateAndTime = (dateStr, timeStr) => {
                const dateParts = dateStr.split('/');
                if (dateParts.length !== 3) return null;
            
                const [day, month, year] = dateParts.map(Number);
                if (isNaN(day) || isNaN(month) || isNaN(year)) return null;
            
                const timeParts = timeStr.split(':');
                if (timeParts.length !== 2) return null;
            
                const [hours, minutes] = timeParts.map(Number);
                if (isNaN(hours) || isNaN(minutes)) return null;
            
                return new Date(year, month - 1, day, hours, minutes);
            };

            let competition_ = null;

            if(!competition) {
                competition_ = {
                    name: competitionName,
                    place: competitionPlace,
                    dte: parseDateAndTime(competitionDate, competitionTime),
                    minPlayersByTeam: !competitionIndividualComposition ? parseInt(competitionMinimalComposition) : 1,
                    maxPlayersByTeam: !competitionIndividualComposition ? parseInt(competitionMaximalComposition) : 1,
                    published: competitionPublishedCompetition,
                    private_: competitionPrivateCompetition,
                    message: competitionMessagePrivateCompetition, 
                    code: competitionCodePrivateCompetition,
                    onlineSub: competitionOnlineRegistration,
                    onlinePay: competitionOnlinePayment,
                    limitDte: competitionOnlineRegistrationClosingDate ? parseDateAndTime(competitionOnlineRegistrationClosingDate, "00:00") : null,
                    price: competitionOnlinePaymentPrice ? parseInt(competitionOnlinePaymentPrice) : 0,
                    description: competitionDescription,
                    sportId: parseInt(competitionSport),
                    scoreMode: parseInt(competitionScoreMode),
                    stepConfiguration: parseInt(competitionStepConfiguration),
                    max: competitionMax ? parseInt(competitionMax) : null
                }
            }
            else {
                competition_ = {
                    name: competitionName,
                    place: competitionPlace,
                    dte: parseDateAndTime(competitionDate, competitionTime),
                    minPlayersByTeam: !competitionIndividualComposition ? parseInt(competitionMinimalComposition) : 1,
                    maxPlayersByTeam: !competitionIndividualComposition ? parseInt(competitionMaximalComposition) : 1,
                    published: competitionPublishedCompetition,
                    private_: competitionPrivateCompetition,
                    message: competitionMessagePrivateCompetition, 
                    code: competitionCodePrivateCompetition,
                    onlineSub: competitionOnlineRegistration,
                    onlinePay: competitionOnlinePayment,
                    limitDte: competitionOnlineRegistrationClosingDate ? parseDateAndTime(competitionOnlineRegistrationClosingDate, "00:00") : null,
                    price: competitionOnlinePaymentPrice ? parseInt(competitionOnlinePaymentPrice) : 0,
                    description: competitionDescription,
                    sportId: parseInt(competitionSport),
                    max: competitionMax ? parseInt(competitionMax) : null,
                    competitionId: competition.competitionId
                } 
            }

            let method = "POST";

            if(competition) method = "PUT";

            const request = await fetchAPI(method, '/competition', {
                competition: competition_
            });

            if(request.status !== 200) {
                setError("Une erreur est survenue.");
                setIsLoading(false);
            } else {
                if(!competition) {
                    navigate("/");
                    return;
                }
                navigate('/dashboard');
                return;
            }

        }
        catch(e) {
            setError(e.message);
            setIsLoading(false);
        }

    }

    return (

        pro ?

        <Wrapper>

            <Popup content={[error]} />
            <Loader isLoading={isLoading} />

            <TitleWrapper title={competition ? "Modifier la compétition" : "Créer une compétition"} />

            <Section align="sb">

                <FormBlock>
                    <label>Nom</label>
                    <CompetitionNameDiv>
                        <TextInput placeholder="Nom de la compétition" value={competitionName} onChange={handleChangeCompetitionName} />
                    </CompetitionNameDiv>
                </FormBlock>

                <FormBlock>
                    <label>Lieu</label>
                    <CompetitionPlaceDiv>
                        <TextInput placeholder="Lieu de la compétition" value={competitionPlace} onChange={handleChangeCompetitionPlace} />
                    </CompetitionPlaceDiv>
                </FormBlock>

                <FormBlock>
                    <label>Date</label>
                    <CompetitionDateDiv>
                        <TextInput value={competitionDate} onChange={handleChangeCompetitionDate} placeholder="dd/mm/yyyy" />
                    </CompetitionDateDiv>
                </FormBlock>

                <FormBlock>
                    <label>Heure</label>
                    <CompetitionTimeDiv>
                        <TextInput value={competitionTime} onChange={handleChangeCompetitionTime} placeholder="hh:mm" />
                    </CompetitionTimeDiv>
                </FormBlock>

                <FormBlock>
                    <label>Sport</label>
                    <CompetitionSportDiv>
                        <SelectInput options={sports} value={competitionSport} onChange={handleChangeCompetitionSport} />
                    </CompetitionSportDiv>
                </FormBlock>

            </Section>

            {
            
            !competition ?

            <Section title="Calcul des scores" align="start">

                <FormBlockCategory title="Comptage des points" />

                <FormBlock>
                    <label>Paliers</label>
                    <CompetitionPaliersDiv>
                        <SelectInput options={stepConfigurations} value={competitionStepConfiguration} onChange={handleChangeCompetitionStepConfiguration} />
                    </CompetitionPaliersDiv>
                </FormBlock>

                <FormBlock>
                    <label>Average</label>
                    <CompetitionAverageDiv>
                        <SelectInput options={scoreModes} value={competitionScoreMode} onChange={handleChangeCompetitionScoreMode} />
                    </CompetitionAverageDiv>
                </FormBlock>

            </Section>

            :

            null
        
            }

            <Section title="Equipes" align="start">

                <FormBlockCategory title="Composition" />

                <FormBlock>
                    <div>
                        <CompetitionNumberTeamDiv>
                            <TextInput value={competitionMinimalComposition} onChange={handleChangeCompetitionMinimalComposition} isDisabled={competitionIndividualComposition} />
                        </CompetitionNumberTeamDiv>
                        à
                        <CompetitionNumberTeamDiv>
                            <TextInput value={competitionMaximalComposition} onChange={handleChangeCompetitionMaximalComposition} isDisabled={competitionIndividualComposition} />
                        </CompetitionNumberTeamDiv>
                        joueurs
                    </div>
                </FormBlock>

                <FormBlock>
                    <CompetitionIndividualDiv>
                        <CheckboxInput value={competitionIndividualComposition} onChange={handleChangeCompetitionIndividualComposition} />
                    </CompetitionIndividualDiv>
                    <label>Compétition individuelle</label>
                </FormBlock>

            </Section>

            <Section title="Hub" align="sb">

                <FormBlockCategory title="Accessibilité" />

                <FormBlock>
                    <CompetitionPublishedDiv>
                        <CheckboxInput value={competitionPublishedCompetition} onChange={handleChangeCompetitionPublishedCompetition} />
                    </CompetitionPublishedDiv>
                    <label>Compétition publiée</label>
                </FormBlock>

                <FormBlock>
                    <CompetitionPrivateDiv>
                        <CheckboxInput value={competitionPrivateCompetition} onChange={handleChangeCompetitionPrivateCompetition} isDisabled={!competitionPublishedCompetition} />
                    </CompetitionPrivateDiv>
                    <label>Compétition privée</label>
                    <CompetitionPrivateMessageDiv>
                        <TextInput placeholder="Message (ex: 'Competition réservée à ...')" value={competitionMessagePrivateCompetition} onChange={handleChangeCompetitionMessagePrivateCompetition} isDisabled={!competitionPrivateCompetition} />
                    </CompetitionPrivateMessageDiv>
                </FormBlock>

                <FormBlock>
                    <CompetitionPrivateCodeDiv>
                        <TextInput placeholder="Code d'inscription" value={competitionCodePrivateCompetition} onChange={handleChangeCompetitionCodePrivateCompetition} isDisabled={!competitionPrivateCompetition} />
                    </CompetitionPrivateCodeDiv>
                </FormBlock>

            </Section>

            <br />

            <Section align="start">

                <FormBlockCategory title="Inscription" />

                <FormBlock>
                    <CompetitionOnlineRegistrationDiv>
                        <CheckboxInput value={competitionOnlineRegistration} onChange={handleChangeCompetitionOnlineRegistration} isDisabled={!competitionPublishedCompetition} />
                    </CompetitionOnlineRegistrationDiv>
                    <label>Activer l'inscription en ligne</label>
                </FormBlock>

                <FormBlock>
                    <CompetitionOnlineRegistrationClosingDiv>
                        <CheckboxInput value={competitionOnlineRegistrationClosing} onChange={handleChangeCompetitionOnlineRegistrationClosing} isDisabled={!competitionOnlineRegistration} />
                    </CompetitionOnlineRegistrationClosingDiv>
                    <label>Programmer la clôture au</label>
                    <CompetitionOnlineRegistrationClosingDateDiv>
                        <TextInput placeholder="28/07/2024" value={competitionOnlineRegistrationClosingDate} onChange={handleChangeCompetitionOnlineRegistrationClosingDate} isDisabled={!competitionOnlineRegistrationClosing} />
                    </CompetitionOnlineRegistrationClosingDateDiv>
                </FormBlock>

                <FormBlock>
                    <label>Limite</label>
                    <CompetitionMaxDiv>
                        <TextInput value={competitionMax} onChange={handleChangeCompetitionMax} isDisabled={!competitionOnlineRegistration} />
                    </CompetitionMaxDiv>
                    équipes
                </FormBlock>

            </Section>

            <br />

            <Section align="start">

                <FormBlockCategory title="Paiement en ligne" />

                <FormBlock>
                    <CompetitionOnlinePaymentDiv>
                        <CheckboxInput value={competitionOnlinePayment} onChange={handleChangeCompetitionOnlinePayment} isDisabled={!competitionOnlineRegistration} />
                    </CompetitionOnlinePaymentDiv>
                    <label>Activer le paiement en ligne</label>
                    <CompetitionOnlinePaymentPriceDiv>
                        <TextInput placeholder="Prix" value={competitionOnlinePaymentPrice} onChange={handleChangeCompetitionOnlinePaymentPrice} />
                    </CompetitionOnlinePaymentPriceDiv>
                    € par joueur
                </FormBlock>

            </Section>

            <br /><br />

            <Section align="start">

                <FormBlockCategory title="Social" />

                <FormBlock>
                    <CompetitionDescriptionDiv>
                        <TextareaInput placeholder="Description de l'évènement" value={competitionDescription} onChange={handleChangeCompetitionDescription} isDisabled={!competitionPublishedCompetition} />
                    </CompetitionDescriptionDiv>
                </FormBlock>

                <ValidationSection icon="save" message1="Enregistrer" message2="la compétition" callback={handleSubmitForm} />

            </Section>
            
            <br />

            <Section align="start">

                <FormBlockCategory title="Illustration" />
                
                <FormBlock>
                    <CompetitionIllustrationDiv>
                        <FileInput isDisabled={!competitionPublishedCompetition} />
                    </CompetitionIllustrationDiv>
                </FormBlock>
            </Section>

        </Wrapper>

        :

        null

    );

}

export default Competition;