import moment from "moment";

const getFormattedBilling = (payed) => {
    if(payed) return {
        className: 'success',
        text: "Réglé"
    };
    return {
        className: 'error',
        text: "Non réglé"
    };
}

const getFormattedYearsOld = (birthdate) => {
    const today = new Date();
    const birth = new Date(birthdate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
        age--;
    }

    if(isNaN(age)) age = "0";
    
    return age + " ans";

}

const getFormattedFormBirthDate = (birthdate) => {
    return moment(birthdate).format("DD/MM/YYYY");
}

const playerConverter = {
    getFormattedBilling,
    getFormattedYearsOld,
    getFormattedFormBirthDate
}

export default playerConverter;