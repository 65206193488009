import { Link } from "react-router-dom";
import styled from "styled-components";

const Ept = styled.div `

    text-align:center;
    margin:50px 0;
    font-size:18px;
    font-weight:bold;
    color:black;

    & > ion-icon {
        font-size:100px;
        color:#bdc3c7;
    }

    & > p {
        margin:20px 0 10px 0;
    }

`

const StyledLink = styled(Link) `

    text-decoration:none;
    margin:0;
    color:#f1c40f;

`

const EmptyList = (props) => {
    return (
        <Ept>
            <ion-icon name="file-tray-full-outline"></ion-icon>
            <p>Aucun élément</p>
            { props.link ? <StyledLink to={ props.link.path }>{ props.link.text }</StyledLink> : null }
        </Ept>
    )
}

export default EmptyList;