import styled from "styled-components";

const StyledTr = styled.div `

    height:54px;
    flex-shrink: 0;
    width:calc(100% - 10px);
    border-radius:8px;
    margin:10px 5px;
    -webkit-box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.46); 
    box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.46);
    display:flex;
    justify-content:space-between;
    line-height:54px;
    font-size:14px;
    font-weight:500;
    cursor:pointer;
    transition:all .3s ease;

    &:hover {
        background:#ecf0f1;
        transition:all .3s ease;
    }

    & > div:first-child {
        font-weight:700;
        margin-left:26px;
    }

    & > div {
        width:auto;
    }

    & > div.error {
        color:#e74c3c;
    }

    & > div.success {
        color:#2ecc71;
    }

    & > div.btns {
        display:flex;
        position:absolute;
        right:0.5%;
        height:54px;
        justify-content:end;
        align-items:center;
        padding-right:5px;
    }

    & > div.btns > div.btn {
        height:38px;
        width:38px;
        display:flex;
        justify-content:center;
        align-items:center;
        margin:0 5px;
        border-radius:8px;
        color:white;
    }

    & > div.btns > div.btn.large {
        width:auto;
        padding:3px 10px;
    }

    & > div.btns > div.btn.info {
        background:#3498db;
        font-weight:600;
    }

    & > div.btns > div.btn.info:hover {
        cursor:pointer;
        background:#2980b9;
        transition:all .3s ease;
    }

    & > div.btns > div.btn.success {
        background:#2ecc71;
    }

    & > div.btns > div.btn.error:hover {
        cursor:pointer;
        background:#c0392b;
        transition:all .3s ease;
    }

    & > div.btns > div.btn.error {
        background:#e74c3c;
    }

    & > div.btns > div.btn.success:hover {
        cursor:pointer;
        background:#27ae60;
        transition:all .3s ease;
    }

`

const Row = (props) => {
    return <StyledTr {...props}>{ props.children }</StyledTr>
}

export default Row;