import moment from "moment";

const getFormattedTime = (log) => {
    if(!log) return "Non chargé";
    const date = moment(log.createdAt);
    return date.format('DD/MM/YYYY HH:MM');
}

const logModelConverter = {
    getFormattedTime
}

export default logModelConverter;