import styled from "styled-components";
import Row from "../../Table/Row/Row";
import fieldModelConverter from "../../../../utils/modelConverters/field";

const StyledTr = styled(Row) `

    & > div:first-child {
        position:absolute;
        left:0;
        margin:0 0 0 20px;
    }

    & > div:nth-child(2) {
        position:absolute;
        left:30%;
    }

    & > div:nth-child(3) {
        position:absolute;
        left:86%;
        font-weight:600;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const FieldBlock = (props) => {

    return (
        props.field && props.openField && props.deleteField ?
        <StyledTr onMouseOver={props.onHover} onClick={() => props.openField(props.field.fieldId)}>
            <div>{props.field.name}</div>
            <div>{props.field.coeff}</div>
            <div className={fieldModelConverter.getFormattedAvailableStatus(props.field).className}>{ fieldModelConverter.getFormattedAvailableStatus(props.field).text }</div>
            <div className="btns">
                <div className="btn error" onClick={(event) => props.deleteField(event, props.field.fieldId)}><ion-icon name="trash"></ion-icon></div>
            </div>
        </StyledTr>
        : null
    );
    
}

export default FieldBlock;