import styled from "styled-components";

const StyledDiv = styled.div `

    background:white;
    border:1px solid black;
    border-radius:8px;
    display:flex;
    flex-direction:column;
    width:120px;
    flex-shrink:0;
    margin:0 8px 8px 0;
    align-items:center;
    justify-content:center;
    cursor:pointer;

    & > h4 {
        margin:10px;
        padding:0;
        font-size:12px;
    }

    & > div {
        display:flex;
        flex-direction:column;
        margin:10px;
    }

    & > div > h6 {
        margin:2px;
        color:grey;
    }

    &.selected {
        background:#2c3e50;
        color:white;
    }

`

const PouleBlock = (props) => {

    return (

        props.pouleId === 0 ?

        <StyledDiv className={props.selectedDerivationPouleId === props.pouleId && "selected"} onClick={() => props.handleChangeSelectedDerivationPouleId(props.pouleId)}>
            <h4>Créer une poule</h4>
        </StyledDiv>

        :

        <StyledDiv className={props.selectedDerivationPouleId === props.pouleId && "selected"} onClick={() => props.handleChangeSelectedDerivationPouleId(props.pouleId)}>
            <h4>Poule {props.nbPoule}</h4>
            <div>
                {props.teams.map((t) => <h6>{t.name}</h6>)}
            </div>
        </StyledDiv>

    );

}

export default PouleBlock;