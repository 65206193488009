import LoginBox from "../../components/Authentication/LoginBox/LoginBox"

const Login = (props) => {
    
    return (
        <LoginBox page={props.page} />
    )

}

export default Login;