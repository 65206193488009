import styled from "styled-components"

/**
 * Styles
 */

const StyledTextarea = styled.textarea `

    border-radius:8px;
    border:0;
    background:#ecf0f1;
    padding:8px;
    font-weight:550;
    outline:0;
    width:100%;
    height:100%;
    transition:all .3s ease;

    &:hover {
        transition:all .3s ease;
        background:#d9dadb;
    }

    &.disabled {
        background:grey;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TextareaInput = (props) => {
    return <StyledTextarea placeholder={props.placeholder} id={props.id} onChange={props.onChange} value={props.value} disabled={props.isDisabled} className={props.isDisabled ? "disabled" : ""}></StyledTextarea>
}

export default TextareaInput;