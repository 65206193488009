import styled from "styled-components";
import TeamBlock from "./TeamBlock/TeamBlock";
import EmptyList from "../EmptyList/EmptyList";

/**
 * Styles
 */

const TeamListWrapper = styled.div `

    position:relative;
    display:flex;
    flex-direction:column;
    top:0;
    left:0;
    width:100%;
    border-collapse: collapse;
    overflow-y:auto;
    height:100%;
    border-spacing:0 10px;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TeamList = (props) => {

    // Handler
    const onHover = (id) => {
        props.onHoverElement(id);
    }

    return (

        props.teams && props.openTeam && props.deleteTeam && props.confirmTeam && props.handleDerivate &&

        <>
            {props.teams.length === 0 ? <EmptyList link={{path: '/add-team', text: "Inscrivez une première équipe"}} /> : null}
            <TeamListWrapper>
                { props.teams.map((team) => (
                    <TeamBlock key={team.team.teamId} team={team} onHover={() => onHover(team.team.teamId)} openTeam={props.openTeam} deleteTeam={props.deleteTeam} confirmTeam={props.confirmTeam} handleDerivate={props.handleDerivate} />
                )) }
            </TeamListWrapper>
        </>

    );
    
}

export default TeamList;