import styled from "styled-components";
import TourList from "./TourList/TourList";
import PouleList from "./PouleList/PouleList";
import ValidationSection from "../ValidationSection/ValidationSection";

const StyledDiv = styled.div `

    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.3);
    z-index:9999;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    & > div {
        position:relative;
        width:900px;
        height:700px;
        background:white;
        border-radius:8px;
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        padding:30px;
    }

    & > div > div:nth-child(2) > h4 {
        font-size:20px;
        margin:0;
    }

    & > div > div:nth-child(2) > h5 {
        color:#f1c40f;
        font-size:16px;
        margin:15px 0;
    }

    & > div > div.close {
        position:absolute;
        top:0;
        right:0;
        color:grey;
        font-size:35px;
        margin:10px;
        cursor:pointer;
        transition:all .3s ease;
    }

    & > div > div.close:hover {
        transition:all .3s ease;
        color:#e74c3c;
    }

`

const DerivationSection = (props) => {

    return (
        <StyledDiv>

            <div>

                <div className="close" onClick={props.handleCloseDerivate}><ion-icon name="close"></ion-icon></div>

                <div>

                    <h4>Engager l'équipe { props.team.name } dans la compétition</h4>

                    <h5>1/ Choisir un tour</h5>
                    <TourList selectedDerivationTourId={props.selectedDerivationTourId} handleChangeSelectedDerivationTourId={props.handleChangeSelectedDerivationTourId} tours={props.tours} />

                    {
                        props.poules && props.selectedDerivationTourId &&
                        <>
                            <h5>2/ Choisir une poule</h5>
                            <PouleList selectedDerivationPouleId={props.selectedDerivationPouleId} handleChangeSelectedDerivationPouleId={props.handleChangeSelectedDerivationPouleId} poules={props.poules} />
                        </>
                    }

                </div>

                <ValidationSection icon="git-branch-outline" message1="Engager" message2="l'équipe" callback={props.handleSubmitDerivation} />

            </div>

        </StyledDiv>
    )
}

export default DerivationSection;