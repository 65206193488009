import styled from "styled-components";

/**
 * Styles
 */

const PopupWrapper = styled.div `

    position:fixed;
    top:0;
    right:0;
    background:rgba(231, 76, 60,0.96);
    color:white;
    font-weight:bold;
    height:60px;
    padding:0 20px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:10px;
    border-radius:8px;

    & > ion-icon {
        font-size:30px;
        padding:10px 10px 10px 0;
    }

`

/**
 * Components
 * @param {*} props 
 * @returns 
 */
const Popup = (props) => {

    let type = null;
    let message = null;

    if(props.content && props.content[0]) {
        type = "error";
        message = props.content[0];
    }
    if(props.content && props.content[1]) {
        type = "success";
        message = props.content[1];
    }

    if(type) {
        return (
            <PopupWrapper className={type}>
                {type === "error" ? <ion-icon name="alert-circle"></ion-icon> : <ion-icon name="checkmark-circle"></ion-icon>}
                <p>{message}</p>
            </PopupWrapper>
        )
    }


}

export default Popup;