import styled from "styled-components";
import PlayerBlock from "./PlayerBlock/PlayerBlock"
import { useState } from "react";
import EditPlayerForm from "./EditPlayerForm/EditPlayerForm";
import playerValidator from "../../../utils/validators/player";
import Popup from "../Popup/Popup";
import playerConverter from "../../../utils/modelConverters/player";

/**
 * Styles
 */

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    display:flex;
    width:100%;
    flex-direction:column!important;

`

const StyledBtn = styled.div `

    background:#2ecc71;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:start;
    width:200px;
    margin:10px 0;
    color:white;
    border-radius:8px;
    padding:6px 0;
    transition:all .3s ease;

    & > ion-icon {
        font-size:40px;
        margin:0 0 0 8px;
    }

    & > p {
        margin:0 0 0 8px;
        font-size:14px;
    }

    &:hover {
        background:#27ae60;
        transition:all .3s ease;
        cursor:pointer;
    }

`

const W = styled.div `
    display:flex;
    flex-direction:column;
    width:100%;
`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const PlayerList = (props) => {

    // Switch for display player form popup
    const [editPlayerForm, setEditPlayerForm] = useState(false);

    // players
    const [player, setPlayer] = useState(null);

    const [error, setError] = useState(null);


    // Handlers

    const togglePlayerForm = () => {
        if(editPlayerForm) setPlayer(null);
        setEditPlayerForm(editPlayerForm ? false : true);
    }

    const hidePlayerForm = (e) => {
        if(e.keyCode === 27) togglePlayerForm();
    }

    const updateSelectedPlayer = (player) => {
        setPlayer(player);
        togglePlayerForm();
    }

    const onAddPlayer = (player) => {

        function isISODateString(str) {
            const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(Z|([+-])(\d{2}):(\d{2}))?$/;
        
            // Vérifier si la chaîne correspond au format ISO 8601
            if (!isoDateRegex.test(str)) {
                return false;
            }
        
            // Vérifier si la chaîne peut être convertie en une date valide
            const date = new Date(str);
            return date.toISOString() === str;
        }

        try {
            const isoDate = isISODateString(player.birthDate) ? playerConverter.getFormattedFormBirthDate(player.birthDate) : player.birthDate;
            player = {...player, birthDate: isoDate};
            playerValidator(player.firstname, player.name, player.email, player.phone, player.birthDate);
            togglePlayerForm();
            props.onAddPlayer(player);
        }
        catch(e) {
            setError(e.message);
        }
        
    }

    // Listeners
    document.addEventListener("keydown", hidePlayerForm);

    return (
        
        props.players && props.onDeletePlayer && props.onAddPlayer ?

        <W>
            <Popup content={[error]} />
            {editPlayerForm ? <EditPlayerForm onAddPlayer={onAddPlayer} hideForm={togglePlayerForm} player={player ? player : null} /> : ""}
            <StyledDiv>
                { props.players.map((player) => (
                    <PlayerBlock key={player.email} player={player} onDeletePlayer={props.onDeletePlayer} onClick={updateSelectedPlayer} />
                )) }
            </StyledDiv>
            <StyledBtn className="btn" onClick={togglePlayerForm}><ion-icon name="add-circle"></ion-icon><p>Ajouter un joueur</p></StyledBtn>
        </W>

        : null
        
    );

}

export default PlayerList;