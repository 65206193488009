import styled from "styled-components";

const IllustrationBoxWrapper = styled.div `

    background-image:url('https://www.pixel4k.com/wp-content/uploads/2021/03/abstract-4k_1616870926.jpg.webp');
    background-size:cover;
    border-top-right-radius:14px;
    border-bottom-right-radius:14px;
    width:60%;

`

const IllustrationBox = () => {
    return (
    <IllustrationBoxWrapper>
    </IllustrationBoxWrapper>
    )
}

export default IllustrationBox;