import styled from "styled-components"

/**
 * Styles
 */

const StyledInput = styled.input `

    border-radius:8px;
    border:0;
    background:#ecf0f1;
    padding:0 0 0 8px;
    font-weight:550;
    outline:0;
    width:100%;
    height:100%;
    transition:all .3s ease;

    &:hover {
        transition:all .3s ease;
        background:#d9dadb;
    }

    &.disabled {
        background:grey;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const FileInput = (props) => {
    return <StyledInput type="file" value={props.value} placeholder={props.placeholder} id={props.id} onChange={props.onChange} disabled={props.isDisabled} className={props.isDisabled ? "disabled" : "" } />
}

export default FileInput;