import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:260px;
    height:90px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    border-radius:10px;
    display:flex;
    flex-direction:column;
    align-items:start;
    justify-content:start;
    margin:0 7px;
    transition:all .3s ease;

    & > div:first-child {
        display:flex;
        flex-direction:row;
        width:100%;
        padding:12px 12px 0 12px;
        justify-content:space-between;
    }

    & > div:first-child > h6:first-child {
        margin:0;
        color:#34495E;
        font-weight:1000;
        text-transform:uppercase;
    }

    & > div:first-child > h6:nth-child(2) {
        margin:0;
        font-weight:700;
        text-transform:uppercase;
    }

    & > div:nth-child(2) {
        display:flex;
        flex-direction:row;
        width:100%;
        padding:8px 0 0 12px;
        justify-content:space-between;
    }

    & > div:nth-child(2) > h6 {
        margin:0;
        color:#34495E;
        font-weight:500;
    }

    & > div:nth-child(3) {
        display:flex;
        flex-direction:row;
        width:100%;
        padding:3px 0 0 12px;
        justify-content:space-between;
    }

    & > div:nth-child(3) > h6 {
        margin:0;
        color:#34495E;
        font-weight:500;
    }

    & > div:nth-child(4) {
        display:flex;
        flex-direction:row;
        width:100%;
        padding:3px 12px 0 12px;
        justify-content:space-between;
    }

    & > div:nth-child(4) > h6:first-child {
        margin:0;
        font-weight:500;
    }

    & > div:nth-child(4) > h6:nth-child(2) {
        margin:0;
        color:#f1c40f;
        font-weight:500;
        text-transform:uppercase;
        font-weight:700;
    }

    &:hover {
        transform:scale(1.05);
        transition:all .3s ease;
        cursor:pointer;
    }

    &.transparent {
        opacity:0.6;
    }

`

const TourBlock = (props) => {

    return (
        props.tour &&
        <StyledDiv className={ props.transparent && "transparent" } onClick={() => props.handleOpenTour(props.tour.tourId)}>
            <div>
                <h6>{ props.tour.name }</h6>
                <h6 className="success">Complété à { props.tour.complete }%</h6>
            </div>
            <div>
                <h6>{ props.tour.nbTeams } équipes</h6>
            </div>
            <div>
                <h6>{ props.tour.nbPoules } { !props.tour.isFinal ? " poules" : " phases" }</h6>
            </div>
            <div>
                <h6 className="success">{ props.tour.nbgames } matchs au total</h6>
                <h6>{ props.tour.isFinal === false ? "Poules" : "Tableau" } </h6>
            </div>
        </StyledDiv>
    )
}

export default TourBlock;