import styled from "styled-components";

const StyledDiv = styled.div `

    background:white;
    border:1px solid black;
    border-radius:8px;
    display:flex;
    width:120px;
    height:40px;
    flex-shrink:0;
    margin:0 8px 8px 0;
    align-items:center;
    justify-content:center;
    cursor:pointer;

    & > h4 {
        margin:0;
        padding:0;
        font-size:12px;
    }

    &.selected {
        background:#2c3e50;
        color:white;
    }

`

const TourBlock = (props) => {

    return (

        <StyledDiv className={props.selectedDerivationTourId === props.tourId && "selected"} onClick={() => props.handleChangeSelectedDerivationTourId(props.tourId)}>
            <h4>{props.tourName}</h4>
        </StyledDiv>

    );

}

export default TourBlock;