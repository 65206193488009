import styled from "styled-components";
import TitlePage from "../../components/Software/TitlePage/TitlePage";
import TextInput from "../../components/Software/Inputs/TextInput";
import { useEffect, useState } from "react";
import AddBtn from "../../components/Software/Btns/AddBtn";
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";
import fetchAPI from "../../utils/fetch";
import Popup from "../../components/Software/Popup/Popup";
import FieldList from "../../components/Software/FieldList/FieldList";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import Loader from "../../components/Loader/Loader";

/**
 * Styles
 */

const Cols = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:85%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;

`

const Col1 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;

`

const Col2 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:17%;
    display:flex;
    justify-content:start;
    align-items:start;
    flex-direction:column;
    overflow-y:auto;

`

const HeaderTitleWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;
    display:flex;
    justify-content:space-between;
    align-items:center;

`

const SearchEngine = styled.div `

    width:500px;
    height:38px;
    
`

/**
 * Component
 * @returns 
 */
const Fields = () => {

    // Get navigation
    const navigate = useNavigate();

    // Get cookies
    const [cookies] = useCookies(['competition']);

    // Teams
    const [fields, setFields] = useState([]);
    const [fieldsRestriction, setFieldsRestriction] = useState(null);

    // Competition
    const [competition, setCompetition] = useState(null);

    // Fetch trigger
    const [fetchTrigger, setFetchTriger] = useState(0);

    // Errors
    const [error, setError] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    /**
     * Test if token exists
     */
    useEffect(() => {
        if(!cookies.proAuthorization) navigate('/login');
    }, [cookies.proAuthorization, navigate]);

    /**
     * Load competition from database
     */
    useEffect(() => {

        setIsLoading(true);

        (async () => {

            // Check for cookie
            if(!cookies.competition) {
                navigate('/');
                return;
            }

            try {

                // Fetch
                const request = await fetchAPI('GET', '/competition', {
                    competitionId: parseInt(cookies.competition)
                });

                if(request.status !== 200) {
                    if(request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/logout');
                        return;
                    }
                    throw new Error();
                } else {
                    setCompetition(request.json.data.competition);
                    setIsLoading(false);
                }

            }
            catch(e) {
                setError("Une erreur est survenue.");
                setIsLoading(false);
            }

        })();

    }, [cookies.competition, navigate])

    /**
     * Load fields from database
     */
    useEffect(() => {

        setIsLoading(true);

        if(!cookies.competition) {
            navigate('/');
            return;
        }

        (async () => {

            if(competition) {

                try {

                    const request = await fetchAPI('GET', '/field/allofcompetition', {
                        competitionId: parseInt(cookies.competition)
                    });
    
                    if(request.status !== 200) {
                        if(request.json.message === "ERR_PLEASE_RECONNECT") {
                            navigate('/logout');
                            return;
                        }
                        throw new Error();
                    } else {
                        setFields(request.json.data.fields);
                        setIsLoading(false);
                    }

                }
                catch(e) {
                    setError("Une erreur est survenue.");
                    setIsLoading(false);
                }

            }
            
        })();

        return () => {};

    }, [competition, cookies.competition, navigate, fetchTrigger]);

    // Handlers

    /**
     * Add btn handler
     * @param {*} e 
     */
    const redirectToAddField = (e) => {
        e.preventDefault();
        navigate("/add-field");
    }

    /**
     * Search engine handler
     * @param {*} e 
     */
    const handleSearch = (e) => {

        const search = e.target.value;

        if(search !== null && search.length !== 0) {

            setFieldsRestriction(fields.filter((f) => {
                return f.name.toLowerCase().includes(search.toLowerCase());
            }));

        } else {
            setFieldsRestriction(null);
        }

    }

    /**
     * Open handler
     * @param {*} id 
     */
    const openField = (id) => {
        navigate('/field/' + id);
    }

    /**
     * Delete handler
     * @param {*} event 
     * @param {*} id 
     * @returns 
     */
    const deleteField = async (event, id) => {

        event.stopPropagation();

        const cf = window.confirm("Souhaitez-vous vraiment supprimer le terrain ?");

        if(cf) {

            try {

                const deleteRequest = await fetchAPI('DELETE', '/field', {
                    fieldId: id
                });
    
                if(deleteRequest.status !== 200) {
                    if(deleteRequest.json.message === "ERR_LINKED_GAMES") throw new Error("Des matchs sont liés à ce terrain.");
                    else if(deleteRequest.status === 403 && deleteRequest.json.message === "ERR_PLEASE_RECONNECT") navigate('/logout')
                    else throw new Error("Une erreur est survenue.");
                    return;
                }
    
                setFetchTriger(fetchTrigger + 1);

            }
            catch(e) {
                setError(e.message.includes('fetch') ? "Une erreur est survenue" : e.message);
            }

        }

    }

    return (

        fields ?

        <Wrapper>

            <Loader isLoading={isLoading} />

            <Popup content={[error]} />

            <HeaderTitleWrapper>
                <TitlePage title="Liste des terrains" />
                <SearchEngine>
                    <TextInput placeholder="Rechercher des terrains ..." onChange={handleSearch} />
                </SearchEngine>
            </HeaderTitleWrapper>

            <Cols>

                <Col1>
                    <FieldList fields={fieldsRestriction === null ? fields : fieldsRestriction} openField={openField} deleteField={deleteField} />
                </Col1>

                <Col2>
                    <AddBtn message1="Ajouter" message2="un terrain" callback={redirectToAddField} />
                </Col2>

            </Cols>

        </Wrapper>

        : null

    );

}

export default Fields;