export const tourTypes_ = [
    {
        value: 1,
        text: 'Tour de Poules'
    },
    {
        value: 2,
        text: 'Tour final'
    }
];

export const poulesCompositions_ = [
    {
        value: 1,
        text: 'Composition par niveau d\'inscription'
    },
    {
        value: 2,
        text: 'Composition aléatoire'
    },
    {
        value: 3,
        text: 'Brassage par décalage'
    },
    {
        value: 4,
        text: 'Répartition en serpentin'
    }
];

const tourValidator = (name, tourType, poulesComposition, selectedTeams, tourNbTeams) => {

    if(typeof name !== "string") throw new Error("Le nom est invalide.");
    if(typeof tourNbTeams !== "string") throw new Error("Le nombre d'équipes par poule est invalide.");
    if(tourNbTeams !== "3" && tourNbTeams !== '4') throw new Error("Le nombre d'équipes par poule doit être de 3 ou 4.");
    if(name.length === 0) throw new Error("Le nom ne peut pas être vide.");
    if(name.length > 20) throw new Error("Le nom est trop long.");
    if(typeof tourType !== 'string') throw new Error("Le type de tour est invalide.");
    if(tourType === '0') throw new Error("Veuillez préciser un type de tour.");
    if(typeof poulesComposition !== 'string') throw new Error("Le type de composition des Poules est invalide.");
    if(poulesComposition === '0') throw new Error("Veuillez préciser un type de composition des Poules.");
    if(selectedTeams.length < 2) throw new Error("Vous devez qualifier au minimum 2 équipes.");

    return;

}

export default tourValidator;