import styled from "styled-components"

/**
 * Styles
 */

const StyledDiv = styled.div `

    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:start;
    margin:0 40px 0 0;

    & > label {
        margin:0 20px 0 0;
        font-size:14px;
        font-weight:500;
    }

    &:last-child {
        margin-right:0;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const FormBlock = (props) => {
    return <StyledDiv className={props.className}>{props.children}</StyledDiv>
}

export default FormBlock;