const moment = require('moment');

const getSport = (competition) => {
    return "Volley-Ball";
}

const getTotalTeams = (competition) => {

    if(!competition) return 0;

    let nbTeams = 0;

    for(const l of competition.levels) {
        nbTeams += l.teams.length;
    }

    return nbTeams;

}

const getConfirmedTeams = (competition) => {

    if(!competition) return 0;

    let nbConfirmedTeams = 0;

    for(const l of competition.levels) {
        for(const t of l.teams) {
            if(t.confirmed) nbConfirmedTeams++;
        }
    }

    return nbConfirmedTeams;

}

const getFormattedJauge = (competition) => {
    if(!competition) return "Non chargée";
    if(!competition.max) return "Non";
    return getTotalTeams(competition) + "/" + competition.max;
}

const getFormattedOnlineSubscriptionStatus = (competition) => {
    if(!competition) return {
        className: "Non chargée",
        text: "Non chargée"
    };
    if(competition.onlineSub) return {
        className: 'success',
        text: "Activée"
    }
    return {
        className: "error",
        text: "Désactivée"
    }
}

const getFormattedPublicationStatus = (competition) => {
    if(!competition) return "Non chargée";
    if(competition.published) return "Publié";
    return "Privé";
}

const getFormattedDate = (competition) => {
    if(!competition) return "Non chargée";
    const date = moment(competition.dte);
    return date.format('DD/MM/YYYY HH:mm');
}

const getFormattedLimitOnlineSubDate = (competition) => {
    if(!competition) return "Non chargée";
    if(!competition.limitDteOnline) return "Pas de date limite";
    const date = moment(competition.limitDteOnline);
    return date.format('DD/MM/YYYY');
}

const getFormattedStatus = (competition) => {
    if(competition.tours && competition.tours.length !== 0) {
        return {
            className: 'success',
            text: 'Démarré'
        }
    }
    return {
        className: 'medium',
        text: 'Non démarré'
    }
}

const competitionModelConverter = {
    getFormattedDate,
    getSport,
    getTotalTeams,
    getConfirmedTeams,
    getFormattedJauge,
    getFormattedOnlineSubscriptionStatus,
    getFormattedPublicationStatus,
    getFormattedLimitOnlineSubDate,
    getFormattedStatus
}

export default competitionModelConverter;