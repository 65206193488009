import { Link } from "react-router-dom"
import styled from "styled-components"

/**
 * Styles
 */

const SidebarLinkWrapper = styled(Link) `

    transition:all .3s ease;
    width:100%;
    display:flex;
    flex-direction:column;
    padding:10px 0;
    margin:0;
    text-decoration:none;

    &:hover {
        transition:all .3s ease;
        background:#2c3e50;
    }

    &.active {
        background:white;
    }

    & > div:first-child {
        width:100%;
        text-align:center;
        font-size:34px;
        color:white;
        margin:0;
    }

    &.active > div:first-child {
        color:#34495e;
    }

    & > div:nth-child(2) {
        width:100%;
        font-size:16px;
        text-align:center;
        margin:5px 0 0 0;
        color:white;
    }

    &.active > div:nth-child(2) {
        color:#34495e;
    }

    & > div:nth-child(2) > h5 {
        margin:0;
        padding:0 10px;
        font-weight:600;
    }

    &.red {
        background:#e74c3c;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const SidebarLink = (props) => {
    
    return (
        <SidebarLinkWrapper to={props.to} className={props.className}>
            <div><ion-icon name={props.icon}></ion-icon></div>
            <div><h5>{props.title}</h5></div>
        </SidebarLinkWrapper>
    );

}

export default SidebarLink;