import { CookiesProvider } from "react-cookie";
import Area from "../components/Authentication/Area/Area";

/**
 * Component
 * @returns 
 */
const Authentication = (props) => {
   
    return (
        <CookiesProvider>
            <Area page={props.page} />
        </CookiesProvider>
    );
}

export default Authentication;