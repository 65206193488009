import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

/**
 * Styles
 */

const LinksWrapper = styled.div `

    display:flex;
    justify-content:space-between;
    align-items:center;
    margin:0 40px 0 0;

`

const StyledLink = styled(Link) `

    transition:all .3s ease;
    text-decoration:none;
    color:black;
    font-weight:600;
    margin:0 12px;
    color:#34495e;
    font-size:14px;

    &:hover {
        transition:all .3s ease;
        color:black;
    }

`

const Links = () => {

    const [cookies, setCookies] = useCookies(['competition', 'proAuthorization']);
    const navigate = useNavigate();

    const handleDeleteCookie = (e) => {
        e.preventDefault();
        setCookies("competition", null, {path: '/'});
        navigate('/');
        return;
    }

    return (

    <LinksWrapper>
        {

        cookies.competition ? 

        <>
            <StyledLink to="/" onClick={handleDeleteCookie} >Fermer la compétition</StyledLink>
            <StyledLink to={"/competition/" + cookies.competition }>Modifier la compétition</StyledLink>
        </>

        :

        <StyledLink to="/">Ouvrir une compétition</StyledLink>

        }
    </LinksWrapper>

    );
    
}

export default Links;