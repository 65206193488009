import styled from "styled-components";
import TitlePage from "../../components/Software/TitlePage/TitlePage";
import TextInput from "../../components/Software/Inputs/TextInput";
import { useEffect, useState } from "react";
import CompetitionList from "../../components/Software/CompetitionList/CompetitionList";
import CompetitionInfo from "../../components/Software/CompetitionList/CompetitionInfo/CompetitionInfo";
import AddBtn from "../../components/Software/Btns/AddBtn";
import { useNavigate } from "react-router-dom";
import fetchAPI from "../../utils/fetch";
import { useCookies } from "react-cookie";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import Popup from "../../components/Software/Popup/Popup";
import Loader from "../../components/Loader/Loader";

/**
 * Styles
 */

const Cols = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:85%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;

`

const Col1 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;

`

const Col2 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:17%;
    display:flex;
    justify-content:start;
    align-items:start;
    flex-direction:column;
    overflow-y:auto;

`

const HeaderTitleWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;
    display:flex;
    justify-content:space-between;
    align-items:center;

`

const SearchEngine = styled.div `

    width:500px;
    height:38px;
    
`

/**
 * Component
 * @returns 
 */
const Competitions = () => {

    // Get navigation
    const navigate = useNavigate();

    // Get cookies
    const [cookies, setCookies] = useCookies(["competition", "proAuthorization"]);

    // Competitions states
    const [competitions, setCompetitions] = useState([]);
    const [competitionsRestriction, setCompetitionsRestriction] = useState(null);
    const [competition, setCompetition] = useState(null);

    // Errors
    const [error, setError] = useState(null);

    // Fetch trigger
    const [fetchTrigger, setFetchTriger] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    /**
     * Test if token exists
     */
    useEffect(() => {
        if(!cookies.proAuthorization) {
            navigate('/login');
            return;
        }
    }, [cookies.proAuthorization, navigate]);

    /**
     * Check if cookie is provided
     */
    useEffect(() => {
        if(cookies.competition) {
            navigate('/dashboard');
            return;
        }
    }, [cookies.competition, navigate]);

    /**
     * Get all competitions
     */
    useEffect(() => {

        setIsLoading(true);

        (async () => {

            const request = await fetchAPI("GET", "/competition/all", null);

            if(request.status !== 200) {
                if(request.json.message === "ERR_PLEASE_RECONNECT") {
                    navigate('/logout');
                    return;
                }
                setError("Une erreur est survenue.");
            } else {
                setCompetitions(request.json.data.competitions);
            }

            setIsLoading(false);

        })();

        return () => {};

    }, [navigate, fetchTrigger]);

    /**
     * Autoselect a competition
     */
    useEffect(() => {
        setCompetition(competitions[0] ? competitions[0] : null);
        return () => {};
    }, [competitions]);

    // Handlers

    /**
     * Handler for search
     * @param {*} e 
     */
    const handleSearch = (e) => {

        const search = e.target.value;

        if(search !== null && search.length !== 0) {

            setCompetitionsRestriction(competitions.filter((c) => {
                return c.name.toLowerCase().includes(search.toLowerCase());
            }));

        } else {
            setCompetitionsRestriction(null);
        }

    }

    /**
     * Btn add competition
     * @param {*} e 
     */
    const redirectToAddCompetition = (e) => {
        e.preventDefault();
        navigate("/add-competition");
    }

    /**
     * Change selected competition handler
     * @param {*} id 
     */
    const changeSelectedCompetition = (id) => {
        competitions.forEach((c) => {
            if(c.competitionId === id) setCompetition(c);
        });
    }

    /**
     * Delete handler
     * @param {*} event 
     * @param {*} id 
     * @returns 
     */
    const deleteCompetition = async (event, id) => {

        event.stopPropagation();

        const cf = window.confirm("Souhaitez-vous vraiment supprimer cette compétition ?");

        if(cf) {

            setIsLoading(true);

            try {

                const deleteRequest = await fetchAPI('DELETE', '/competition', {
                    competitionId: id
                });
    
                if(deleteRequest.status !== 200) {
                    if(deleteRequest.json.message === "ERR_LINKED_TOURS") throw new Error("Des tours sont liés à cette compétition.");
                    if(deleteRequest.json.message === "ERR_LINKED_FIELDS") throw new Error("Des terrains sont liés à cette compétition.");
                    if(deleteRequest.json.message === "ERR_LINKED_LEVELS") throw new Error("Des niveaux sont liés à cette compétition.");
                    else if(deleteRequest.status === 403 && deleteRequest.json.message === "ERR_PLEASE_RECONNECT") navigate('/logout')
                    else throw new Error("Une erreur est survenue.");
                    setIsLoading(false);
                    return;
                }
    
                setFetchTriger(fetchTrigger + 1);

            }
            catch(e) {
                setIsLoading(false);
                setError(e.message.includes('fetch') ? "Une erreur est survenue" : e.message);
            }

        }

    }

    return (

        competitions ?

        <Wrapper>

            <Popup content={[error]} />
            <Loader isLoading={isLoading} />

            <HeaderTitleWrapper>
                <TitlePage title="Ouvrir une compétition" />
                <SearchEngine>
                    <TextInput placeholder="Rechercher des compétitions ..." onChange={handleSearch} />
                </SearchEngine>
            </HeaderTitleWrapper>

            <Cols>

                <Col1>
                    <CompetitionList competitions={competitionsRestriction !== null ? competitionsRestriction : competitions } onHoverElement={changeSelectedCompetition} deleteCompetition={deleteCompetition} setCookies={setCookies} />
                </Col1>

                <Col2>
                    <AddBtn message1="Nouvelle" message2="compétition" callback={redirectToAddCompetition} />
                    <CompetitionInfo competition={competition} />
                </Col2>

            </Cols>
           
        </Wrapper>

        : null

    );

}

export default Competitions;