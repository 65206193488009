import styled from "styled-components";
import CompetitionBlock from "./CompetitionBlock/CompetitionBlock";
import EmptyList from "../EmptyList/EmptyList";
import { useEffect, useState } from "react";

/**
 * Styles
 */

const CompetitionListWrapper = styled.div `

    position:relative;
    display:flex;
    flex-direction:column;
    top:0;
    left:0;
    width:100%;
    border-collapse: collapse;
    overflow-y:auto;
    height:100%;
    border-spacing:0 10px;

    & > thead > tr > td {
        text-transform:uppercase;
        font-style:italic;
        font-size:12px;
        font-weight:700;
        color:grey;
    }

    & > div.dateindicator {
        font-size:16px;
        font-weight:bold;
        margin:10px 0;
        padding:0 0 0 20px;
        color:grey;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const CompetitionList = (props) => {

    // Handlers
    const onHover = (id) => props.onHoverElement(id);

    // Filters
    const [today, setToday] = useState([]);
    const [future, setFuture] = useState([]);

    useEffect(() => {

        setToday(props.competitions.filter((c) => {
            const _today = new Date();
            _today.setHours(0,0,0,0);
            const _competitionDate = new Date(c.dte);
            _competitionDate.setHours(0,0,0,0);
            return _today.getTime() === _competitionDate.getTime();
        }));

        setFuture(props.competitions.filter((c) => {
            const _today = new Date();
            _today.setHours(23,59,59,0);
            const _competitionDate = new Date(c.dte);
            return _today.getTime() < _competitionDate.getTime();
        }));

    }, [props.competitions])

    return (

        props.competitions && props.setCookies &&

        <>
            {props.competitions.length === 0 ? <EmptyList link={{path: '/add-competition', text: "Ajoutez votre première compétition"}} /> : null}
            <CompetitionListWrapper>
                <div className="dateindicator">Aujourd'hui</div>
                { today.map((competition) => (
                    <CompetitionBlock key={competition.id} competition={competition} onHover={() => onHover(competition.competitionId)} deleteCompetition={props.deleteCompetition} setCookies={props.setCookies} />
                )) }
                <div className="dateindicator">A venir</div>
                { future.map((competition) => (
                    <CompetitionBlock key={competition.id} competition={competition} onHover={() => onHover(competition.competitionId)} deleteCompetition={props.deleteCompetition} setCookies={props.setCookies} />
                )) }
                <div className="dateindicator">Toutes les compétitions</div>
                { props.competitions.map((competition) => (
                    <CompetitionBlock key={competition.id} competition={competition} onHover={() => onHover(competition.competitionId)} deleteCompetition={props.deleteCompetition} setCookies={props.setCookies} />
                )) }
            </CompetitionListWrapper>
        </>
        
    );
    
}

export default CompetitionList;