import styled from "styled-components"

/**
 * Styles
 */

const IndicatorWrapper = styled.div `

    position:relative;
    width:24%;
    height:180px;
    
    border-radius:8px;
    -webkit-box-shadow: 0px 0px 15px -7px rgba(0,0,0,0.6); 
    box-shadow: 0px 0px 15px -7px rgba(0,0,0,0.6);
    border:1px solid #34495e;

    & > div:first-child {
        position:absolute;
        top:0;
        left:0;
    }

    & > div:first-child > h5 {
        color:#f1c40f;
        font-size:100px;
        margin:10px 20px;
    }

    & > div:nth-child(2) {
        position:absolute;
        bottom:0;
        right:0;
    }

    & > div:nth-child(2) > h6 {
        color:#2c3e50;
        font-size:16px;
        margin:10px 20px;
    }

`

/**
 * Components
 * @param {*} props 
 * @returns 
 */
const Indicator = (props) => {

    return (
        <IndicatorWrapper>
            <div><h5>{ props.main }</h5></div>
            <div><h6>{ props.desc }</h6></div>
        </IndicatorWrapper>
    );
    
}

export default Indicator;