const isDateValid = (dateString) => {
    if(dateString === "") return true;
    const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/;
    return dateString.match(datePattern) !== null;
}

export const parseDate = (dateStr) => {
    const dateParts = dateStr.split('/');
    if (dateParts.length !== 3) return null;

    const [day, month, year] = dateParts.map(Number);
    if (isNaN(day) || isNaN(month) || isNaN(year)) return null;

    return new Date(year, month - 1, day, 0, 0);
};

const playerValidator = (firstname, name, email, phone, birthDate) => {

    if(typeof name !== "string") throw new Error("Le nom est invalide.");
    if(typeof firstname !== "string") throw new Error("Le prénom est invalide.");
    if(typeof email !== "string") throw new Error("Le mail est invalide.");
    if(typeof phone !== "string") throw new Error("Le numéro de téléphone est invalide.");
    if(name.length === 0) throw new Error("Le nom ne peut pas être vide.");
    if(name.length > 50) throw new Error("Le nom est trop long.");
    if(firstname.length === 0) throw new Error("Le prénom ne peut pas être vide.");
    if(firstname.length > 50) throw new Error("Le prénom est trop long.");
    if(email.length === 0) throw new Error("L'email ne peut pas être vide.");
    if(email.length > 50) throw new Error("L'email est trop long.");
    if(phone.length === 0) throw new Error("Le numéro de téléphone ne peut pas être vide.");
    if(phone.length > 50) throw new Error("Le numéro de téléphone est trop long.");
    if(birthDate.length === 0) throw new Error("La date de naissance doit être précisée.");
    if(!isDateValid(birthDate)) throw new Error("La date de naissance est invalide");
    if(parseDate(birthDate) >= (new Date())) throw new Error("La date de naissance doit être passée.");
    return;

}

export default playerValidator;