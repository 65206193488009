const isDateValid = (dateString) => {
    if(dateString === "") return true;
    const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/;
    return dateString.match(datePattern) !== null;
}

const isTimeValid = (timeString) => {
    if(timeString === "") return true;
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timeString.match(timePattern) !== null;
}

const isInteger = (string) => {
    var integerPattern = /^-?\d+$/;
    return integerPattern.test(string);
}

const parseDateAndTime = (dateStr, timeStr) => {
    const dateParts = dateStr.split('/');
    if (dateParts.length !== 3) return null;

    const [day, month, year] = dateParts.map(Number);
    if (isNaN(day) || isNaN(month) || isNaN(year)) return null;

    const timeParts = timeStr.split(':');
    if (timeParts.length !== 2) return null;

    const [hours, minutes] = timeParts.map(Number);
    if (isNaN(hours) || isNaN(minutes)) return null;

    return new Date(year, month - 1, day, hours, minutes);
};

/**
 * Validator
 * @param {*} name 
 * @param {*} place 
 * @param {*} date 
 * @param {*} time 
 * @param {*} minimal 
 * @param {*} maximal 
 * @param {*} pbl 
 * @param {*} prv 
 * @param {*} message 
 * @param {*} code 
 * @param {*} online_sub 
 * @param {*} online_sub_close 
 * @param {*} online_sub_close_date 
 * @param {*} online_payment 
 * @param {*} payment_price 
 * @param {*} description 
 * @returns 
 */
const competitionValidator = (
    name,
    place,
    date,
    time,
    minimal,
    maximal,
    individual,
    pbl,
    prv,
    message, 
    code,
    online_sub,
    online_sub_close,
    online_sub_close_date,
    online_payment,
    payment_price,
    description,
    scoremode,
    sport,
    stepconfig,
    max) => {
    
    // Name
    if(typeof name !== 'string') throw new Error("Le nom de la compétition est invalide.");
    if(name.length === 0) throw new Error("Le nom de la compétition ne peut pas être vide.");
    if(name.length > 30) throw new Error("Le nom de la compétition est trop long.");

    // Place
    if(typeof place !== 'string') throw new Error("Le lieu de la compétition est invalide.");
    if(place.length === 0) throw new Error("Le lieu de la compétition ne peut pas être vide.");
    if(place.length > 100) throw new Error("Le lieu de la compétition est trop long.");
    
    // Date and time
    if(typeof date !== 'string') throw new Error("La date de la compétition est invalide.");
    if(date.length === 0) throw new Error("La date de la compétition ne peut pas être vide.");
    if(!isDateValid(date)) throw new Error("La date de la compétition est invalide.");
    if(typeof time !== 'string') throw new Error("L'heure de la compétition est invalide.");
    if(time.length === 0) throw new Error("L'heure de la compétition ne peut pas être vide.");
    if(!isTimeValid(time)) throw new Error("L'heure de la compétition est invalide.");
    
    // Teams compo
    if(typeof minimal !== 'string') throw new Error("Le nombre de joueurs minimal par équipe de la compétition est invalide.");
    if(!isInteger(minimal) && minimal) throw new Error("Le nombre de joueurs minimal par équipe de la compétition est invalide.");
    if(minimal && (minimal < 0 || minimal > 50)) throw new Error("Le nombre de joueurs minimal par équipe de la compétition est invalide.");
    if(typeof maximal !== 'string') throw new Error("Le nombre de joueurs maximal par équipe de la compétition est invalide.");
    if(!isInteger(maximal) && maximal) throw new Error("Le nombre de joueurs maximal par équipe de la compétition est invalide.");
    if(maximal && (maximal < 0 || maximal > 50)) throw new Error("Le nombre de joueurs maximal de la compétition est invalide.");
    if(minimal && maximal && parseInt(maximal) < parseInt(minimal)) throw new Error("le nombre de joueurs maximal par équipe de la compétition doit être supérieur au nombre de joueurs minimal.");
    if(!individual && !maximal && !minimal) throw new Error("Vous devez préciser la composition des équipes.");
    if(max && max <= 0) throw new Error("La jauge doit être positive ou non définie.");
    
    // Published and private
    if(typeof pbl !== 'boolean') throw new Error("Le paramètre de publication de la compétition est invalide.");
    if(typeof prv !== 'boolean') throw new Error("Le paramètre de confidentialité de la compétition est invalide.");
    
    // Message
    if(message && typeof message !== 'string') throw new Error("Le message de la compétition est invalide.");
    if(message && message.length > 30) throw new Error("Le message de la compétition est trop long.");
    
    // Code
    if(code && typeof code !== 'string') throw new Error("Le code d'inscription de la compétition est invalide.");
    if(code && code.length > 10) throw new Error("Le code d'inscription de la compétition est trop long.");
    
    // Switchs for online sub and payment
    if(typeof online_sub !== 'boolean') throw new Error("Le paramètre d'inscription en ligne de la compétition est invalide.");
    if(typeof online_payment !== 'boolean') throw new Error("Le paramètre de paiement en ligne de la compétition est invalide.");
    
    // Closing date for sub
    if(typeof online_sub_close !== 'boolean') throw new Error("Le paramètre de fermeture des inscriptions en ligne de la compétition est invalide.");
    if(typeof online_sub_close_date !== 'string') throw new Error("La date de fermeture des inscriptions en ligne de la compétition est invalide.");
    if(!isDateValid(online_sub_close_date)) throw new Error("La date de fermeture des inscriptions en ligne de la compétition est invalide.");
    if(online_sub_close_date.length === 0 && online_sub_close) throw new Error("La date de fermeture des inscriptions en ligne de la compétition ne peut pas être vide");
    if(online_sub_close && !online_sub_close_date) throw new Error("Vous devez préciser une date de fermeture des inscriptions en ligne.");

    // Price
    if(typeof payment_price !== 'string') throw new Error("Le prix de la compétition est invalide.");
    if(!isInteger(payment_price) && payment_price.length !== 0) throw new Error("Le prix d'inscription de la compétition est invalide.");
    if(parseInt(payment_price) < 0) throw new Error("le prix d'inscription de la compétition est invalide.");
    if(online_payment && payment_price.length === 0) throw new Error("Le prix de la compétition ne peut pas être vide.");
    
    // Description
    if(description && typeof description !== 'string') throw new Error("La description de la compétition est invalide.");
    if(description && description.length > 100) throw new Error("La description de la compétition est trop longue.");

    // Select check defined
    if(stepconfig === 0 || stepconfig === '0') throw new Error("Une configuration de points doit être précisée.");
    if(scoremode === 0 || scoremode === '0') throw new Error("Une configuration de score doit être précisée.");
    if(sport === 0 || sport === '0') throw new Error("Un sport doit être précisé.");

    return;

}

export default competitionValidator;