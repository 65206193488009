const getFormattedAvailableStatus = (field) => {

    if(field.available) {
        return {
            className: 'success',
            text: 'Disponible'
        }
    }

    return {
        className: 'error',
        text: 'Occupé'
    }

}

const fieldModelConverter = {
    getFormattedAvailableStatus,
}

export default fieldModelConverter;