import styled from "styled-components";
import TourBlock from "./TourBlock/TourBlock";

const StyledDiv = styled.div `

    position:relative;
    top:0:
    left:0;
    margin:20px 0;
    width:90%;
    overflow:auto;
    display:flex;
    flex-wrap:no-wrap;

`

const TourList = (props) => {

    return (

        <StyledDiv>
            { props.tours.map((t) => <TourBlock tourId={t.tourId} tourName={t.name} selectedDerivationTourId={props.selectedDerivationTourId} handleChangeSelectedDerivationTourId={props.handleChangeSelectedDerivationTourId} />) }
        </StyledDiv>

    )

}

export default TourList;