import styled from "styled-components";

const StyledDiv = styled.div `

    position:relative;
    display:flex;
    flex-direction:row;
    height:60px;
    align-items:center;
    justify-content:space-between;
    background:white;
    cursor:pointer;
    flex-shrink:0;

    & > div:first-child {
        display:flex;
        flex-direction:row;
        align-items:center;
    }

    & > div:nth-child(2) {
        padding:0 20px 0 0;
        text-transform:uppercase;
        font-size:13px;
        font-weight:700;
        color:#f1c40f;
    }
    
    & > div > div:first-child {
        font-size:30px;
        transform:translateY(3px);
        padding:0 20px;
    }

    & > div > div:nth-child(2) {
        font-size:20px;
        font-weight:bold;
    }

    &.odd {
        background:#ecf0f1;
    }

    &:hover {
        background:#2c3e50;
        color:white;
    }


`

const SearchResultElement = (props) => {

    let icon = "search-cirle-outline";
    let typetext = "résultat";

    if(props.elementType === "team") {
        icon = "people-outline";
        typetext = "équipe";
    }
    if(props.elementType === "manager") {
        icon = "git-merge-outline";
        typetext = "tour de jeu";
    }
    if(props.elementType === "level") {
        icon = "analytics";
        typetext = "niveau de jeu"
    }
    if(props.elementType === "field") {
        icon = "tablet-landscape";
        typetext = "terrain"
    }


    return (

        <StyledDiv className={props.odd && "odd"} onClick={() => props.handleNavigate(props.elementType, props.elementId)}>
            <div>
                <div>
                    <ion-icon name={icon}></ion-icon>
                </div>
                <div>{ props.elementName }</div>
            </div>
            <div>{ typetext }</div>
        </StyledDiv>

    )

}

export default SearchResultElement;