import styled from "styled-components"
import GameBlock from "./GameBlock/GameBlock";
import Loader from "../../Loader/Loader";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:calc(100% - 60px);
    margin:30px;
    overflow:auto;
    height:45%;

    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }

`

const GameList = (props) => {

    return (

        props.scoresGames && props.scoresGames.length !== 0 &&

        <StyledDiv>
            { props.games.map((g) => <GameBlock game={g} scores={props.scoresGames[g.gameId] } key={g.gameId} onUpdateGame={props.onUpdateGame} steps={props.steps} isFinal={props.isFinal} generatePdfFinal={props.generatePdfFinal} onValidateGameUpdates={props.onValidateGameUpdates} />) }
        </StyledDiv>

    )

}

export default GameList;