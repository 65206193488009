import styled from "styled-components";
import TitlePage from "../TitlePage/TitlePage";

/**
 * Styles
 */

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:80%;
    display:flex;
    justify-content:space-between;
    align-items:center;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TitleWrapper = (props) => {

    return (
        <StyledDiv>
            <TitlePage title={props.title} />
        </StyledDiv>
    );
    
}

export default TitleWrapper;