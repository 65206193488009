import styled from "styled-components";
import Links from "./Links/Links";
import Profile from "./Profile/Profile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import fetchAPI from "../../../../../utils/fetch";

/**
 * Styles
 */

const MenuWrapper = styled.div `

    display:flex;
    justify-content:space-between;
    align-items:center;

`

/**
 * Component
 * @returns 
 */
const Menu = () => {

    // Get navigation
    const navigate = useNavigate();

    // Get cookies
    const [cookies] = useCookies(["proAuthorization"]);

    // Pro
    const [pro, setPro] = useState(null);

    useEffect(() => {

        if(!cookies.proAuthorization) {
            navigate('/');
            return;
        }

        (async () => {

            try {

                const request = await fetchAPI('GET', '/pro', {});

                if(request.status !== 200) {

                    if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/');
                        return;
                    }

                } else {
                    setPro(request.json.data.pro);
                }

            }
            catch(e) {
                console.log(e);
            }

        })();

    }, [cookies.proAuthorization, navigate]);

    return (
        <MenuWrapper>
            <Links />
            <Profile pro={pro} />
        </MenuWrapper>
    )

}

export default Menu;