import styled from "styled-components";
import LevelBlock from "./LevelBlock/LevelBlock";
import EmptyList from "../EmptyList/EmptyList";

/**
 * Styles
 */

const LevelListWrapper = styled.div `

    position:relative;
    display:flex;
    flex-direction:column;
    top:0;
    left:0;
    width:100%;
    border-collapse: collapse;
    overflow-y:auto;
    height:100%;
    border-spacing:0 10px;

    & > thead > tr > td {
        text-transform:uppercase;
        font-style:italic;
        font-size:12px;
        font-weight:700;
        color:grey;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const LevelList = (props) => {

    return (

        props.levels && props.openLevel && props.deleteLevel ?

        <>
            {props.levels.length === 0 ? <EmptyList link={{path: '/add-level', text: "Ajoutez votre premier niveau"}} /> : null}
            <LevelListWrapper>
                { props.levels.map((level) => (
                    <LevelBlock key={level.levelId} level={level} openLevel={props.openLevel} deleteLevel={props.deleteLevel} />
                )) }
            </LevelListWrapper>
        </>

        : null

    );
    
}

export default LevelList;