import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import fetchAPI from "../../../../utils/fetch";
import { useCookies } from "react-cookie";

const zoomInOut = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`;

const FormBoxWrapper = styled.div `

    width:40%;
    display:flex;
    position:relative;
    flex-direction:column;
    margin:60px 0 0 60px;

    & > div.loader {
        position:absolute;
        top:-60px;
        left:-60px;
        right:0;
        bottom:0;
        background:blue;
        background:rgba(1,1,1,0.2);
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
    }

    & > div.loader > img {
        width:40px;
        animation: ${zoomInOut} 2s infinite;
    }

    & > div.loader > p {
        font-weight:bold;
        text-transform:uppercase;
        font-size:13px;
        margin:20px 0;
    }

    & > div.logo > img {
        width:70px;
    }

    & > div.titles > h3 {
        font-size:30px;
        font-weight:800;
        margin:28px 0 0 0;
    }

    & > div.titles > h4 {
        font-size:14px;
        font-weight:normal;
        margin:8px 0 0 0;
        color:grey;
    }

    & > div.form {
        margin:30px 0 0 0;
        width:calc(100% - 160px);
    }

    & > div.form > div.group {
        display:flex;
        flex-direction:column;
        margin:0 0 10px 0;
    }

    & > div.form > div.group > label {
        font-size:13px;
        font-weight:500;
    }

    & > div.form > div.group > label > span {
        color:#e74c3c;
        font-size:14px;
        padding:0 0 0 3px;
    }

    & > div.form > div.group > input {
        width:100%;
        height:40px;
        border-radius:4px;
        border:1px solid #bdc3c7;
        outline:0;
        margin:2px 0 0 0;
        font-size:13px;
        padding:0 0 0 5px;
        transition:all .3s ease;
    }

    & > div.form > div.group > input.error {
        border:1px solid #e74c3c;
    }

    & > div.form > div.group > input:hover {
        transition:all .3s ease;
        border:1px solid #34495e;
    }

    & > div.form > div.bottomBar {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        margin:0 0 10px 0;
    }

    & > div.form > div.bottomBar > div {
        font-size:12px;
        display:flex;
    }

    & > div.form > div.btn {
        width:100%;
        height:50px;
        background:#34495e;
        display:flex;
        align-items:center;
        justify-content:center;
        color:white;
        font-size:14px;
        font-weight:600;
        border-radius:4px;  
        margin:30px 0 0 0;
        transition:all .3s ease;
        cursor:pointer;
    }

    & > div.form > div.btn:hover {
        background:#2c3e50;
        transition:all .3s ease;
    }

    & > div.form > div.error p {
        color:#e74c3c;
        font-size:13px;
        font-weight:600;
    }

`

const CustomLink = styled(Link) `

    text-decoration:none;
    color:#f1c40f;
    transition:all .3s ease;

    &:hover {
        color:#34495e;
        transition:all .3s ease;
    }

`

const FormBox = (props) => {

    // Login infos
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    // Errors
    const [error, setError] = useState(null);

    // Loaders status
    const [isLoading, setIsLoading] = useState(false);

    // Get cookies
    const [cookies, setCookies] = useCookies(['proAuthorization']);

    // Get navigation
    const navigate = useNavigate();

    /**
     * Redirect to dashboard if already connected
     */
    useEffect(() => {
        if(cookies.proAuthorization) {
            navigate('/');
            return;
        }
    }, [cookies.proAuthorization, navigate]);
    
    // Handlers

    const handleChangeEmail = (e) => {setEmail(e.target.value); setError(null)}
    const handleChangePassword = (e) => {setPassword(e.target.value); setError(null)}

    const listenEnter = (e) => {
        if(e.key === 'Enter') handleLogin();
    }

    /**
     * Handler for login submission
     * @returns 
     */
    const handleLogin = async () => {

        if(email.length === 0 || password.length === 0) {
            setError("Veuillez remplir tous les champs");
            return;
        }

        try {

            setIsLoading(true);

            const request = await fetchAPI("POST", "/authenticate/login/pro", {
                email: email,
                password: password
            }, true);

            if(request.status === 200) {
                setCookies("proAuthorization", request.json.data.token);
                setIsLoading(false);
                navigate('/');
                return;
            }

            if(request.status === 403) {
                setError("Identifiant ou mot de passe incorrect.");
            }

            setIsLoading(false);

        }
        catch(e) {
            setIsLoading(false);
            setError(e.message)
        }

    }

    /**
     * Handler for new mdp request
     */
    const handleMdp = async () => {

        if(email.length === 0) {
            setError("Vous devez entrer l'email de votre organisation");
            return;
        }

        try {

            // TODO

        }
        catch(e) {
            setError("Une erreur est survenue.");
        }

    }   

    return (

        <FormBoxWrapper>
            {
                isLoading ?
                <div className="loader">
                    <img src="logo.png" alt="Logo Neoscore" />
                    <p>Chargement ...</p>
                </div>
                :
                null
            }
            <div className="logo">
                <img src="logo.png" alt="Logo Neoscore" />
            </div>
            <div className="titles">
                {
                    props.page === "login" ?
                    <>
                        <h3>Heureux de vous revoir !</h3>
                        <h4>Entrez vos identifiants pour vous connecter</h4>
                    </>
                    :
                    <>
                        <h3>Retrouvez vos accès</h3>
                        <h4>Entrez votre email afin de recevoir un nouveau mot de passe</h4>
                    </>
                }
            </div>
            {
                props.page === "login" ?
                <div className="form">
                    <div className="group">
                        <label>Email<span>*</span></label>
                        <input onKeyDown={listenEnter} type="email" placeholder="Votre email" value={email} onChange={handleChangeEmail} className={error ? "error" : null} />
                    </div>
                    <div className="group">
                        <label>Mot de passe<span>*</span></label>
                        <input onKeyDown={listenEnter} type="password" placeholder="Votre mot de passe" value={password} onChange={handleChangePassword} className={error ? "error" : null} />
                    </div>
                    <div className="bottomBar">
                        <div><CustomLink to="/forgot">Mot de passe oublié ?</CustomLink></div>
                    </div>
                    <div class="error">{error ? <p>{error}</p> : null}</div>
                    <div className="btn" onClick={handleLogin}>Se connecter</div>
                </div>
                :
                <div className="form">
                    <div className="group">
                        <label>Email<span>*</span></label>
                        <input type="email" placeholder="Votre email" value={email} onChange={handleChangeEmail} className={error ? "error" : null} />
                    </div>
        
                    <div className="bottomBar">
                        <div><CustomLink to="/login">Je connais mon mot de passe</CustomLink></div>
                    </div>
                    <div class="error">{error ? <p>{error}</p> : null}</div>
                    <div className="btn" onClick={handleMdp}>Recevoir un mail</div>
                </div>
            }
            
        </FormBoxWrapper>

    )

}

export default FormBox;