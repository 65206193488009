import styled from "styled-components";
import TextInput from "../../Inputs/TextInput";
import { useEffect, useState } from "react";
import CheckboxInput from "../../Inputs/CheckboxInput";
import fetchAPI from "../../../../utils/fetch";
import { useNavigate } from "react-router-dom";
import playerConverter from "../../../../utils/modelConverters/player";
import API_HOST_URL from "../../../../utils/apiHost";

/**
 * Styles
 */

const StyledDiv = styled.div `

    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.5);
    z-index:999;

    & > div > div.close {
        position:absolute;
        top:0;
        right:0;
        padding:8px;
        font-size:28px;
        color:#bdc3c7;
        cursor:pointer;
        transition:all .3s ease;
    }

    & > div > div.close:hover {
        color:#e74c3c;
        transition:all .3s ease;
    }

    & > div {
        position:fixed;
        top:10%;
        left:calc(50% - 400px);
        width:800px;
        background:white;
        border-radius:8px;
        display:flex;
        flex-direction:column;
    }

    & > div > h3 {
        margin:30px;
        font-size:24px;
    }

    & > div > h3 > span.neoscore {
        color:#f1c40f;
    }

    & > div > h3 > span.minalert {
        color:#e74c3c;
        font-size:10px;
    }

    & > div > div.email {
        margin:0 30px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
        padding: 0 0 20px 0;
    }

    & > div > div.profile {
        margin:0 30px;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:space-between;
        border-bottom:1px solid #bdc3c7;
        padding: 0 0 20px 0;
    }

    & > div > div.payment {
        margin:0 30px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
        padding: 20px 0;
        border-bottom:1px solid #bdc3c7;
    }

    & > div > div.payment > div:first-child {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
    }

    & > div > div.payment > div:first-child > div:first-child {
        width:40px;
        height:40px;
        margin:0 10px 0 0;
    }

    & > div > div.payment > div:first-child > div:nth-child(2) {
        font-size:14px;
        font-weight:500;
    }

    & > div > div.payment > div:nth-child(2) {
        display:flex;
        align-items:center;
        justify-content:start;
        background:#2ecc71;
        color:white;
        border-radius:8px;
        transition:all .3s ease;
    }

    & > div > div.payment > div:nth-child(2) > ion-icon {
        margin:0 10px 0 6px;
        font-size:28px;
    }

    & > div > div.payment > div:nth-child(2) > p {
        margin:10px 10px 10px 0;
        font-weight:600;
    }

    & > div > div.payment > div:nth-child(2):hover {
        background:#27ae60;
        cursor:pointer;
        transition:all .3s ease;
    }

    & > div > div.profile > div {
        height:84px;
        width:100%;
        border-radius:8px;
        margin:6px 0;
        -webkit-box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.36); 
        box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.36);
        display:flex;
        justify-content:start;
        align-items:center;
        line-height:54px;
        font-size:14px;
        font-weight:500;
        cursor:pointer;
        transition:all .3s ease;
    }

    & > div > div.profile > p.noresult {
        color:red;
        font-weight:500;
        text-align:center;
        font-size:14px;
    }

    & > div > div.profile > div.selected {
        background:#34495e;
        color:white;
    }

    & > div > div.profile > div > div:nth-child(2) {
        margin:0 0 0 20px;
        font-size:16px;
    }

    & > div > div.email > label {
        font-size:18px;
        margin:0 20px 0 0;
    }

    & > div > div.email > div.input {
        width:340px;
        height:38px;
    }

    & > div > div.email > div.btn {
        background:#2ecc71;
        color:white;
        height:40px;
        display:flex;
        flex-direction:row;
        align-items:center;
        font-size:14px;
        border-radius:8px;
        padding:3px 8px;
        transition:all .3s ease;
    }

    & > div > div.email > label {
        font-size:16px;
        font-weight:500;
    }

    & > div > div.email > div.btn:hover {
        background:#27ae60;
        transition:all .3s ease;
        cursor:pointer;
    }

    & > div > div.email > div.btn > ion-icon {
        font-size:20px;
    }

    & > div > div.form {
        margin:0 30px;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:space-between;
        border-bottom:1px solid #bdc3c7;
        padding: 10px 0 10px 0;
    }

    & > div > div.form > div {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        width:100%;
    }

    & > div > div.form > div > div {
        display:flex;
        flex-direction:row;
        margin:5px 0;
        align-items:center;
    }

    & > div > div.form > div > div > label {
        font-weight:500;
        margin: 0 10px 0 0;
    }

    & > div > div.form > div > div > div {
        width:260px;
        height:38px;
    }

    .del {
        display:none!important;
    }

`

const PPArea = styled.div `

    width:60px;
    height:60px;
    border-radius:50%;
    background-image:url(${props => API_HOST_URL + "/player/getpp?playerId=" + props.playerId});
    margin:0 0 0 18px;
    background-size:cover;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const EditPlayerForm = (props) => {

    // Selection Neoscore account indicator
    const [selected, setSelected] = useState(false);
    const [result, setResult] = useState(false);

    // Get navigation
    const navigate = useNavigate();

    // Criteria
    const [searchCriteria, setSearchCriteria] = useState("");

    // New player to add
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [firstname, setFirstname] = useState("");
    const [phone, setPhone] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [payed, setPayed] = useState(false);

    // If player is selected then fill
    useEffect(() => {

        if(props.player && props.player.playerId === 0) {
            setEmail(props.player.email);
            setPhone(props.player.phone);
            setFirstname(props.player.firstname);
            setName(props.player.name);
            setPayed(props.player.payed);
            setBirthDate(playerConverter.getFormattedFormBirthDate(props.player.birthDate));
        }

        (async () => {

            if(props.player && props.player.playerId !== 0) {

                setResult({
                    playerId: props.player.playerId,
                    firstname: props.player.firstname,
                    name: props.player.name,
                    email : props.player.email,
                    phone: props.player.phone,
                    birthDate: props.player.birthDate
                });

                setSelected(true);
                setPayed(props.player.payed);

            }

        })();

    }, [props.player])

    // Handlers
    
    const toggleMode = () => {
        if(props.player && props.player.playerId !== 0) setSelected(true);
        else setSelected(selected ? false : true);
    }

    const onChangeCriteria = (e) => {
        if(!props.player || props.player.playerId === 0) setSearchCriteria(e.target.value);
    };

    const handleEnter = (event) => {
        if (event.key === 'Enter' && (!props.player || props.player.playerId === 0)) {
            onSubmitSearch();
        }
    };

    const onSubmitSearch = async () => {

        if(searchCriteria && (!props.player || props.player.playerId === 0)) {

            let treatedCriteria;

            if(!searchCriteria.includes('@')) {
                treatedCriteria = searchCriteria.replace(/[^\d+]/g, '');
                if(treatedCriteria.startsWith("0")) treatedCriteria = "+33" + treatedCriteria.substring(1)    

            } else {
                treatedCriteria = searchCriteria;
            }

            console.log(treatedCriteria);

            // Try to fetch player
            const request = await fetchAPI("GET", "/player/criteria", {
                criteria: treatedCriteria
            });

            if(request.status !== 200) {
                navigate("/");
                return;
            }

            setResult(request.json.data.player ? {
                playerId: request.json.data.player.playerId,
                firstname: request.json.data.player.firstname,
                name: request.json.data.player.name,
                email : request.json.data.player.email,
                phone: request.json.data.player.phone,
                birthDate: request.json.data.player.birthDate
            } : null);

            if(!request.json.data.player) {
                if(searchCriteria.includes('@')) setEmail(searchCriteria);
                else setPhone(searchCriteria);
            }

        }

    }

    const handleChangeFirstname = (e) => {setFirstname(e.target.value);};
    const handleChangeName = (e) => {setName(e.target.value); };
    const handleChangePhone = (e) => {setPhone(e.target.value);};

    const handleChangeBirthDate = (e) => {
        if(e.target.value.length === 2 || e.target.value.length === 5) setBirthDate(e.target.value + "/");
        else setBirthDate(e.target.value);
    };

    const handleChangeEmail = (e) => {setEmail(e.target.value);};
    const handleChangePayed = (b) => {setPayed(b);};

    const handleSubmitPlayer = () => {

        if(selected && result) {
            
            props.onAddPlayer({
                firstname: result.firstname,
                name: result.name,
                email: result.email,
                phone: result.phone,
                playerId: result.playerId,
                payed: payed,
                birthDate: result.birthDate
            });

        } else {

            props.onAddPlayer({
                firstname: firstname,
                name: name,
                email: email,
                phone: phone,
                payed: payed,
                birthDate: birthDate
            });

        }

    }

    return (
        <StyledDiv>

            <div>

                <div className="close" onClick={props.hideForm}><ion-icon name="close"></ion-icon></div>

                {
                props.player && props.player.playerId !== 0 ?
                <h3>Joueur lié à un compte <span className="neoscore">Neoscore</span> <span className="minalert">(lecture seule)</span></h3>
                :
                <h3>{ props.player ? "Modifier le joueur de l'équipe" : "Ajouter un joueur à l'équipe"}</h3>
                }
                
                <div className={ props.player && props.player.account ? "email del" : "email"}>
                    <label>Recherche</label>
                    <div className="input"><TextInput placeholder="Email ou numéro de téléphone" onChange={onChangeCriteria} onKeyDown={handleEnter} value={searchCriteria} autofocus autoCapitalize="off" /></div>
                    <div className="btn" onClick={onSubmitSearch}><ion-icon name="search"></ion-icon><p>Rechercher le compte</p></div>
                </div>
                
                <div className="profile">

                    {
                        result ?
                        <div className={selected || (props.player && props.player.playerId !== 0) ? "selected": ""} onClick={toggleMode}>
                            <PPArea playerId={result.playerId}></PPArea>
                            <div>{ result.firstname } { result.name } ({ result.phone }) ({ playerConverter.getFormattedYearsOld(result.birthDate) })</div>
                        </div>
                        : <p className="noresult">Aucun résultat</p>
                    }

                </div>
                
                <div className={ (props.player && props.player.account) || selected ? "form del" : "form"}>
                    <div>
                        <div>
                            <label>Prénom</label>
                            <div><TextInput placeholder="Prénom du joueur" value={firstname} onChange={handleChangeFirstname} /></div>
                        </div>
                        <div>
                            <label>Nom</label>
                            <div><TextInput placeholder="Nom du joueur" value={name} onChange={handleChangeName} /></div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label>Email</label>
                            <div><TextInput placeholder="Email du joueur" value={email} onChange={handleChangeEmail} isDisabled={props.player !== null} /></div>
                        </div>
                        <div>
                            <label>Téléphone</label>
                            <div><TextInput placeholder="Téléphone du joueur" value={phone} onChange={handleChangePhone} /></div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label>Date de naissance</label>
                            <div><TextInput placeholder="dd/MM/YYYY" value={birthDate} onChange={handleChangeBirthDate} /></div>
                        </div>
                    </div>
                </div>
               
                <div className="payment">
                    <div>
                        <div><CheckboxInput value={payed} onChange={handleChangePayed} /></div>
                        <div>Inscription réglée</div>
                    </div>
                    <div onClick={ handleSubmitPlayer }>
                        <ion-icon name="add-circle"></ion-icon>
                        <p>{props.player ? "Enregistrer les modifications" : "Ajouter le joueur à l'équipe" }</p>
                    </div>
                </div>

            </div>

        </StyledDiv>
    );

}

export default EditPlayerForm;