import styled from "styled-components";
import FormBlock from "../FormBlock/FormBlock";
import SelectInput from "../Inputs/SelectInput";
import TextInput from "../Inputs/TextInput";

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:calc(100% - 60px);
    margin:30px;

    & .marged {
        padding:0 0 0 30px;
    }

    & .margedblock {
        margin:10px 0 0 0;
    }

`

const TourTypeDiv = styled.div `
    width:auto;
    height:32px;
`

const TourNameDiv = styled.div `
    width:300px;
    height:32px;
`

const PoulesCompositionDiv = styled.div `
    width:auto;
    height:32px;
`

const NbTeamsPouleDiv = styled.div `
    width:32px;
    height:32px;
`

const FormTourConfigurator = (props) => {

    return (

        <StyledDiv>

            <FormBlock>
                <label>Nom du nouveau tour</label>
                <TourNameDiv>
                    <TextInput value={props.tourName} onChange={props.handleChangeTourName} />
                </TourNameDiv>
            </FormBlock>

            <br />

            <FormBlock>
                <label>Type de tour</label>
                <TourTypeDiv>
                    <SelectInput options={props.tourTypes} value={props.tourType} onChange={props.handleChangeTourType} />
                </TourTypeDiv>
                <label className="marged">Prioriser les poules de</label>
                <NbTeamsPouleDiv>
                    <TextInput value={props.tourNbTeams} onChange={props.handleChangeTourNbTeams} isDisabled={props.tourType === "2"} />
                </NbTeamsPouleDiv>
            </FormBlock>

            <br />

            <FormBlock>
                <label>Mode de composition</label>
                <PoulesCompositionDiv>
                    <SelectInput options={props.poulesCompositions} value={props.poulesComposition} onChange={props.handleChangePoulesComposition} isDisabled={props.tourType === "2"} />
                </PoulesCompositionDiv>
            </FormBlock>

        </StyledDiv>

    );
}

export default FormTourConfigurator;