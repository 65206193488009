import { CookiesProvider } from "react-cookie";
import Area from "../components/Software/Area/Area";
import Header from "../components/Software/Header/Header";

/**
 * Component
 * @returns 
 */
const Software = () => {
    return (
        <CookiesProvider>
            <Header />
            <Area />
        </CookiesProvider>
    );
}

export default Software;