import styled from "styled-components";

/**
 * Styles
 */

const StyledDiv = styled.div `

    display:flex;
    flex-direction:column;
    justify-content:start;
    width:100%;
    height:100%;
    position:relative;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const Wrapper = (props) => {

    return (
        <StyledDiv>
            {props.children}
        </StyledDiv>
    );

}

export default Wrapper;