import styled from "styled-components";
import CompetitionModel from "../../../../utils/modelConverters/competition"
import { useNavigate } from "react-router-dom";

/**
 * Styles
 */

const StyledTr = styled.div `

    height:74px;
    flex-shrink: 0;
    width:98%;
    border-radius:8px;
    margin:10px 1%;
    -webkit-box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.36); 
    box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.36);
    display:flex;
    justify-content:space-between;
    align-items:center;
    font-size:14px;
    font-weight:500;
    cursor:pointer;
    transition:all .3s ease;

    &:hover {
        background:#ecf0f1;
        transition:all .3s ease;
    }

    & > div {
        display:flex;
        flex-direction:column;
        justify-content:start;
        align-items:start;
        width:100%;
    }

    & > div:nth-child(4) {
        align-items:center;
        font-weight:bold;
    }

    & > div > span {
        font-size:14px;
        margin:0 20px;
        padding:0;
        line-height:22px;
    }

    & > div:first-child > span:first-child {
        font-size:18px;
        font-weight:700;
    }

    & > div:first-child > span:last-child {
        font-weight:600;
        color:grey;
    }

    & > div:nth-child(2) > span:first-child {
        font-weight:600;
        color:#f1c40f;
    }

    & > div:nth-child(2) > span:first-child > span {
        font-weight:700;
        color:black;
    }

    & > div:nth-child(2) > span:last-child {
        font-weight:600;
        color:#f1c40f;
    }

    & > div:nth-child(2) > span:last-child > span {
        font-weight:700;
        color:black;
    }

    & > div:nth-child(3) > span:first-child {
        font-weight:800;
        text-transform:uppercase;
    }

    & > div:nth-child(3) > span:first-child > span {
        font-weight:700;
        color:black;
        text-transform:none;
    }

    & > div:nth-child(3) > span:last-child {
        font-weight:600;
        color:#f1c40f;
    }

    & > div:nth-child(3) > span:last-child > span {
        font-weight:700;
        color:black;
    }

    & > div.btns {
        display:flex;
        height:74px;
        justify-content:center;
        align-items:end;
        padding-right:5px;
    }

    & > div.btns > div.btn {
        height:38px;
        width:38px;
        display:flex;
        justify-content:center;
        align-items:center;
        margin:0 5px;
        border-radius:8px;
        color:white;
    }

    & > div.btns > div.btn.validation {
        background:#2ecc71;
    }

    & > div.btns > div.btn.cancel:hover {
        cursor:pointer;
        background:#c0392b;
        transition:all .3s ease;
    }

    & > div.btns > div.btn.cancel {
        background:#e74c3c;
    }

    & > div.btns > div.btn.validation:hover {
        cursor:pointer;
        background:#27ae60;
        transition:all .3s ease;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const CompetitionBlock = (props) => {

    // Load navigation
    const navigate = useNavigate();

    /**
     * Redirect user to the dashboard and open the corresponding competition that matches to the provided id
     * @param {*} id 
     */
    const openDashboard = (id) => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        props.setCookies('competition', parseInt(id), { path: '/' });
        navigate("/dashboard");
        return;
    }

    return (

        props.competition ?

        <StyledTr key={props.competition.competitionId} onMouseOver={props.onHover} onClick={() => openDashboard(props.competition.competitionId)}>
            <div>
                <span>{props.competition.name}</span>
                <span>{ CompetitionModel.getFormattedDate(props.competition) }</span>
            </div>
            <div>
                <span><span>Sport: </span> { props.competition.sport.name }</span>
                <span><span>Equipes: </span> { CompetitionModel.getTotalTeams(props.competition) }</span>
            </div>
            <div>
                <span className={ CompetitionModel.getFormattedOnlineSubscriptionStatus(props.competition).className }><span>Inscription: </span> { CompetitionModel.getFormattedOnlineSubscriptionStatus(props.competition).text }</span>
                <span><span>Limite: </span> { CompetitionModel.getFormattedJauge(props.competition) }</span>
            </div>
            <div>
                <span className={ CompetitionModel.getFormattedStatus(props.competition).className }>{ CompetitionModel.getFormattedStatus(props.competition).text }</span>
            </div>
            <div className="btns">
                <div className="btn cancel" onClick={(event) => props.deleteCompetition(event, props.competition.competitionId)}><ion-icon name="trash"></ion-icon></div>
            </div>
        </StyledTr>

        :

        null

        
    );

}

export default CompetitionBlock;