import React from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

const StyledDiv = styled.div `

  &.dspn {
    display:none;
  }

`

const QRCodeComponent = (props) => {
  return (
    <StyledDiv ref={props.reference} className={props.display === "none" ? "dspn" : ""}>
      <QRCode
        id="qrcode"
        value={props.value}
        size={256}
        fgColor="#2c3e50"
        level={"H"}
        includeMargin={true}
        imageSettings={{
            src: "/logo.png",
            x: null,
            y: null,
            height: 50,
            width: 50,
            excavate: true
          }}
      />
    </StyledDiv>
  );
};

export default QRCodeComponent;