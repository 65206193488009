import { Link } from "react-router-dom";
import styled from "styled-components"

/**
 * Styles
 */

const StyledLink = styled(Link) `

    width:100%;
    text-decoration:none;

`

const BtnWrapper = styled.div `

    display:flex;
    flex-direction:row;
    width:100%;
    height:100%;
    background:#2ecc71;
    background: ${props => props.color};
    justify-content:start;
    align-items:center;
    transition:all .3s ease;
    border-radius:8px;

    & > ion-icon {
        font-size:48px;
        color:white;
        margin:0 20px;
    }

    & > p {
        margin:18px 0;
        color:white;
        font-weight:600;
    }

    &:hover {
        transform:scale(1.02);
        cursor:pointer;
        transition:all .3s ease;    
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const AddBtn = (props) => {

    // Determine the type of icon to display
    const icon = props.icon ? props.icon : "add-circle";
    const color = props.color ? props.color : "#2ecc71";

    return (
        <StyledLink onClick={props.callback} className={props.small && "small"}>
            <BtnWrapper color={color}>
                <ion-icon name={icon}></ion-icon>
                <p>{props.message1}{ props.message2 ? <><br />{props.message2}</> : ""}</p>
            </BtnWrapper>
        </StyledLink>
    );
    
}

export default AddBtn;