import styled from "styled-components"
import Searchbar from "./Searchbar/Searchbar";
import Menu from "./Menu/Menu";

/**
 * Styles
 */

const TopbarWrapper = styled.div `

    position:absolute;
    top:0;
    display:flex;
    justify-content:space-between;
    align-items:center;
    left:120px;
    width:calc(100% - 120px);
    height:100px;

`

/**
 * Component
 * @returns 
 */
const Topbar = () => {

    return (
        <TopbarWrapper>
            <Searchbar />
            <Menu />
        </TopbarWrapper>
    );

}

export default Topbar;