import styled from "styled-components";
import FieldBlock from "./FieldBlock/FieldBlock";
import EmptyList from "../EmptyList/EmptyList";

/**
 * Styles
 */

const FieldListWrapper = styled.div `

    position:relative;
    display:flex;
    flex-direction:column;
    top:0;
    left:0;
    width:100%;
    border-collapse: collapse;
    overflow-y:auto;
    height:100%;
    border-spacing:0 10px;

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const FieldList = (props) => {

    return (
        props.fields && props.openField && props.deleteField ?
        <>
            {props.fields.length === 0 ? <EmptyList link={{path: '/add-field', text: "Ajoutez votre premier terrain"}} /> : null}
            <FieldListWrapper>
                { props.fields.map((field) => (
                    <FieldBlock key={field.fieldId} field={field} openField={props.openField} deleteField={props.deleteField} />
                )) }
            </FieldListWrapper>
        </>
        : null
    );
    
}

export default FieldList;