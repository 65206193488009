import { useEffect, useState } from "react";
import styled from "styled-components";
import NetworkInfo from "./NetworkInfo/NetworkInfo";
import Login from "../../../pages/Authentication/Login";
import Logout from "../../../pages/Authentication/Logout";

/**
 * Styles
 */

const AreaWrapper = styled.div `

    position:absolute;
    top:0;
    left:0;
    margin:0;
    width:100%;
    height:100%;
    overflow:none;
    background:#ecf0f1;
    display:flex;
    justify-content:center;
    align-items:center;

`

/**
 * Component
 * @returns 
 */
const Area = (props) => {

    // States
    const [online, setOnline] = useState(true);

    // Handlers
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);

    // Listen connection changes
    useEffect(() => {

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

    }, []);

    return (
        <AreaWrapper>
            { !online ? <NetworkInfo /> : "" }
            { props.page === "login" ? <Login page="login" /> : null}
            { props.page === "forgot" ? <Login page="forgot" /> : null}
            { props.page === "logout" ? <Logout /> : null}
        </AreaWrapper>
    );

}

export default Area;