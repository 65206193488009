import styled from "styled-components"
import logModelConverter from "../../../../utils/modelConverters/log";

/**
 * Styles
 */

const LogWrapper = styled.div `

    width:100%;
    padding:0 20px;
    margin:5px;
    font-size:14px;
    font-weight:600;

    & > span.time {
        color:#f1c40f;
        font-weight:600;

    }

    & > span.type.online-subscription {
        color:#2ecc71;
    }

    & > span.type.online-cancel {
        color:#e74c3c;
    }

    & > span.type.result {
        color:#3498db;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const Log = (props) => {

    return (

        props.date && props.type && props.content &&

        <LogWrapper>
            <span className="time">{ logModelConverter.getFormattedTime(props.date) }</span> - 
            <span className={props.type + " type"}>{ props.content }</span>
        </LogWrapper>

    );
    
}

export default Log;