import styled from "styled-components";
import PouleBlock from "./PouleBlock/PouleBlock";

const StyledDiv = styled.div `

    position:relative;
    top:0:
    left:0;
    margin:20px 0;
    width:90%;
    overflow:auto;
    display:flex;
    flex-wrap:no-wrap;

`

const PouleList = (props) => {

    return (

        <StyledDiv>
            <PouleBlock teams={[]} pouleId={0} nbPoule={0} selectedDerivationPouleId={props.selectedDerivationPouleId} handleChangeSelectedDerivationPouleId={props.handleChangeSelectedDerivationPouleId} />
            { props.poules.map((p) => <PouleBlock teams={p.teams} pouleId={p.pouleId} nbPoule={p.nbPoule} selectedDerivationPouleId={props.selectedDerivationPouleId} handleChangeSelectedDerivationPouleId={props.handleChangeSelectedDerivationPouleId} />) }
        </StyledDiv>

    )

}

export default PouleList;