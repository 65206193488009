import styled from "styled-components";
import pouleModelConverter from "../../../../utils/modelConverters/poule";

const StyledDiv = styled.div `

    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    border-radius:10px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    width:180px;
    margin:0 20px 0 0;
    transition:all .3s ease;
    flex-shrink:0;

    &:hover {
        cursor:pointer;
        transition:all .3s ease;
        transform:scale(1.05);
    }

    & > div:first-child {
        background:#2c3e50;
        border-top-left-radius:10px;
        border-top-right-radius:10px;
        height:40px;
        color:white;
        font-weight:800;
        font-size:20px;
        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;
    }

    & > div:nth-child(2) {

        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        overflow:hidden;
        padding:8px 14px;

    }

    & > div:nth-child(2) > p {
        margin:0;
        font-size:14px;
        color:grey;
        font-weight:500;
    }

    & > div:nth-child(3) {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:end;
    }

    & > div:nth-child(3) > span {
        font-weight:800;
        font-size:13px;
        margin:5px 0px 10px 5px;
    }

    & > div:nth-child(3) > div {
        width:15px;
        height:15px;
        border-radius:50%;
        margin:5px 10px 10px 5px;
    }

    & > div:nth-child(3) > span.field {
        margin:0 20px 0 0;
    }

`

const PouleBlock = (props) => {
    
    return (

        <StyledDiv onClick={() => props.openPoule(props.poule.pouleId)}>
            <div>{ pouleModelConverter.getFormattedPouleTitle(props.poule.nbPoule, props.isFinal) }</div>
            {

            !props.isFinal ?

            <div>
                { props.poule.formattedClassement.map((c) => <p key={c.teamId}>{ c.name }</p>) }
            </div>

            :

            <div></div>

            }
            <div>
                <span className="field">{props.poule.fieldName ? props.poule.fieldName : ""}</span>
                <span className={ props.poule.percentageScored === 100 ? "success" : (props.poule.percentageScored === 0 ? "error" : "medium") }>{ props.poule.percentageScored }%</span>
                <div className={ props.poule.percentageScored === 100 ? "successbg" : (props.poule.percentageScored === 0 ? "errorbg" : "mediumbg") }></div>
            </div>
        </StyledDiv>

    )

}

export default PouleBlock;