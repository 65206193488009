const teamValidator = (name, levelId, giveup, confirmed) => {

    if(typeof name !== "string") throw new Error("Le nom est invalide.");
    if(name.length === 0) throw new Error("Le nom ne peut pas être vide.");
    if(name.length > 50) throw new Error("Le nom est trop long.");
    if(typeof giveup !== "boolean") throw new Error("Le status est invalide");
    if(typeof confirmed !== "boolean") throw new Error("Le status est invalide est invalide");
    if(typeof parseInt(levelId) !== "number") throw new Error("Le niveau est invalide");
    if(parseInt(levelId) === 0) throw new Error("Vous devez préciser un niveau.");

    return;

}

export default teamValidator;