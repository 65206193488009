import styled from "styled-components"
import Tour from "./Tour/Tour";

/**
 * Styles
 */

const TourCategoryWrapper = styled.div `

    position:relative;
    top:0;
    width:100%;
    display:flex;
    flex-direction:column;

    & > h3 {
        font-size:14px;
        color:#95a5a6;
        font-weight:500;
    }

    & > div {
        display:flex;
        flex-wrap:wrap;
        width:100%;
        position:relative;
        top:0;
        left:0;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TourCategory = (props) => {

    return (

        props.tours && props.title &&

        <TourCategoryWrapper>

            <h3>{ props.title } ({props.tours.length})</h3>

            <div>
                { props.tours.map((tour) => (
                    <Tour title={tour.tour.name} progression={tour.percentageScored} key={tour.tour.tourId} tourId={tour.tour.tourId} />
                ))}
            </div>

        </TourCategoryWrapper>

    );

}

export default TourCategory;