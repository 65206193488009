import { useEffect, useState } from "react"
import styled from "styled-components"

/**
 * Styles
 */

const StyledInput = styled.div `

    border-radius:8px;
    border:1px solid black;
    background:white;
    padding:0;
    font-weight:550;
    outline:0;
    width:100%;
    height:100%;
    transition:all .3s ease;
    cursor:pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:22px;
    font-weight:600;
    color:white;

    &.checked {
        background:#2c3e50;
    }

    &.disabled {
        background:grey;
        cursor:default;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const CheckboxInput = (props) => {

    // Check status
    const [checked, setChecked] = useState(false);

    // Default value
    useEffect(() => {

        setChecked(props.value);

    }, [props.value]);

    // Handler
    const toggle = () => {
        if(!props.isDisabled) {
            props.onChange(checked ? false : true);
            setChecked(checked ? false : true);
        }
    }

    let classNames = "";
    if(checked) classNames += "checked ";
    if(props.isDisabled) classNames += "disabled";

    return (
        <StyledInput onClick={toggle} className={classNames}>{checked ? "X" : ""}</StyledInput>
    );

}

export default CheckboxInput;