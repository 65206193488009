import { useEffect, useState } from "react";
import styled from "styled-components";

/**
 * Styles
 */

const StyledDiv = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    display:flex;
    flex-direction:column;

    & > h4 {
        font-size:20px;
        color:#f1c40f;
        margin:40px 0 20px 0;
    }

    & > div {
        display:flex;
        flex-direction:row;
        align-items:center;
    }

    &.sb > div {
        justify-content:space-between;
    }

    &.start > div {
        justify-content:start;
    }

    &.end > div {
        justify-content:end;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const Section = (props) => {

    // Align state
    const [align, setAlign] = useState("");

    useEffect(() => {
        setAlign(props.align ? props.align : "sb");
    }, [props.align]);

    return (
        <StyledDiv className={align}>
            {props.title ? <h4>{props.title}</h4> : null}
            <div>
                {props.children}
            </div>
        </StyledDiv>
    );

}

export default Section;