import styled, { keyframes } from "styled-components";

/**
 * Styles
 */

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const NetworkInfoWrapper = styled.div `

    position:fixed;
    top:0;
    right:0;
    width:100%;
    height:100%;
    z-index:9999;
    background:rgba(0,0,0,0.7);
    display:flex;
    justify-content:center;
    align-items:center;

    & > div {
        display:flex;  
        justify-content:start;
        align-items:center;
        color:white;
        animation: ${fadeInOut} 4s ease-in-out infinite;
    }

    & > div > p:first-child {
        font-size:60px;
        margin:0;
        font-weight:bold;
    }

    & > div > p:first-child > ion-icon {
        padding:23px 30px 0 0;
    }

    & > div > p:last-child {
        font-size:20px;
        font-weight:700;
    }

`

/**
 * Component
 * @returns 
 */
const NetworkInfo = () => {

    return (
        <NetworkInfoWrapper>
            <div>
                <p><ion-icon name="wifi-outline"></ion-icon></p>
                <p>Vous êtes hors connexion ...</p>
            </div>
        </NetworkInfoWrapper>
    );
    
}

export default NetworkInfo;