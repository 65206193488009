import styled from "styled-components"
import TourCategory from "./TourCategory/TourCategory"

/**
 * Styles
 */

const TourStateWrapper = styled.div `

    position:relative;
    top:0;
    left:0;
    width:calc(100% - 50px);
    display:flex;
    flex-direction:column;
    margin:0 25px 0 25px;

`

/**
 * Component
 * @returns 
 */
const TourState = (props) => {

    return (

        props.tours &&

        
        <TourStateWrapper>
            <TourCategory title="Terminés" type="pending" tours={props.tours.filter((t) => t.percentageScored === 100)} />
            <TourCategory title="En cours" type="done" tours={props.tours.filter((t) => t.percentageScored !== 100)} />
        </TourStateWrapper>

    );
    
}

export default TourState;