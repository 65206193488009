import styled from "styled-components";
import TeamModel from "../../../../utils/modelConverters/team"
import Row from "../../Table/Row/Row";

/**
 * Styles
 */

const StyledTr = styled(Row) `

    & > div:first-child {
        position:absolute;
        left:0;
        margin:0 0 0 24px;
    }

    & > div:nth-child(2) {
        position:absolute;
        left:18%;
    }

    & > div:nth-child(3) {
        position:absolute;
        left:28%;
    }

    & > div:nth-child(4) {
        position:absolute;
        left:43%;
    }

    & > div:nth-child(5) {
        position:absolute;
        left:53%;
    }

    & > div:nth-child(6) {
        position:absolute;
        left:63%;
    }

    & > div:nth-child(7) {
        position:absolute;
        left:73%;
    }

    &.confirmed {
        border-left: 10px solid #2ecc71;
    }

    &.notconfirmed {
        border-left: 10px solid #e74c3c;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const TeamBlock = (props) => {

    const confirmed = props.team.team ? props.team.team.confirmed : false;

    return (
        props.team.team && props.openTeam && props.deleteTeam ?
        <StyledTr onMouseOver={props.onHover} onClick={() => props.openTeam(props.team.team.teamId)} className={confirmed ? "confirmed" : "notconfirmed"}>
            <div>{props.team.team.name}</div>
            <div>{props.team.team.players.length} joueurs</div>
            <div>{ TeamModel.getFormattedRegistrationDate(props.team.team)}</div>
            <div>{ TeamModel.getFormattedCanal(props.team.team) }</div>
            <div>{props.team.team.level.name}</div>
            <div className={ TeamModel.getFormattedConfirmation(props.team.team).className }>{ TeamModel.getFormattedConfirmation(props.team.team).text }</div>
            <div className={ TeamModel.getFormattedPayment(props.team.team).className }>{ TeamModel.getFormattedPayment(props.team.team).text }</div>
            <div className="btns">
                {
                    props.team.deletable &&
                    <div className="btn large info" onClick={(event) => props.handleDerivate(event, props.team.team)}>Engager</div>
                }
                { confirmed ?
                    <div className="btn error" onClick={(event) => props.confirmTeam(event, props.team.team.teamId, false)}><ion-icon name="alert-outline"></ion-icon></div>
                :
                    <div className="btn success" onClick={(event) => props.confirmTeam(event, props.team.team.teamId, true)}><ion-icon name="checkmark-done"></ion-icon></div>
                }
                {
                    props.team.deletable &&
                    <div className="btn error" onClick={(event) => props.deleteTeam(event, props.team.team.teamId)}><ion-icon name="trash"></ion-icon></div>
                }
            </div>
        </StyledTr>
        : null
    );
    
}

export default TeamBlock;