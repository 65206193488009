import styled from "styled-components";

const StyledDiv = styled.div `

    margin:0 40px 0 0;

    & > label {
        font-weight:bold;
    }

`

const FormBlockCategory = (props) => {
    return (
        <StyledDiv><label>{props.title}</label></StyledDiv>
    );
}

export default FormBlockCategory;