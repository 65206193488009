import styled from "styled-components";
import IllustrationBox from "./IllustrationBox/IllustrationBox";
import FormBox from "./FormBox/FormBox";

const LoginBoxArea = styled.div `

    background:white;
    -webkit-box-shadow: 0px 0px 50px 14px rgba(0,0,0,0.07); 
    box-shadow: 0px 0px 50px 14px rgba(0,0,0,0.07);
    border-radius:14px;
    width:70%;
    height:70%;
    display:flex;
    flex-direction:row;

`

const LoginBox = (props) => {
    
    return (
            <LoginBoxArea>
                <FormBox page={props.page} />
                <IllustrationBox />
            </LoginBoxArea>
    )

}
export default LoginBox;