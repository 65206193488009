import styled from "styled-components";
import TextInput from "../../Inputs/TextInput";

const StyledDiv = styled.div `

    width:calc(100% - 10px);
    height:70px;
    margin:15px 5px;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.17);
    border-radius:10px;
    background:rgba(46,204,113, 0.3);
    display:flex;
    flex-direction:row;
    align-items:center;
    font-weight:normal;
    justify-content:space-between;

    & > div:first-child {
        padding:0 0 0 20px;
        width:33%;
        justify-content:start;
    }

    & > div:last-child {
        padding:0 20px 0 0;
        width:33%;
        text-align:right;
        justify-content:end;
    }

    & > div:nth-child(2) {
        
        display:flex;
        align-items:center;

        & > div.score {

            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            margin:0 5px;

            & > label {
                font-size:12px;
            }

        }

    }

    & > div:first-child, & > div:last-child {

        text-transform:uppercase;
        font-size:12px;

        display:flex;
        flex-direction:row;
        align-items:center;

        & > ion-icon {
            font-size:30px;
            padding:0 0 0 20px;
            color:#3498db;
            cursor:pointer;
            transition:all .3s ease;
        }

        & > ion-icon:hover {
            transform:scale(1.3);
            transition:all .3s ease;
        }

    }

    & div.winner {
        text-decoration:underline;
    }

    &.not_filled {
        background:rgba(231,76,60,0.3);
    }

`

const ScoreDiv = styled.div `
    width:32px;
    height:32px;
    margin:5px;
`

const GameBlock = (props) => {

    if(props.steps.length !== props.scores['scoresTeamA'].length) return;

    const stepsSorted = props.steps.sort((a, b) => {
        if(a.CompetitionsSteps.index < b.CompetitionsSteps.index) return 1;
        if(a.CompetitionsSteps.index > b.CompetitionsSteps.index) return -1;
        return 0;
    }).map((s) => {
        return s.name.charAt(0).toUpperCase() + s.name.slice(1) + "s";
    });

    let winner = null;

    for(let i = 0; i < props.steps.length; i++) {
        if(parseInt(props.scores['scoresTeamA'][i]) > parseInt(props.scores['scoresTeamB'][i])) winner = 'A';
        if(parseInt(props.scores['scoresTeamA'][i]) < parseInt(props.scores['scoresTeamB'][i])) winner = 'B';
        if(winner) break;
    }

    let filled = false;

    for(let i = 0; i < props.steps.length; i++) {
        if((props.scores['scoresTeamA'][i] !== 0 && props.scores['scoresTeamA'][i] !== '' && props.scores['scoresTeamA'][i] !== '0') || (props.scores['scoresTeamB'][i] !== 0 && props.scores['scoresTeamB'][i] !== '' && props.scores['scoresTeamB'][i] !== '0')) filled = true;
    }
    
    
    return (
        
        <StyledDiv className={!filled && "not_filled"}>

            <div className={winner === 'A' && "winner"}>
                <h4>{ props.game.teamAName }</h4>
            </div>

            <div>

                { props.scores['scoresTeamA'].map((s, i) => (

                    <div className="score">
                        <label>{stepsSorted[i]}</label>
                        <ScoreDiv>
                            <TextInput value={s ? s : ''} centered={true} onChange={ (e) => props.onUpdateGame(e, 'scoresTeamA', i, props.game.gameId) } />
                        </ScoreDiv>
                    </div>

                ))}

                -

                { props.scores['scoresTeamB'].map((s, i) => (

                    <div className="score">
                        <label>{stepsSorted[i]}</label>
                        <ScoreDiv>
                            <TextInput value={s ? s : ''} centered={true} onChange={ (e) => props.onUpdateGame(e, 'scoresTeamB', i, props.game.gameId) } />
                        </ScoreDiv>
                    </div>

                ))}

            </div>

            <div className={winner === 'B' && "winner"}>
                <h4>{ props.game.teamBName }</h4>
                { props.isFinal && <ion-icon name="print-outline" onClick={() => props.generatePdfFinal(props.game.teamAName, props.game.teamBName)}></ion-icon> }
                <ion-icon name="checkmark-done-outline" onClick={() => props.onValidateGameUpdates(props.game.gameId)}></ion-icon>
            </div>

        </StyledDiv>

    );

}

export default GameBlock;