import styled from "styled-components";
import Row from "../../Table/Row/Row";

const StyledDiv = styled(Row) `

    & > div:first-child {
        position:absolute;
        left:0;
        margin:0 0 0 20px;
    }

    & > div:nth-child(2) {
        position:absolute;
        left:30%;
    }

`

/**
 * Component
 * @param {*} props 
 * @returns 
 */
const LevelBlock = (props) => {

    return (
        props.level && props.openLevel && props.deleteLevel ?
        <StyledDiv onMouseOver={props.onHover} onClick={() => props.openLevel(props.level.levelId)}>
            <div>{props.level.name}</div>
            <div>{props.level.coeff}</div>
            <div className="btns">
                <div className="btn error" onClick={(event) => props.deleteLevel(event, props.level.levelId)}><ion-icon name="trash"></ion-icon></div>
            </div>
        </StyledDiv>
        : null
    );
    
}

export default LevelBlock;