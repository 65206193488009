import { useEffect, useState } from "react";
import styled from "styled-components";

/**
 * Styles
 */

const StyledDiv = styled.div `

    width:calc(100% - 5px);
    margin:2.5px;
    -webkit-box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.36); 
    box-shadow: 0px 0px 13px -5px rgba(0,0,0,0.36);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    border-radius:8px;

    & > div {
        display:flex;
        flex-direction:row;
        justify-content:start;
        align-items:center;
        width:100%;
        margin:8px 0;
    }

    & > div > div:first-child {
        font-size:28px;
        font-weight:700;
        margin:0 20px;
    }

    & > div > div:nth-child(2) {
        font-size:13px;
        font-weight:500;
    }

    & > div > div.danger {
        color:#e74c3c;
    }

    & > div > div.full {
        color:#2ecc71;
    }

    & > div > div.intermediate {
        color:#e67e22;
    }

`

/**
 * Component
 * @returns 
 */
const TeamResume = (props) => {

    const [nbConfirmed, setNbConfirmed] = useState(0);
    const [nbBilled, setNbBilled] = useState(0);
    const [ratioBilled, setRatioBilled] = useState(1);
    const [ratioConfirmed, setRatioConfirmed] = useState(1);
    const [total, setTotal] = useState(0);

    /**
     * Update the total
     */
    useEffect(() => {
        setTotal(props.teams.length);
    }, [props.teams])

    /**
     * Define the numbers of confirmed and billed
     */
    useEffect(() => {

        let billed = 0;
        let confirmed = 0;

        for(const t of props.teams) {
            let payed = true;
            for(const p of t.team.players) {
                if(!p.PlayersTeams.payed) payed = false;
            }
            if(payed) billed++;
            if(t.team.confirmed) confirmed++;
        }

        setNbBilled(billed);
        setNbConfirmed(confirmed);

    }, [props.teams]);

    /**
     * Calculate the ratios
     */
    useEffect(() => {

        if(nbBilled / total < 0.5) setRatioBilled("danger");
        if(nbConfirmed / total < 0.5) setRatioConfirmed("danger");
        if(nbBilled / total >= 0.5 && nbBilled / total < 1) setRatioBilled("intermediate");
        if(nbConfirmed / total >= 0.5 && nbConfirmed / total < 1) setRatioConfirmed("intermediate");
        if(nbBilled / total === 1) setRatioBilled("full");
        if(nbConfirmed / total === 1) setRatioConfirmed("full");

    }, [nbBilled, nbConfirmed, total]);

    return (

        props.teams ?

        <StyledDiv>
            <div>
                <div className={ ratioConfirmed }>
                    { nbConfirmed }/{ total }
                </div>
                <div>
                    confirmées
                </div>
            </div>
            <div>
                <div className={ ratioBilled }>
                    { nbBilled }/{ total }
                </div>
                <div>
                    réglées
                </div>
            </div>
        </StyledDiv>

        :

        null

    );
    
}

export default TeamResume;