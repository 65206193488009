import Sidebar from "./Sidebar/Sidebar";
import Topbar from "./Topbar/Topbar";

/**
 * Component
 * @returns 
 */
const Header = () => {

    return (
        <>
            <Topbar />
            <Sidebar />
        </>
    );
    
}

export default Header;