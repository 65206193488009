import styled from "styled-components"
import SearchResults from "./SearchResults/SearchResults";
import { useState } from "react";
import fetchAPI from "../../../../../utils/fetch";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

/**
 * Styles
 */

const StyledInput = styled.input `

    transition:all .3s ease;
    height:50px;
    margin-left:40px;
    outline:0;
    color:#34495e;
    width:800px;
    background:#ecf0f1;
    border:0;
    border-radius:8px;
    padding-left:15px;
    font-size:16px;
    font-weight:600;

    &:hover {
        transition:all .3s ease;
        background:#d9dadb;
    }

`

/**
 * Component
 * @returns 
 */
const Searchbar = () => {

    // General
    const navigate = useNavigate();
    const [cookies] = useCookies(["competition"]);

    // Search
    const [showResults, setShowResults] = useState(false);
    const [results, setResults] = useState([]);
    const [keyword, setKeyWord] = useState("");

    const handleSearch = () => {
        setShowResults(true);
    }

    const handleUpdateSearch = (e) => {

        setShowResults(true);

        if(!e.target.value) {
            setResults([]);
            setKeyWord("");
            return;
        }

        const keyword_ = e.target.value;

        (async () => {

            const request = await fetchAPI('GET', '/general/search', {
                competitionId: cookies.competition,
                keyword: keyword_
            });

            if(request.status !== 200) {
                if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                    navigate('/');
                    return;
                }
            } else {
                 setResults(request.json.data.results);
            }

        })();

        setKeyWord(keyword_);

    }

    const handleKeyDown = (event) => {
        if(!results || results.length === 0) return;
        if (event.key === 'Enter') {
            handleNavigate(results[0].elementType, results[0].elementId);
            return;
        }
      };

    const handleNavigate = (t, id) => {
        setShowResults(false);
        setKeyWord("");
        setResults([]);
        navigate('/' + t + '/' + id);
    }

    const handleExitSearch = () => {
        setShowResults(false);  
    }

    return (
        <>
            { cookies.competition ? <StyledInput placeholder="Rechercher dans Neoscore ..." value={keyword} onFocus={handleSearch} onBlur={handleExitSearch} onChange={handleUpdateSearch} onKeyDown={handleKeyDown} />: <div></div>}
            { showResults && <SearchResults results={results} handleNavigate={handleNavigate} />}
        </>
    )
}

export default Searchbar;