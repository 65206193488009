import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import AddBtn from "../../components/Software/Btns/AddBtn";
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import fetchAPI from "../../utils/fetch";
import Popup from "../../components/Software/Popup/Popup";
import Wrapper from "../../components/Software/Wrapper/Wrapper";
import TourList from "../../components/Software/TourList/TourList";
import TourClassement from "../../components/Software/Classements/TourClassement/TourClassement";
import PouleList from "../../components/Software/PouleList/PouleList";
import TourPicker from "../../components/Software/Classements/TourPicker/TourPicker";
import FormTourConfigurator from "../../components/Software/FormTourConfigurator/FormTourConfigurator";
import tourModelConverter from "../../utils/modelConverters/tour";
import PouleDragNDropList from "../../components/Software/PouleList/PouleDragNDropList/PouleDragNDropList";
import ValidationSection from "../../components/Software/ValidationSection/ValidationSection";
import Arrow from "../../components/Software/TourList/Arrow/Arrow";
import arrayUtils from "../../utils/array";
import tourValidator, { tourTypes_, poulesCompositions_ } from "../../utils/validators/tour";
import PouleClassement from "../../components/Software/Classements/PouleClassement/PouleClassement";
import GameList from "../../components/Software/GameList/GameList";
import { PDFDocument } from 'pdf-lib';
import competitionModelConverter from "../../utils/modelConverters/competition";
import pouleModelConverter from "../../utils/modelConverters/poule";
import Loader from "../../components/Loader/Loader";
import QRCodeComponent from "../../components/QRCodeComponent/QRCodeComponent";
import MOBILE_HOST_URL from "../../utils/mobileHost";

/**
 * Styles
 */

const Cols = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    overflow:visible;

`

const Col1 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:55%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:start;
    overflow:visible;

`

const Col3 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:75%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:start;
    overflow:visible;

`

const Col2 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:43%;
    display:flex;
    justify-content:space-between;
    align-items:start;
    flex-direction:column;
    overflow-y:auto;
    overflow:visible;

`

const Col4 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:23%;
    display:flex;
    justify-content:space-between;
    align-items:start;
    flex-direction:column;
    overflow-y:auto;
    overflow:visible;

`

const Box1 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:66%;
    box-shadow: 0px 0px 33.5px 0px rgba(0, 0, 0, 0.22);
    overflow:visible;
    border-radius:10px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    & .validation {
        padding:0 30px 30px 0;
    }

`

const Box4 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:100%;
    box-shadow: 0px 0px 33.5px 0px rgba(0, 0, 0, 0.22);
    overflow:visible;
    border-radius:10px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;

    & > div.ct {
        height:80%;
        overflow:auto;
    }

    & .validation {
        padding:0 30px 30px 0;
    }

`

const Box2 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:30%;
    box-shadow: 0px 0px 33.5px 0px rgba(0, 0, 0, 0.22);
    overflow:visible;
    border-radius:10px;

    & .validation {
        padding:0 30px 30px 0;
    }

`

const Box3 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:100%;
    box-shadow: 0px 0px 33.5px 0px rgba(0, 0, 0, 0.22);
    overflow:visible;
    border-radius:10px;

    & h3.subtitle {
        margin: 30px 0 10px 30px;
        color:#f1c40f;
        font-weight:700;
        font-size:16px;
    }

    & .validation {
        padding:0 30px 30px 0;
    }

`

const Box5 = styled.div `

    position:relative;
    top:0;
    left:0;
    width:100%;
    height:100%;
    box-shadow: 0px 0px 33.5px 0px rgba(0, 0, 0, 0.22);
    overflow:visible;
    border-radius:10px;

    & .validation {
        padding:0 30px 30px 0;
    }

`

const TitleBox = styled.div `

    & > h3 {
        font-size:20px;
        margin:0;
    }

    & > div.toolbox {

        height:50px;
        background:#ecf0f1;
        border-radius:20px;
        display:flex;
        justify-content:start;
        align-items:center;
        font-size:30px;
        padding: 0 10px;

    }

    & > div.toolbox > ion-icon {
        padding:0 2.5px;
        cursor:pointer;
    }

    & > div.toolbox > ion-icon:first-child {
        color:#2ecc71;
    }

    & > div.toolbox > ion-icon:nth-child(2) {
        color:#3498db;
    }
    
    display:flex;
    flex-direction:row;
    margin:30px 30px 10px 30px;
    align-items:center;
    justify-content:space-between;

`

const AreaClassement = styled.div `

    width:calc(100% - 60px);
    margin:30px;
    height:70%;
    overflow:auto;

    &::-webkit-scrollbar {
        width: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
    
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }

`

const AreaPouleClassement = styled.div `

width:calc(100% - 60px);
margin:30px;
height:25%;
overflow:auto;

&::-webkit-scrollbar {
    width: 6px;
}

&::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

&::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

`

const BtnsSection = styled.div `

    display:flex;
    justify-content:end;
    margin:40px 30px;

    & > div {
        width:200px;
        margin:0 0 0 14px;
    }

`

const Shortcut = styled.div `

    margin:0 30px;
    position:relative;

    & > span {
        margin:0 20px 0 0;
        font-weight:bold;
        font-size:12px;
        transition:all .3s ease;
    }

    & > span:hover {
        color:#f1c40f;
        transition:all .3s ease;
        cursor:pointer;
    }

`

/**
 * Component
 * @returns 
 */
const Manager = ({match}) => {

    // Common
    const {id} = useParams();
    const [cookies] = useCookies(['competition']);
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    // Competition object
    const [competition, setCompetition] = useState(null);
    const [steps, setSteps] = useState(null);
    const [competitionClassement, setCompetitionClassement] = useState(null);

    // Fields
    const [fields, setFields] = useState([]);

    // Actual tour
    const [current, setCurrent] = useState(null);
    const [currentClassement, setCurrentClassement] = useState([]);
    const [poules, setPoules] = useState([]);
    const [children, setChildren] = useState([]);
    const [parents, setParents] = useState([]);

    // Actual selected poule
    const [selectedPoule, setSelectedPoule] = useState(null);
    const [scoresGames, setScoresGames] = useState(null);
    const [games, setGames] = useState([]);

    // Switchs
    const [isFirstTour, setIsFirstTour] = useState(false);
    const [isCreateTour, setIsCreateTour] = useState(0);

    // New tour
    const [poulesToCreate, setPoulesToCreate] = useState([]);
    const [tourType, setTourType] = useState("1");
    const [poulesComposition, setPoulesComposition] = useState("1");
    const [tourName, setTourName] = useState("");
    const [tourNbTeams, setTourNbTeams] = useState("3");

    // Triggers
    const [tourFetchTrigger, setTourFetchTrigger] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    // Data
    const [tourTypes] = useState(tourTypes_);
    const [poulesCompositions] = useState(poulesCompositions_);

    /**
     * Load competition
     */
    useEffect(() => {

        (async () => {

            if(!cookies.competition) {
                navigate('/');
                return;
            }

            try {

                // Try to get the competition
                const request = await fetchAPI('GET', '/competition', {
                    competitionId: parseInt(cookies.competition)
                });

                if(request.status !== 200) {
                    if(request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/logout');
                        return;
                    }
                    throw new Error();
                } else {
                    setCompetition(request.json.data.competition);
                    setCompetitionClassement(request.json.data.formattedClassement);
                }

            }
            catch(e) {
                setError("Une erreur est survenue.");
            }
            
        })();

    }, [cookies.competition, navigate])

    /**
     * Load fields
     */
    useEffect(() => {

        (async () => {

            if(!cookies.competition) {
                navigate('/');
                return;
            }

            try {

                // Try to get the competition
                const request = await fetchAPI('GET', '/field/allofcompetition', {
                    competitionId: parseInt(cookies.competition)
                });

                if(request.status !== 200) {
                    if(request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/logout');
                        return;
                    }
                    throw new Error();
                } else {
                    setFields(request.json.data.fields.map((f) => {
                        return {
                            value: f.fieldId,
                            text: f.name
                        }
                    }));
                }

            }
            catch(e) {
                setError("Une erreur est survenue.");
            }
            
        })();

    }, [cookies.competition, navigate])

    /**
     * Load tour, poules and classement
     */
    useEffect(() => {

        setIsLoading(true);

        (async () => {

            if(id) {
                
                try {

                    const request = await fetchAPI('GET', '/tour', {
                        tourId: parseInt(id)
                    });

                    if(request.status !== 200) {
                        if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                            navigate('/');
                            return;
                        }
                        if(request.status === 404 || (request.status === 403 && request.json.message === "ERR_MISSINGPRIVILEGES")) {
                            navigate('/manager');
                            return;
                        }
                    }

                    const tour = request.json.data.tour;
                    const parents_= request.json.data.parents;
                    const children_ = request.json.data.children;

                    setCurrent({
                        tourId: tour.tourId,
                        depth: tour.depth,
                        name: tour.name,
                        complete: tour.percentageScored,
                        isFinal: tour.isFinal,
                        nbTeams: tourModelConverter.getFormattedNbTeams(tour),
                        nbPoules: tour.poules.length,
                        nbgames: tourModelConverter.getFormattedNbGames(tour),
                    });

                    setChildren(children_.map((c) => {
                        return {
                            tourId: c.tourId,
                            name: c.name,
                            complete: c.percentageScored,
                            isFinal: c.isFinal,
                            nbTeams: tourModelConverter.getFormattedNbTeams(c),
                            nbPoules: c.poules.length,
                            nbgames: tourModelConverter.getFormattedNbGames(c),
                        }
                    }));

                    setParents(parents_.map((c) => {
                        return {
                            tourId: c.tourId,
                            name: c.name,
                            complete: c.percentageScored,
                            isFinal: c.isFinal,
                            nbTeams: tourModelConverter.getFormattedNbTeams(c),
                            nbPoules: c.poules.length,
                            nbgames: tourModelConverter.getFormattedNbGames(c),
                        }
                    }));

                    setCurrentClassement(tour.formattedClassement.filter((c) => !c.giveup));
                    
                    tour.poules.sort((a, b) => a.nbPoule > b.nbPoule ? 1 : -1);

                    setPoules(tour.poules);
                    setSteps(tour.competition.steps);

                    setIsLoading(false);


                }
                catch(e) {
                    setError('Une erreur est survenue.');
                    setIsLoading(false);
                }

            } else {

                try {

                    if(competition) {

                        const request = await fetchAPI('GET', '/tour/allofcompetition', {
                            competitionId: competition.competitionId 
                        });
    
                        if(request.status !== 200) {
                            navigate('/');
                            return;
                        } else {
                            
                            const tours = request.json.data.tours;
    
                            if(tours.length === 0) {
                                setIsFirstTour(true);
                                setIsLoading(false);
                            } else {
                                navigate('/manager/' + tours[tours.length - 1].tour.tourId);
                                return;
                            }
    
                        }

                    }

                }
                catch(e) {
                    setError('Une erreur est survenue.');
                    setIsLoading(false);
                }

            }

        })();

    }, [id, navigate, competition, tourFetchTrigger]);

    useEffect(() => {

        if(!selectedPoule) return;

        // If no tour is selected then return an error
        if(!current || !poules) {
            setError("Vous devez sélectionner un tour.");
            return;
        }

        let match = null;

        // Check if the poule with this id is in the current tour
        for(const p of poules) {
            if(p.pouleId === selectedPoule.pouleId) match = p;
        }

        if(!match) {
            setError("La poule n'existe pas dans le tour actuel.");
            return;
        }

        setSelectedPoule(match);

    }, [poules])


    // Handlers
    
    const handleChangeTourType = (e) => {
        setTourType(e.target.value);
    }

    const handleChangePoulesComposition = (e) => {
        setPoulesComposition(e.target.value);
    }

    const handleChangeTourNbTeams = (e) => {
        setTourNbTeams(e.target.value);
    }

    const handleChangeTourName = (e) => {
        setTourName(e.target.value);
    }

    /**
     * Handler for tour creation
     */
    const handleCreateTour = () => {
        setIsCreateTour(1);
    }

    const openPoule = async (id) => {

        try {

            // If no tour is selected then return an error
            if(!current || !poules) {
                setError("Vous devez sélectionner un tour.");
                return;
            }

            let match = null;

            // Check if the poule with this id is in the current tour
            for(const p of poules) {
                if(p.pouleId === id) match = p;
            }

            if(!match) {
                setError("La poule n'existe pas dans le tour actuel.");
                return;
            }

            setSelectedPoule(match);

            const request = await fetchAPI('GET', '/game/getallofpoule_pro', {
                pouleId: match.pouleId
            });

            if(request.status !== 200) throw new Error();
            
            let scores = {};

            for(const g of request.json.data.games) {
                scores[g.gameId] = {}
                scores[g.gameId]['scoresTeamA'] = g.scoresTeamA;
                scores[g.gameId]['scoresTeamB'] = g.scoresTeamB;
            }

            setScoresGames(scores);
            setGames(request.json.data.games);

        }
        catch(e) {
            setError("Une erreur est survenue.");
        }

    }

    /**
     * Handler for saving a game
     */
    const onUpdateGame = (event, player, index, id) => {

        const newScoresGames = {
            ...scoresGames,
            [id]: {
                ...scoresGames[id],
                [player]: scoresGames[id][player].map((s, i) => {
                    if(i !== index) return s;
                    return event.target.value;
                })
            }
        };

        setScoresGames(newScoresGames);
        
    }

    const onValidateGameUpdates = (id) => {


        const resultTeamA = scoresGames[id]['scoresTeamA'].map((s) => {
            if(!s) return 0;
            return parseInt(s);
        });
        const resultTeamB = scoresGames[id]['scoresTeamB'].map((s) => {
            if(!s) return 0;
            return parseInt(s);
        });

        (async () => {

            try {

                setIsLoading(true);

                const request = await fetchAPI('PUT', '/game', {
                    game: {
                        gameId: parseInt(id),
                        scoresTeamA: resultTeamA,
                        scoresTeamB: resultTeamB
                    }
                });

                if(request.status !== 200) {
                    if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/');
                        return;
                    }
                    throw new Error();
                }

                setTourFetchTrigger(tourFetchTrigger + 1);
                setIsLoading(false);

            }
            catch(e) {
                setError("Une erreur est survenue.");
                setIsLoading(false);
            }

        })();


    }

    const handleSelectAllTeams = () => {

        if(!isFirstTour) setCurrentClassement(currentClassement.map((c) => {
            if(c.giveup || c.available !== true) return c;
            return {
                ...c,
                selectedForAfter: true
            }
        }));
        else setCompetitionClassement(competitionClassement.map((c) => {
            return {
                ...c,
                selectedForAfter: true
            }
        }));

    }

    const handleUnselectAllTeams = () => {

        if(!isFirstTour) setCurrentClassement(currentClassement.map((c) => {
            return {
                ...c,
                selectedForAfter: false
            }
        }));
        else setCompetitionClassement(competitionClassement.map((c) => {
            return {
                ...c,
                selectedForAfter: false
            }
        }));

    }

    /**
     * Handler for open panel of poules
     */
    const handleConfigTour = async (e) => {

        e.preventDefault();

        if(!tourName) {
            setTourName("<Tour sans nom>")
        }

        try {

            let selectedTeams = [];

            const classement = !isFirstTour ? currentClassement : competitionClassement;

            classement.map((c) => {
                if(c.selectedForAfter) selectedTeams.push(c);
                return c;
            });

            tourValidator(tourName ? tourName : "<Tour sans nom>", tourType, poulesComposition, selectedTeams, tourNbTeams);

            if(tourType === '1') {

                let nbTeams = tourNbTeams ? parseInt(tourNbTeams) : 3;

                const nbPoules = Math.floor(selectedTeams.length / nbTeams) !== 0 ? Math.floor(selectedTeams.length / nbTeams) : 1;

                let poules_ = [];

                for(let c = 0; c < nbPoules; c++) {
                    poules_.push({
                        id: c+1,
                        teams: [],
                        fieldId: 0
                    });
                }

                if(poulesComposition === '1') {
                    let i = 0;
                    for(let c of selectedTeams) {
                        if(i === poules_.length) i = 0;
                        poules_[i].teams.push(c);
                        i++;
                    }
                }
                if(poulesComposition === '2') {
                    let i = 0;
                    const shuffledTeams = arrayUtils.shuffleArray(selectedTeams);
                    for(let c of shuffledTeams) {
                        if(i === poules_.length) i = 0;
                        poules_[i].teams.push(c);
                        i++;
                    }
                }
                if (poulesComposition === '3') {

                    console.log(poules, currentClassement);

                    if(poules.length === 0 || currentClassement.length === 0) {
                        setError("Le brassage par décalage n'est pas possible pour un premier tour.");
                        return;
                    }

                    poules_ = [];

                    for (let c = 0; c < poules.length; c++) {
                        poules_.push({
                            id: c + 1,
                            teams: [],
                            fieldId: 0
                        });
                    }

                    for(let pouleToFill = 0; pouleToFill < poules.length; pouleToFill++) {

                        let pouleToSearch = pouleToFill;

                        for(let posToFill = 0; poules[pouleToSearch].formattedClassement[posToFill]; posToFill++) {
                            const isTeamSelected = currentClassement.filter((c) => ((c.teamId === poules[pouleToSearch].formattedClassement[posToFill].teamId) && (c.selectedForAfter === true)));
                            if(isTeamSelected.length !== 0) poules_[pouleToFill].teams.push(poules[pouleToSearch].formattedClassement[posToFill]);
                            pouleToSearch++;
                            if(pouleToSearch === poules_.length) pouleToSearch = 0;
                        }


                    }

                    
                }
                if(poulesComposition === '4') {
                    let i = 0;
                    let step = 1;
                    for(let c of selectedTeams) {
                        if(i === poules_.length && step === 1) {
                            step = -1;
                            i = i - 1;
                        }
                        if(i === -1 && step === -1) {
                            step = 1;
                            i = i + 1;
                        }
                        poules_[i].teams.push(c);
                        i = i + step;
                    }
                }

                if(fields.length !== 0) {
                    let bmp = 0;
                    for(let i = 0; i < poules_.length; i++) {
                        if(bmp === fields.length) bmp = 0;
                        poules_[i].fieldId = fields[bmp].value;
                        bmp++;
                    }
                }

                setPoulesToCreate(poules_);
                setIsCreateTour(2);
                setError(null);
                return;

            }
            if(tourType === '2') {

                if(selectedTeams.length  < 2 || selectedTeams.length > 16) {
                    setError("Un tour final doit être composé de 2 à 16 équipes");
                    return;
                }

                try {

                    const request = await fetchAPI('POST', '/tour/generatefinal_pro', {
                        selectedTeams: selectedTeams.map((slt) => slt.teamId),
                        fromTourId: current ? current.tourId : null,
                        competitionId: competition.competitionId,
                        name: tourName ? tourName : "<Tour sans nom>"
                    });

                    if(request.status !== 200) {
                        if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                            navigate('/');
                            return;
                        }
                        throw new Error("Une erreur est survenue.");
                    } else {
                        setTourFetchTrigger(tourFetchTrigger + 1);
                        setIsCreateTour(0);
                        setError(null);
                    }

                }
                catch(e) {
                    throw e;
                }

            }

        }
        catch(e) {
            setError(e.message);
        }

    }

    const handleChangeFieldId = (e, id) => {

        setPoulesToCreate(poulesToCreate.map((ptc) => {
            if(ptc.id === id) return {
                ...ptc,
                fieldId: e.target.value
            }
            return ptc;
        }));

    }

    /**
     * Handler for tour validation and publication
     */
    const handleValidateTour = async () => {

        try {

            if(poulesToCreate.length === 0) {
                throw new Error("Impossible de créer un tour vide.");
            }

            for(const ptc of poulesToCreate) {
                if(ptc.teams.length < 2 || ptc.teams.length > 5) throw new Error("Seulement les poules de 3, 4 et 5 sont autorisées.");
            }

            const request = await fetchAPI('POST', '/tour', {
                competitionId: competition.competitionId,
                tour: {
                    name: tourName,
                    fromTourId: current ? current.tourId : null,
                    poules: poulesToCreate.map((p) => {
                        return {
                            nbPoule: p.id,
                            teams: p.teams.map((t) => {
                                return t.teamId
                            }),
                            fieldId: parseInt(p.fieldId)
                        }
                    })
                }
            });

            if(request.status !== 200) {
                if(request.status === 403 && request.json.data.message === "ERR_PLEASE_RECONNECT") {
                    navigate('/');
                    return;
                }
                throw new Error();
            } else {
                setIsCreateTour(0);
                setIsFirstTour(false);
                setTourFetchTrigger(tourFetchTrigger + 1);
                setTourName("");
            }

        }
        catch(e) {
            console.log(e);
            setError(e.message);
        }
        
    }

    /**
     * Handler for selector of teams
     * @param {*} pos 
     */
    const handleChangeSelectionTeam = (id) => {

        if(!isFirstTour) {
            setCurrentClassement(currentClassement.map((c) => {
                if(c.teamId !== id) {
                    return {
                        ...c
                    }
                }
                return {
                    ...c,
                    selectedForAfter: c.selectedForAfter ? false : true
                }
            }));
        } else {
            setCompetitionClassement(competitionClassement.map((c) => {
                if(c.teamId !== id) {
                    return {
                        ...c
                    }
                }
                return {
                    ...c,
                    selectedForAfter: c.selectedForAfter ? false : true
                }
            }));
        }

        
    }

    const onDragEnd = (result) => {

        const { destination, source } = result;
    
        if (!destination) return;
    
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    
        const sourcePouleIndex = poulesToCreate.findIndex(poule => poule.id === parseInt(source.droppableId));
        const destinationPouleIndex = poulesToCreate.findIndex(poule => poule.id === parseInt(destination.droppableId));
    
        const sourcePoule = poulesToCreate[sourcePouleIndex];
        const destinationPoule = poulesToCreate[destinationPouleIndex];
        const sourceTeams = Array.from(sourcePoule.teams);
        const [movedTeam] = sourceTeams.splice(source.index, 1);
    
        if (sourcePoule === destinationPoule) {

            sourceTeams.splice(destination.index, 0, movedTeam);

            const newPoule = {
                ...sourcePoule,
                teams: sourceTeams,
            };
    
            const newPoules = Array.from(poulesToCreate);
            newPoules[sourcePouleIndex] = newPoule;
            setPoulesToCreate(newPoules);

        } else {

            const destinationTeams = Array.from(destinationPoule.teams);
            destinationTeams.splice(destination.index, 0, movedTeam);
    
            const newSourcePoule = {
                ...sourcePoule,
                teams: sourceTeams,
            };
    
            const newDestinationPoule = {
                ...destinationPoule,
                teams: destinationTeams,
            };
    
            const newPoules = Array.from(poulesToCreate);
            newPoules[sourcePouleIndex] = newSourcePoule;
            newPoules[destinationPouleIndex] = newDestinationPoule;
        
            setPoulesToCreate(newPoules);

        }

    };

    const handleDeletePouleToCreate = (id) => {

        const cf = window.confirm("Voulez-vous vraiment supprimer cette poule ?");
        if(cf) setPoulesToCreate(poulesToCreate.filter((p) => (p.id !== id)).map((el, i) => {
            return {
                ...el,
                id: i+1
            }
        }));
        else return;
    }

    const handleAddPouleToCreate = () => {

        let lastid = 0;

        for(const c of poulesToCreate) {
            lastid = c.id;
        }

        setPoulesToCreate([...poulesToCreate, {
            id: lastid + 1,
            teams: []
        }]);

    }

    const handleDeleteCurrentTour = async () => {

        const cf = window.confirm("Voulez-vous vraiment supprimer le tour actuel ?");

        try {

            if(cf) {
                
                const request = await fetchAPI('DELETE', '/tour', {
                    tourId: current.tourId
                });

                if(request.status !== 200) {
                    if(request.status === 403 && request.json.message === "ERR_PLEASE_RECONNECT") {
                        navigate('/');
                        return;
                    }
                    throw new Error();
                }

                setTourFetchTrigger(tourFetchTrigger+1);
                setCurrent(null);
                setCurrentClassement([]);
                setParents([]);
                setChildren([]);
                setPoules([]);
                return;

            }

        }
        catch(e) {
            setError('Une erreur est survenue.');
        }

    }

    const handleReturn = () => {
        setIsCreateTour(1);
        return;
    }

    const handleReturnPoule = () => {
        setSelectedPoule(null);
        setScoresGames(null);
        return;
    }

    const handleReturnGeneral = () => {
        setIsCreateTour(0);
        return;
    }

    const generatePdfFinalScheme = async () => {

        if(!competition || !current) return;

        const existingPdfBytes = await fetch('/finalscheme.pdf').then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const form = pdfDoc.getForm();

        const competition_name = form.getTextField('competition_name');
        const tour_name = form.getTextField('tour_name');
        const date = form.getTextField('date');

        competition_name.setText(competition.name);
        tour_name.setText(current.name);
        date.setText(competitionModelConverter.getFormattedDate(competition));

        const game1team1 = form.getTextField('game1team1');
        const game1team2 = form.getTextField('game1team2');
        const game2team1 = form.getTextField('game2team1');
        const game2team2 = form.getTextField('game2team2');
        const game3team1 = form.getTextField('game3team1');
        const game3team2 = form.getTextField('game3team2');
        const game4team1 = form.getTextField('game4team1');
        const game4team2 = form.getTextField('game4team2');
        const game5team1 = form.getTextField('game5team1');
        const game5team2 = form.getTextField('game5team2');
        const game6team1 = form.getTextField('game6team1');
        const game6team2 = form.getTextField('game6team2');
        const game7team1 = form.getTextField('game7team1');
        const game7team2 = form.getTextField('game7team2');
        const game8team1 = form.getTextField('game8team1');
        const game8team2 = form.getTextField('game8team2');
        const game9team1 = form.getTextField('game9team1');
        const game9team2 = form.getTextField('game9team2');
        const game10team1 = form.getTextField('game10team1');
        const game10team2 = form.getTextField('game10team2');
        const game11team1 = form.getTextField('game11team1');
        const game11team2 = form.getTextField('game11team2');
        const game12team1 = form.getTextField('game12team1');
        const game12team2 = form.getTextField('game12team2');
        const game13team1 = form.getTextField('game13team1');
        const game13team2 = form.getTextField('game13team2');
        const game14team1 = form.getTextField('game14team1');
        const game14team2 = form.getTextField('game14team2');
        const game15team1 = form.getTextField('game15team1');
        const game15team2 = form.getTextField('game15team2');

        const pickGame = (index, poule) => {
            for(const g of poule.games) {
                if(g.index === index) return g;
            }
            return null;
        }


        for(const p of poules) {

            if(p.nbPoule === 1) {
                if(pickGame(15, p)) game15team1.setText(pickGame(15, p).teamAName !== "?" ? pickGame(15, p).teamAName : "");
                if(pickGame(15, p)) game15team2.setText(pickGame(15, p).teamBName !== "?" ? pickGame(15, p).teamBName : "");
            }

            if(p.nbPoule === 2) {
                if(pickGame(13, p)) game13team1.setText(pickGame(13, p).teamAName !== "?" ? pickGame(13, p).teamAName : "");
                if(pickGame(13, p)) game13team2.setText(pickGame(13, p).teamBName !== "?" ? pickGame(13, p).teamBName : "");
                if(pickGame(14, p)) game14team1.setText(pickGame(14, p).teamAName !== "?" ? pickGame(14, p).teamAName : "");
                if(pickGame(14, p)) game14team2.setText(pickGame(14, p).teamBName !== "?" ? pickGame(14, p).teamBName : "");
            }

            if(p.nbPoule === 4) {
                if(pickGame(9, p)) game9team1.setText(pickGame(9, p).teamAName !== "?" ? pickGame(9, p).teamAName : "");
                if(pickGame(9, p)) game9team2.setText(pickGame(9, p).teamBName !== "?" ? pickGame(9, p).teamBName : "");
                if(pickGame(10, p)) game10team1.setText(pickGame(10, p).teamAName !== "?" ? pickGame(10, p).teamAName : "");
                if(pickGame(10, p)) game10team2.setText(pickGame(10, p).teamBName !== "?" ? pickGame(10, p).teamBName : "");
                if(pickGame(11, p)) game11team1.setText(pickGame(11, p).teamAName !== "?" ? pickGame(11, p).teamAName : "");
                if(pickGame(11, p)) game11team2.setText(pickGame(11, p).teamBName !== "?" ? pickGame(11, p).teamBName : "");
                if(pickGame(12, p)) game12team1.setText(pickGame(12, p).teamAName !== "?" ? pickGame(12, p).teamAName : "");
                if(pickGame(12, p)) game12team2.setText(pickGame(12, p).teamBName !== "?" ? pickGame(12, p).teamBName : "");
            }

            if(p.nbPoule === 8) {
                if (pickGame(3, p)) game3team1.setText(pickGame(3, p).teamAName !== "?" ? pickGame(3, p).teamAName : "");
                if (pickGame(3, p)) game3team2.setText(pickGame(3, p).teamBName !== "?" ? pickGame(3, p).teamBName : "");
                if (pickGame(2, p)) game2team1.setText(pickGame(2, p).teamAName !== "?" ? pickGame(2, p).teamAName : "");
                if (pickGame(2, p)) game2team2.setText(pickGame(2, p).teamBName !== "?" ? pickGame(2, p).teamBName : "");
                if (pickGame(4, p)) game4team1.setText(pickGame(4, p).teamAName !== "?" ? pickGame(4, p).teamAName : "");
                if (pickGame(4, p)) game4team2.setText(pickGame(4, p).teamBName !== "?" ? pickGame(4, p).teamBName : "");
                if (pickGame(5, p)) game5team1.setText(pickGame(5, p).teamAName !== "?" ? pickGame(5, p).teamAName : "");
                if (pickGame(5, p)) game5team2.setText(pickGame(5, p).teamBName !== "?" ? pickGame(5, p).teamBName : "");
                if (pickGame(6, p)) game6team1.setText(pickGame(6, p).teamAName !== "?" ? pickGame(6, p).teamAName : "");
                if (pickGame(6, p)) game6team2.setText(pickGame(6, p).teamBName !== "?" ? pickGame(6, p).teamBName : "");
                if (pickGame(1, p)) game1team1.setText(pickGame(1, p).teamAName !== "?" ? pickGame(1, p).teamAName : "");
                if (pickGame(1, p)) game1team2.setText(pickGame(1, p).teamBName !== "?" ? pickGame(1, p).teamBName : "");
                if (pickGame(7, p)) game7team1.setText(pickGame(7, p).teamAName !== "?" ? pickGame(7, p).teamAName : "");
                if (pickGame(7, p)) game7team2.setText(pickGame(7, p).teamBName !== "?" ? pickGame(7, p).teamBName : "");
                if (pickGame(8, p)) game8team1.setText(pickGame(8, p).teamAName !== "?" ? pickGame(8, p).teamAName : "");
                if (pickGame(8, p)) game8team2.setText(pickGame(8, p).teamBName !== "?" ? pickGame(8, p).teamBName : "");
                
            }


        }

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');

    }

    const generatePdfFinal = async (teamAName, teamBName) => {

        const note_ = prompt("Souhaitez-vous ajouter une mention sur vos feuilles de Match ?");
        const existingPdfBytes = await fetch('/final.pdf').then(res => res.arrayBuffer());

        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const form = pdfDoc.getForm();

        const competition_tour = form.getTextField('competition_tour');
        const pouleNb = form.getTextField('poulenb');
        // const field = form.getTextField('field');
        const note = form.getTextField('note');

        competition_tour.setText((competition.name + " - " + current.name).toUpperCase());
        pouleNb.setText(pouleModelConverter.getFormattedPouleTitle(selectedPoule.nbPoule, true).toUpperCase());
        // field.setText(selectedPoule.fieldName ? selectedPoule.fieldName : "");
        // field.setText(selectedPoule.fieldName ? selectedPoule.fieldName : "");
        note.setText(note_ ? note_ : "");

        const team1 = form.getTextField('team1');
        const team2 = form.getTextField('team2');
        team1.setText(teamAName);
        team2.setText(teamBName);

        const imageBytes = await fetch('/logo-tdp.jpg').then(res => res.arrayBuffer());
        const image = await pdfDoc.embedJpg(imageBytes);
        const imageDims = image.scale(0.1);

        const pages = pdfDoc.getPages();
        const firstPage = pages[0];

        // Définir les coordonnées où vous voulez insérer l'image
        const x = 50; // Coordonnée x
        const y = 690; // Coordonnée y

        // Dessiner l'image sur la page
        firstPage.drawImage(image, {
            x: x,
            y: y,
            width: imageDims.width,
            height: imageDims.height,
        });

        // Insert scaninfo
        const imageBytesScanInfo = await fetch('/scaninfo.png').then(res => res.arrayBuffer());
        const imageScanInfo = await pdfDoc.embedPng(imageBytesScanInfo);
        const imageDimsScanInfo = imageScanInfo.scale(0.25);
        const pagesScanInfo = pdfDoc.getPages();
        const firstPageScanInfo = pagesScanInfo[0];
        const x_si = 115;
        const y_si = 45;
        firstPageScanInfo.drawImage(imageScanInfo, {
            x: x_si,
            y: y_si,
            width: imageDimsScanInfo.width,
            height: imageDimsScanInfo.height,
        });

        // Insert QR Code
        const qrCodeCanvas = qrCodePouleRef.current.querySelector('canvas');
        const qrCodeDataURL = qrCodeCanvas.toDataURL('image/png');
        const qrCodeImage = await pdfDoc.embedPng(qrCodeDataURL);
        const imageDimsQrcode = qrCodeImage.scale(0.3);
        const pagesQrcode = pdfDoc.getPages();
        const firstPageQrcode = pagesQrcode[0];
        const x_qr = 40;
        const y_qr = 30;
        firstPageQrcode.drawImage(qrCodeImage, {
            x: x_qr,
            y: y_qr,
            width: imageDimsQrcode.width,
            height: imageDimsQrcode.height,
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');

    }

    const generatePdf = async () => {

        if(selectedPoule.teams.length === 2) {
            setError("Impression impossible pour 2 équipes.");
            return;
        }

        const note_ = prompt("Souhaitez-vous ajouter une mention sur vos feuilles de Poule ?");

        let existingPdfBytes;
        if(selectedPoule.teams.length === 3) existingPdfBytes = await fetch('/poule3.pdf').then(res => res.arrayBuffer());
        if(selectedPoule.teams.length === 4) existingPdfBytes = await fetch('/poule4.pdf').then(res => res.arrayBuffer());
        if(selectedPoule.teams.length === 5) existingPdfBytes = await fetch('/poule5.pdf').then(res => res.arrayBuffer());
        
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const form = pdfDoc.getForm();

        const competition_tour = form.getTextField('competition_tour');
        const pouleNb = form.getTextField('poulenb');
        const field = form.getTextField('field');
        const note = form.getTextField('note');

        competition_tour.setText((competition.name + " - " + current.name).toUpperCase());
        pouleNb.setText(pouleModelConverter.getFormattedPouleTitle(selectedPoule.nbPoule, false).toUpperCase());
        field.setText(selectedPoule.fieldName ? selectedPoule.fieldName : "");
        field.setText(selectedPoule.fieldName ? selectedPoule.fieldName : "");
        note.setText(note_ ? note_ : "");

        if(selectedPoule.teams.length === 3) {

            const team1 = form.getTextField('team1');
            const team2 = form.getTextField('team2');
            const team3 = form.getTextField('team3');
            team1.setText(selectedPoule.teams[0].name);
            team2.setText(selectedPoule.teams[1].name);
            team3.setText(selectedPoule.teams[2].name);

            const game1team1 = form.getTextField('game1team1');
            const game1team2 = form.getTextField('game1team2');
            game1team1.setText(selectedPoule.games[0].teamA.name);
            game1team2.setText(selectedPoule.games[0].teamB.name);

            const game2team1 = form.getTextField('game2team1');
            const game2team2 = form.getTextField('game2team2');
            game2team1.setText(selectedPoule.games[1].teamA.name);
            game2team2.setText(selectedPoule.games[1].teamB.name);

            const game3team1 = form.getTextField('game3team1');
            const game3team2 = form.getTextField('game3team2');
            game3team1.setText(selectedPoule.games[2].teamA.name);
            game3team2.setText(selectedPoule.games[2].teamB.name);

        }

        if(selectedPoule.teams.length === 4) {

            const team1 = form.getTextField('team1');
            const team2 = form.getTextField('team2');
            const team3 = form.getTextField('team3');
            const team4 = form.getTextField('team4');
            team1.setText(selectedPoule.teams[0].name);
            team2.setText(selectedPoule.teams[1].name);
            team3.setText(selectedPoule.teams[2].name);
            team4.setText(selectedPoule.teams[3].name);

            const game1team1 = form.getTextField('game1team1');
            const game1team2 = form.getTextField('game1team2');
            game1team1.setText(selectedPoule.games[0].teamA.name);
            game1team2.setText(selectedPoule.games[0].teamB.name);

            const game2team1 = form.getTextField('game2team1');
            const game2team2 = form.getTextField('game2team2');
            game2team1.setText(selectedPoule.games[1].teamA.name);
            game2team2.setText(selectedPoule.games[1].teamB.name);

            const game3team1 = form.getTextField('game3team1');
            const game3team2 = form.getTextField('game3team2');
            game3team1.setText(selectedPoule.games[2].teamA.name);
            game3team2.setText(selectedPoule.games[2].teamB.name);

            const game4team1 = form.getTextField('game4team1');
            const game4team2 = form.getTextField('game4team2');
            game4team1.setText(selectedPoule.games[3].teamA.name);
            game4team2.setText(selectedPoule.games[3].teamB.name);

            const game5team1 = form.getTextField('game5team1');
            const game5team2 = form.getTextField('game5team2');
            game5team1.setText(selectedPoule.games[4].teamA.name);
            game5team2.setText(selectedPoule.games[4].teamB.name);

            const game6team1 = form.getTextField('game6team1');
            const game6team2 = form.getTextField('game6team2');
            game6team1.setText(selectedPoule.games[5].teamA.name);
            game6team2.setText(selectedPoule.games[5].teamB.name);

        }

        if(selectedPoule.teams.length === 5) {

            const team1 = form.getTextField('team1');
            const team2 = form.getTextField('team2');
            const team3 = form.getTextField('team3');
            const team4 = form.getTextField('team4');
            const team5 = form.getTextField('team5');
            team1.setText(selectedPoule.teams[0].name);
            team2.setText(selectedPoule.teams[1].name);
            team3.setText(selectedPoule.teams[2].name);
            team4.setText(selectedPoule.teams[3].name);
            team5.setText(selectedPoule.teams[4].name);

            const game1team1 = form.getTextField('game1team1');
            const game1team2 = form.getTextField('game1team2');
            game1team1.setText(selectedPoule.games[0].teamA.name);
            game1team2.setText(selectedPoule.games[0].teamB.name);

            const game2team1 = form.getTextField('game2team1');
            const game2team2 = form.getTextField('game2team2');
            game2team1.setText(selectedPoule.games[1].teamA.name);
            game2team2.setText(selectedPoule.games[1].teamB.name);

            const game3team1 = form.getTextField('game3team1');
            const game3team2 = form.getTextField('game3team2');
            game3team1.setText(selectedPoule.games[2].teamA.name);
            game3team2.setText(selectedPoule.games[2].teamB.name);

            const game4team1 = form.getTextField('game4team1');
            const game4team2 = form.getTextField('game4team2');
            game4team1.setText(selectedPoule.games[3].teamA.name);
            game4team2.setText(selectedPoule.games[3].teamB.name);

            const game5team1 = form.getTextField('game5team1');
            const game5team2 = form.getTextField('game5team2');
            game5team1.setText(selectedPoule.games[4].teamA.name);
            game5team2.setText(selectedPoule.games[4].teamB.name);

            const game6team1 = form.getTextField('game6team1');
            const game6team2 = form.getTextField('game6team2');
            game6team1.setText(selectedPoule.games[5].teamA.name);
            game6team2.setText(selectedPoule.games[5].teamB.name);

            const game7team1 = form.getTextField('game7team1');
            const game7team2 = form.getTextField('game7team2');
            game7team1.setText(selectedPoule.games[6].teamA.name);
            game7team2.setText(selectedPoule.games[6].teamB.name);

            const game8team1 = form.getTextField('game8team1');
            const game8team2 = form.getTextField('game8team2');
            game8team1.setText(selectedPoule.games[7].teamA.name);
            game8team2.setText(selectedPoule.games[7].teamB.name);

            const game9team1 = form.getTextField('game9team1');
            const game9team2 = form.getTextField('game9team2');
            game9team1.setText(selectedPoule.games[8].teamA.name);
            game9team2.setText(selectedPoule.games[8].teamB.name);

            const game10team1 = form.getTextField('game10team1');
            const game10team2 = form.getTextField('game10team2');
            game10team1.setText(selectedPoule.games[9].teamA.name);
            game10team2.setText(selectedPoule.games[9].teamB.name);

        }

        // Insert logo
        const imageBytesLogo = await fetch('/logo-tdp.jpg').then(res => res.arrayBuffer());
        const imageLogo = await pdfDoc.embedJpg(imageBytesLogo);
        const imageDimsLogo = imageLogo.scale(0.1);
        const pagesLogo = pdfDoc.getPages();
        const firstPageLogo = pagesLogo[0];
        const x_l = 50;
        const y_l = 690;
        firstPageLogo.drawImage(imageLogo, {
            x: x_l,
            y: y_l,
            width: imageDimsLogo.width,
            height: imageDimsLogo.height,
        });

        // Insert scaninfo
        const imageBytesScanInfo = await fetch('/scaninfo.png').then(res => res.arrayBuffer());
        const imageScanInfo = await pdfDoc.embedPng(imageBytesScanInfo);
        const imageDimsScanInfo = imageScanInfo.scale(0.25);
        const pagesScanInfo = pdfDoc.getPages();
        const firstPageScanInfo = pagesScanInfo[0];
        const x_si = 115;
        const y_si = 45;
        firstPageScanInfo.drawImage(imageScanInfo, {
            x: x_si,
            y: y_si,
            width: imageDimsScanInfo.width,
            height: imageDimsScanInfo.height,
        });

        // Insert QR Code
        const qrCodeCanvas = qrCodePouleRef.current.querySelector('canvas');
        const qrCodeDataURL = qrCodeCanvas.toDataURL('image/png');
        const qrCodeImage = await pdfDoc.embedPng(qrCodeDataURL);
        const imageDimsQrcode = qrCodeImage.scale(0.3);
        const pagesQrcode = pdfDoc.getPages();
        const firstPageQrcode = pagesQrcode[0];
        const x_qr = 40;
        const y_qr = 30;
        firstPageQrcode.drawImage(qrCodeImage, {
            x: x_qr,
            y: y_qr,
            width: imageDimsQrcode.width,
            height: imageDimsQrcode.height,
        });

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');

    }

    const handleOpenTour = (id) => {
        setSelectedPoule(null);
        navigate('/manager/' + id);
        return;
    }

    const qrCodePouleRef = useRef(null);

    return (

        <Wrapper>

            <Loader isLoading={isLoading} />
            <Popup content={[error]} />

            {

            isCreateTour !== 2 ?

            <Cols>

                <Col1>

                    <Box1>

                        { current && current.depth >= 1 && <Arrow tpe="up" /> }
                        <TourList handleOpenTour={handleOpenTour} name={isFirstTour ? "Créez un premier tour" : (parents.length === 0 && "Provenance des équipes : Inscriptions") } tours={parents} transparent={true} />
                        { !isFirstTour && <Arrow tpe="down" /> }
                        <TourList handleOpenTour={handleOpenTour} tours={[current]} />
                        { children.length !== 0 && <Arrow tpe="down" /> }
                        <TourList handleOpenTour={handleOpenTour} tours={children} transparent={true} />

                    </Box1>

                    <Box2>
                        <TitleBox><h3>{ (current && !current.isFinal) ? "Liste des Poules" : "Etapes du tableau"}</h3></TitleBox>
                        <PouleList poules={poules} openPoule={openPoule} isFinal={current ? current.isFinal : false} />
                    </Box2>

                </Col1>

                <Col2>

                    {

                    selectedPoule ?

                        <Box3 className="scores">

                            <QRCodeComponent value={ MOBILE_HOST_URL + "/scorer/" + selectedPoule.pouleId} reference={qrCodePouleRef} display="none" />

                            <TitleBox>
                                <h3>Edition : { current.name } &gt; { pouleModelConverter.getFormattedPouleTitle(selectedPoule.nbPoule, current.isFinal) } { selectedPoule.fieldName && "(" + selectedPoule.fieldName + ")"}</h3>
                                <div className="toolbox">
                                    <ion-icon name="chevron-back-circle-outline" onClick={handleReturnPoule}></ion-icon>
                                    { current && !current.isFinal && <ion-icon name="print-outline" onClick={generatePdf}></ion-icon> }
                                </div>
                            </TitleBox>

                            {

                            current && !current.isFinal &&

                            <>
                            
                                <h3 className="subtitle">Classement de la Poule</h3>

                                <AreaPouleClassement>
                                        <PouleClassement classement={selectedPoule.formattedClassement} />
                                </AreaPouleClassement>

                            </>

                            }

                            <h3 className="subtitle">Matchs de la {current && !current.isFinal ? "Poule" : "phase de tableau"}</h3>

                            <GameList games={ games } scoresGames={scoresGames} onUpdateGame={onUpdateGame} onValidateGameUpdates={onValidateGameUpdates} steps={steps} isFinal={current.isFinal} generatePdfFinal={generatePdfFinal} />

                        </Box3>

                    :

                        <Box3>
                        {

                            isCreateTour === 0 &&

                            <>

                                {
                                
                                ((current && !current.isFinal) || !current) &&

                                <>
                                    <TitleBox><h3>Classement</h3></TitleBox>
                                    <AreaClassement>
                                        <TourClassement classement={!isFirstTour ? currentClassement : competitionClassement} isFirstTour={isFirstTour} />
                                    </AreaClassement>
                                </>

                                }

                                {

                                (current && current.isFinal) &&

                                <TitleBox>
                                    <h3>Edition : { current.name }</h3>
                                    <div className="toolbox">
                                        <ion-icon name="print-outline" onClick={generatePdfFinalScheme}></ion-icon>
                                    </div>
                                </TitleBox>

                                }

                                <BtnsSection>
                                    { current &&
                                    <div>
                                        <AddBtn icon="trash" message1="Supprimer" message2="le tour" callback={handleDeleteCurrentTour} color="#e74c3c" />
                                    </div>
                                    }
                                    { ((current && !current.isFinal) || !current) &&
                                    <div>
                                        <AddBtn icon="add" message1="Créer un" message2="tour" callback={handleCreateTour} />
                                    </div>
                                    }
                                </BtnsSection>
                            </>

                        }

                        {

                            isCreateTour === 1 &&

                            <>

                                <TitleBox>
                                    <h3>Génération d'un tour</h3>
                                    <div className="toolbox">
                                        <ion-icon name="chevron-back-circle-outline" onClick={handleReturnGeneral}></ion-icon>
                                    </div>
                                </TitleBox>
                                
                                <h3 className="subtitle">1/ Sélectionnez des équipes ({(!isFirstTour ? currentClassement : competitionClassement).filter((c) => c.selectedForAfter).length})</h3>
                                <TourPicker classement={!isFirstTour ? currentClassement.filter((c) => c.available === true) : competitionClassement } isFirstTour={isFirstTour} onChange={handleChangeSelectionTeam} />
                                <Shortcut>
                                    <span onClick={handleSelectAllTeams}>Tout séléctionner</span>
                                    <span onClick={handleUnselectAllTeams}>Tout déséléctionner</span>
                                </Shortcut>
                                <h3 className="subtitle">2/ Paramétrez la génération automatique</h3>

                                <FormTourConfigurator tourName={tourName} handleChangeTourName={handleChangeTourName} tourTypes={tourTypes} tourType={tourType} handleChangeTourType={handleChangeTourType} tourNbTeams={tourNbTeams} handleChangeTourNbTeams={handleChangeTourNbTeams} poulesCompositions={poulesCompositions} poulesComposition={poulesComposition} handleChangePoulesComposition={handleChangePoulesComposition} />

                                <BtnsSection>
                                    <div>
                                        <AddBtn icon="cog" message1="Générer" message2="le tour" callback={handleConfigTour} />
                                    </div>
                                </BtnsSection>

                            </>

                        }
                        </Box3>

                    }
                    
                </Col2>

            </Cols>

            :

            <Cols>

                <Col3>
                    <Box4>

                        <div className="ct">

                            <TitleBox>
                                <h3>Génération d'un tour : { tourName }</h3>
                                <div className="toolbox">
                                    <ion-icon name="chevron-back-circle-outline" onClick={handleReturn}></ion-icon>
                                    <ion-icon name="add-circle-outline" onClick={handleAddPouleToCreate}></ion-icon>
                                </div>
                            </TitleBox>

                            <PouleDragNDropList poules={poulesToCreate} onDragEnd={onDragEnd} handleDeletePouleToCreate={handleDeletePouleToCreate} handleChangeFieldId={handleChangeFieldId} fields={fields} />

                        </div>
                        <ValidationSection icon="checkmark-done" message1="Valider le" message2="nouveau tour" className="validation" callback={handleValidateTour} />

                    </Box4>
                </Col3>

                <Col4>
                    <Box5>
                    </Box5>
                </Col4>

            </Cols>

            }

        </Wrapper>

    );

}

export default Manager;